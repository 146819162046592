import { defineNuxtPlugin } from 'nuxt/app';
import { useStore3 } from '../store/main';
import { storeToRefs } from 'pinia';
import { watch } from '#imports';
import { isDev } from '../lib/services/helpers';
import { onMounted } from 'vue';
import { tags } from '@mailslurp/common/dist/links';
import type { EventName } from '../lib/services/types';
import type { PostHog } from 'posthog-js';
import { getLogger } from '../lib/services/getLogger';

let isEnabled = false;
const log = getLogger('rum.client');
let _posthog: PostHog;
const ifEnabled = (fn: () => void) => {
  if (isEnabled) {
    try {
      fn();
    } catch (e) {
      log('Error in rum ' + e);
    }
  }
};

export async function initializeRum() {
  try {
    _posthog = await import('posthog-js').then((it) => it.posthog);
    isEnabled = true;
    _posthog.init(tags.rum.posthogId, {
      api_host: tags.rum.host,
      capture_pageview: true,
      autocapture: true,
      persistence: 'localStorage',
    });
  } catch (e) {
    console.error('Error init posthog', e);
  }
}

export const identifyUser = (userId: string, data?: object) =>
  ifEnabled(() => {
    // filter data to exclude undefined values
    const d = data
      ? Object.entries(data).reduce((acc, [k, v]) => {
          if (v !== undefined && v !== null) {
            acc[k] = v;
          }
          return acc;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }, {} as any)
      : {};
    _posthog?.identify?.(userId, d);
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const _captureEvent = (name: EventName, data: any = {}) => {
  ifEnabled(() => {
    try {
      _posthog?.capture?.(name, data);
    } catch (e) {
      console.error(e);
    }
  });
};

export default defineNuxtPlugin((nuxtApp) => {
  const store3 = useStore3();
  const { account } = storeToRefs(store3);
  const added = ref(false);
  if (!isDev() && !added.value) {
    added.value = true;
    try {
      log('Initializing RUM');
      initializeRum();
    } catch (e) {
      console.error('Failed to add RUM', e);
    }
  }
  watch(
    () => account.value,
    (accountValue) => {
      try {
        if (accountValue) {
          identifyUser(accountValue.user.id, {
            subscription: accountValue.subscription?.subscriptionType,
            organization: accountValue.user.organization,
          });
        }
      } catch (e) {
        console.error(`Error identifyUser user ${accountValue}`, e);
      }
    },
  );
  return {
    provide: {
      captureEvent: _captureEvent,
    },
  };
});
