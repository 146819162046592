<template>
  <nav
    :class="{
      '': true,
      'sm:ml-60': !sidebarLgMinified,
      'sm:ml-20': sidebarLgMinified,
    }"
  >
    <!-- banners -->
    <FrozenBanner v-if="isFrozen" />
    <RequiresVerificationBanner
      v-else-if="requiresVerification && SHOW_VERIFICATION_REQUIRED"
    />
    <RequiresPasswordBanner
      v-else-if="requiresPassword && SHOW_PASSWORD_REQUIRED"
    />
    <HasComplaintsBanner v-else-if="hasComplaints && SHOW_COMPLAINTS" />
    <span class="hidden" v-else data-id="requires-none-banner" />
    <!-- content -->
    <div
      class="x-container mx-auto flex items-center justify-end gap-4 px-4 py-2"
    >
      <NuxtLink
        class="flex items-center no-underline sm:hidden"
        to="/dashboard/"
      >
        <img class="mr-3 h-6" src="/assets/logo.svg" alt="MailSlurp" />
        <span
          class="hidden self-center whitespace-nowrap text-lg font-bold text-gray-900 sm:block"
          data-test-id="logo"
          >MailSlurp</span
        >
      </NuxtLink>
      <div class="block w-full sm:flex" v-if="$slots.default">
        <slot />
      </div>
      <div class="flex flex-1 items-center justify-end gap-4 md:order-2">
        <Btn
          class="hidden lg:flex"
          v-if="hasNoSubscription && showUpgradeButton"
          to="/upgrade/"
          tag="NuxtLink"
          data-id="navbar-subscription-btn"
          size="sm"
          :variant="Variant.upgrade"
          icon-position="right"
          icon="paidFeature"
        >
          {{ 'Upgrade' }}
        </Btn>

        <Btn
          v-if="button"
          size="sm"
          icon-position="left"
          :circle="true"
          :tag="button.external ? 'a' : 'NuxtLink'"
          data-el="cph-button"
          :data-id="
            button.dataId ||
            (button.external ? 'page-button-external' : 'page-button-internal')
          "
          :to="button.external ? undefined : button.to"
          :href="button.external ? button.to : undefined"
          :icon="button.icon"
          :variant="Variant.primary"
          :title="button.text"
        >
          {{ button.text }}
        </Btn>
        <NavUserSettings />
        <Btn
          class="sm:hidden"
          size="sm"
          :variant="Variant.white"
          type="button"
          icon="menuBars"
          @click="onClickSidebarOpen"
          tag="button"
        >
        </Btn>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useStore3 } from '../../store/main';
import { storeToRefs } from 'pinia';
import Btn from '../../components/form/Btn.vue';
import { Variant } from '../../store/types';
import NavUserSettings from '../../components/nav/NavUserSettings.vue';
import RequiresVerificationBanner from './banners/RequiresVerificationBanner.vue';
import RequiresPasswordBanner from './banners/RequiresPasswordBanner.vue';
import FrozenBanner from './banners/FrozenBanner.vue';
import HasComplaintsBanner from './banners/HasComplaintsBanner.vue';
import type { MsIcon, PageButton } from '../../lib/services/types';

const SHOW_COMPLAINTS = true;
const SHOW_VERIFICATION_REQUIRED = true;
const SHOW_PASSWORD_REQUIRED = false;
withDefaults(
  defineProps<{
    showUpgradeButton?: boolean;
    requiresVerification?: boolean;
    requiresPassword?: boolean;
    hasComplaints?: boolean;

    button?: PageButton;
  }>(),
  {
    button: undefined,
    showUpgradeButton: false,
    requiresVerification: false,
    requiresPassword: false,
    hasComplaints: false,
  },
);

const store3 = useStore3();
const { subscription, user, sidebarLgMinified } = storeToRefs(store3);
const hasNoSubscription = computed(() => {
  return !subscription.value;
});

const isFrozen = computed(() => !!user.value?.isFrozen);
const onClickSidebarOpen = () => {
  store3.toggleSidebarSmOpen();
};
</script>
