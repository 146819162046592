<template>
  <footer class="" :data-has-feedback="hasFeedback ? '1' : '0'">
    <!--    <div-->
    <!--      class="x-container xcontainer grid w-full grid-cols-1 gap-3 pb-4 pt-12 lg:grid-cols-4"-->
    <!--      v-if="enableUserComments"-->
    <!--    >-->
    <!--      <div class="flex-1 lg:col-span-4">-->
    <!--        <slot v-if="$slots.onboarding && hasOnboarding" name="onboarding" />-->
    <!--        <UserHelpArea v-else />-->
    <!--      </div>-->
    <!--      &lt;!&ndash;      <div class="lg:col-span-1 flex-1">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <slot v-if="$slots.feedback && hasFeedback" name="feedback" />&ndash;&gt;-->
    <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
    <!--    </div>-->

    <div class="xcontainer flex flex-col gap-3 border-t bg-white py-2">
      <section
        class="flex flex-col items-start justify-start gap-2 lg:flex-row lg:gap-4"
      >
        <component
          class="text-xs font-light text-gray-500 hover:text-gray-900 hover:underline"
          v-for="link in footerLinks"
          :is="link.external ? 'a' : NuxtLink"
          :key="link.to"
          :data-footer-link-id="link.dataId"
          :href="link.external ? link.to : undefined"
          :to="link.external ? undefined : link.to"
          :target="link.external ? '_blank' : undefined"
          :rel="link.external ? 'noreferrer noopener' : undefined"
        >
          {{ link.text }}
        </component>

        <FormBadge
          v-if="isChild"
          :variant="Variant.warning"
          data-id="organization-child-badge"
          :data-id-organization-user-type="userType"
          :data-id-organization-user-id="userId"
        >
          {{ 'Organization user' }}
        </FormBadge>
        <span
          class="text-xs font-bold"
          v-if="isDemo"
          data-id="demo-days-badge"
          :data-id-is-demo="isDemo"
          :data-id-demo-days="demoDays"
        >
          {{ 'Demo days' }}{{ ': ' }}{{ demoDays }}
        </span>
      </section>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { Variant } from '../store/types';
import FormBadge from './form/FormBadge.vue';
import { computed } from 'vue';
import { useStore3 } from '../store/main';
import { storeToRefs } from 'pinia';
import UserHelpArea from './feedback/UserHelpArea.vue';
import { NuxtLink } from '#components';

withDefaults(
  defineProps<{
    enableUserComments?: boolean;
    hasOnboarding?: boolean;
    hasFeedback?: boolean;
  }>(),
  { hasOnboarding: false, enableUserComments: false },
);
const store3 = useStore3();
const { user, isChild, subscription } = storeToRefs(store3);
const demoDays = computed<number>(() => {
  return subscription?.value?.demoDaysRemaining ?? 0;
});
const isDemo = computed<boolean>(() => {
  return !!subscription?.value?.demoDaysRemaining;
});
const userType = computed<string>(() => {
  return user?.value?.userType ?? '0';
});

const userId = computed<string>(() => {
  return user?.value?.id ?? '0';
});

const footerLinks = computed<
  { text: string; external?: boolean; to: string; dataId: string }[]
>(() => [
  {
    text: 'Legal',
    dataId: 'legal',
    external: true,
    to: 'https://www.mailslurp.com/full-terms-and-conditions',
  },
  {
    text: 'Pricing',
    dataId: 'pricing',
    external: true,
    to: 'https://www.mailslurp.com/pricing',
  },
  {
    text: 'Documentation',
    dataId: 'documentation',
    external: true,
    to: 'https://docs.mailslurp.com/',
  },
  {
    text: 'Support',
    dataId: 'support',
    external: true,
    to: 'https://www.mailslurp.com/support/',
  },
  {
    to: 'https://www.mailslurp.com/tools',
    dataId: 'tools',
    external: true,
    text: 'Tools',
  },
  {
    to: 'https://status.mailslurp.com',
    external: true,
    dataId: 'status',
    text: 'API Status',
  },
  {
    to: '/settings/feedback/',
    dataId: 'feedback',
    text: 'Give feedback',
  },
]);
</script>
