<template>
  <CardHeaderButton
    v-if="button"
    :variant="variant"
    :data-star-enabled="enabled ? '1' : '0'"
    :title="title"
    :icon="enabled ? 'favouriteEnabled' : 'favouriteDisabled'"
    @click="onToggle"
  />
  <Icon
    v-else
    :data-star-enabled="enabled ? '1' : '0'"
    :icon="enabled ? 'favouriteEnabled' : 'favouriteDisabled'"
    :class="enabled ? 'text-yellow-300' : 'text-light-900'"
    @click="onToggle"
  />
</template>
<script setup lang="ts">
import { computed } from 'vue';
import CardHeaderButton from '../components/card/CardHeaderButton.vue';
import Icon from '../components/form/Icon.vue';
import type { Variant } from '../store/types';
import type { TogglePayload } from '../lib/services/types';

const emit = defineEmits<{
  (e: 'toggle', p: TogglePayload): void;
}>();
const props = withDefaults(
  defineProps<{
    enabled?: boolean;
    id?: string;
    button?: boolean;
    variant?: Variant;
  }>(),
  {
    enabled: false,
    id: '',
    button: false,
    variant: undefined,
  },
);

const title = computed(() => (props.enabled ? 'Unfavorite' : 'Favorite'));
const onToggle = () => {
  emit('toggle', {
    id: props.id,
    previousState: props.enabled,
  });
};
</script>
