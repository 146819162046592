<template>
  <AlertCard
    v-if="flash && routeMatches && notInfo"
    data-id="flash"
    :data-error-code="flash ? flash.errorCodeVariant : undefined"
    :absolute="true"
    :icon="args.icon"
    :dismiss-timeout-seconds="args.dismissTimeoutSeconds"
    :is-upgrade-message="args.isUpgradeMessage"
    :links="args.routerLinks"
    :stack="args.stack"
    :text="args.text || errorMessage"
    :title="store3?.regressionTest ? 'test-title' : args.title"
    :variant="args.variant"
    @dismissed="dismissed"
  />
  <!--  :dismiss-timeout-seconds="15"-->
</template>
<style>
.p-toast-message.p-toast-message-info {
  @apply rounded-lg border-0 bg-blue-50 px-4 py-2 text-sm shadow;
}
.p-toast-message-content {
  @apply flex flex-row items-center gap-3 text-gray-600;
}
.p-toast-message-icon {
  @apply h-5 w-5 opacity-30;
}
.p-toast-close-button {
  @apply self-center opacity-40;
}
.p-toast-close-button:hover {
  @apply opacity-100;
}
.p-toast-summary {
  @apply font-medium text-blue-500;
}
.p-toast-detail {
  @apply font-light;
}
</style>
<script setup lang="ts">
import type { ApiError } from '../lib/services/helpers';
import { useStore3 } from '../store/main';
import { Variant } from '../store/types';
import {
  AppendProductAddonPlanAddonEnum,
  ErrorCodesDtoAppErrorCodesEnum,
} from '@mailslurp/admin-sdk';
import { storeToRefs } from 'pinia';
import AlertCard from '../components/tw/AlertCard.vue';
import { useRoute, watch } from '#imports';
import { computed } from 'vue';
import type { MsIcon } from '../lib/services/types';
import { useToast } from 'primevue/usetoast';
import { getUpgradePageAddonUrl } from '../lib/services/upgradeLinks';

const store3 = useStore3();
const route = useRoute();
const { flash, regressionTest } = storeToRefs(store3);
const routeMatches = computed(() => {
  return !flash.value?.path || flash.value.path.test(route.path);
});
const toast = useToast();

const notInfo = computed(
  () =>
    flash.value?.variant !== Variant.info && flash.value?.variant !== undefined,
);
type ArgType = {
  icon?: MsIcon;
  dismissTimeoutSeconds?: number;
  isUpgradeMessage?: boolean;
  routerLinks?: {
    to?: string;
    text: string;
    href?: string;
    targetBlank?: boolean;
    variant?: Variant;
    dataId?: string;
  }[];
  stack?: string;
  text?: string;
  title?: string;
  variant?: Variant;
};
const args = computed<ArgType>(() => {
  const isUpgradeMessage =
    flash?.value?.errorCodeVariant ===
      ErrorCodesDtoAppErrorCodesEnum.I_001_UPGRADE_REQUIRED ||
    errorMessage.value.includes('upgrade your account') ||
    errorMessage.value.includes('Plan limit') ||
    errorMessage.value.includes('Please upgrade');
  const defaults: Partial<ArgType> = {
    variant: flash.value?.variant ?? Variant.info,
    routerLinks: flash.value?.routerLink
      ? [
          flash.value?.routerLink.external
            ? {
                href: flash.value?.routerLink.to,
                text: flash.value?.routerLink.text,
                targetBlank: true,
                variant: flash.value?.variant,
              }
            : {
                to: flash.value?.routerLink.to,
                text: flash.value?.routerLink.text,
                variant: flash.value?.variant,
              },
        ]
      : undefined,
    icon: 'infoCircle',
    isUpgradeMessage,
    stack: regressionTest.value
      ? 'error-stack'
      : isUpgradeMessage
        ? undefined
        : flash.value?.error?.stack,
    title: isUpgradeMessage ? 'Upgrade required' : 'Info',
  };
  switch (flash.value?.variant ?? defaults.variant) {
    case Variant.danger:
      defaults.icon = 'error';
      if (flash.value?.error?.apiResult?.errorCode) {
        defaults.title = flash.value?.error?.apiResult?.errorCode + '';
      } else {
        defaults.title = 'Error';
      }
      break;
    case Variant.warning:
      defaults.icon = 'warning';
      defaults.title = 'Warning';
      break;
    case Variant.success:
      defaults.icon = 'tickCircle';
      defaults.title = 'Success';
      break;
    case Variant.upgrade:
      defaults.title = 'Upgrade account';
      defaults.icon = 'paidFeature';
      break;
  }
  switch (flash.value?.errorCodeVariant) {
    // I
    case ErrorCodesDtoAppErrorCodesEnum.I_001_UPGRADE_REQUIRED:
      defaults.icon = 'paidFeature';
      defaults.variant = Variant.upgrade;
      defaults.routerLinks = [
        {
          to: '/upgrade/',
          text: 'Upgrade account',
          variant: Variant.upgrade,
          dataId: 'flash-upgrade-button',
        },
      ];
      break;
    // W 401
    case ErrorCodesDtoAppErrorCodesEnum.W_401_MULTIPLE_ACCOUNTS:
      defaults.icon = 'infoCircle';
      defaults.variant = Variant.info;
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_401_UNAUTHORIZED:
      defaults.text = 'Session has expired. Please login.';
      defaults.routerLinks = [
        {
          to: '/login/',
          text: 'Login',
          variant: Variant.success,
          dataId: 'flash-login-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_404_USER_NOT_FOUND_FOR_ACCESS_LINK:
      defaults.routerLinks = [
        {
          to: '/login/',
          text: 'Login instead',
          variant: Variant.warning,
          dataId: 'flash-login-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_409_USER_ALREADY_EXISTS:
      defaults.variant = Variant.warning;
      defaults.routerLinks = [
        {
          to: '/login/',
          text: 'Login',
          variant: Variant.warning,
          dataId: 'flash-login-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_404_USER_NOT_FOUND_FOR_PASSWORD_RESET:
      defaults.routerLinks = [
        {
          to: '/sign-up',
          text: 'Sign up',
          variant: Variant.warning,
          dataId: 'flash-sign-up-button',
        },
        {
          to: '/organization',
          text: 'Find organization',
          variant: Variant.warning,
          dataId: 'flash-find-organization-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_404_USER_NOT_FOUND_FOR_PASSWORD:
      defaults.routerLinks = [
        {
          to: '/forgot-password',
          text: 'Forgot password',
          variant: Variant.warning,
          dataId: 'flash-forgot-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_409_LINK_ACCESS_BLOCKED:
      defaults.routerLinks = [
        {
          to: '/unblock/',
          text: 'Unblock access',
          variant: Variant.warning,
          dataId: 'flash-unblock-button',
        },
      ];
      break;
    // W 429
    case ErrorCodesDtoAppErrorCodesEnum.W_429_SUBSCRIPTION_PAYMENT_FAILURES:
      defaults.routerLinks = [
        {
          to: '/settings/?openBilling=1',
          text: 'Open billing',
          variant: Variant.upgrade,
          dataId: 'flash-billing-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_429_SUBSCRIPTION_TRIAL_LIMIT:
      defaults.icon = 'paidFeature';
      defaults.variant = Variant.upgrade;
      defaults.routerLinks = [
        {
          to: '/upgrade/',
          text: 'Update subscription',
          variant: Variant.upgrade,
          dataId: 'flash-upgrade-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_429_SUBSCRIPTION_REQUIRED:
    case ErrorCodesDtoAppErrorCodesEnum.W_429_SUBSCRIPTION_FREE_LIMIT:
      defaults.icon = 'paidFeature';
      defaults.variant = Variant.upgrade;
      defaults.routerLinks = [
        {
          to: '/upgrade/',
          text: 'Upgrade account',
          variant: Variant.upgrade,
          dataId: 'flash-upgrade-button',
        },
      ];
      break;
    // subscriptions
    case ErrorCodesDtoAppErrorCodesEnum.W_429_SUBSCRIPTION_LIMIT_REACHED:
      defaults.icon = 'paidFeature';
      defaults.variant = Variant.upgrade;
      defaults.routerLinks = [
        {
          to: '/upgrade',
          text: 'Upgrade plan',
          variant: Variant.upgrade,
          dataId: 'flash-upgrade-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_429_SUBSCRIPTION_ADDON_REQUIRED_CONNECTOR:
      defaults.variant = Variant.warning;
      defaults.routerLinks = [
        {
          to: getUpgradePageAddonUrl(
            AppendProductAddonPlanAddonEnum.CONNECTORS,
            {
              expedite: '1',
            },
          ),
          text: 'Update subscription',
          variant: Variant.upgrade,
          dataId: 'flash-upgrade-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_429_SUBSCRIPTION_ADDON_REQUIRED_VALIDATION:
      defaults.variant = Variant.warning;
      defaults.routerLinks = [
        {
          to: getUpgradePageAddonUrl(
            AppendProductAddonPlanAddonEnum.VALIDATION,
            {
              expedite: '1',
            },
          ),
          text: 'Update subscription',
          variant: Variant.upgrade,
          dataId: 'flash-upgrade-button',
        },
      ];
      break;
    case ErrorCodesDtoAppErrorCodesEnum.W_429_ACCOUNT_FROZEN:
      defaults.variant = Variant.warning;
      defaults.routerLinks = [
        {
          to: '/unblock',
          text: 'Unblock account',
          variant: Variant.warning,
          dataId: 'flash-unblock-button',
        },
      ];
      break;
  }

  return defaults;
});

const errorMessage = computed<string>(() => {
  if (store3?.regressionTest) {
    return 'error-message';
  }
  let str = '';
  if (flash.value?.text) {
    str += flash.value?.text;
  } else if (flash.value?.error) {
    const err = flash.value?.error;
    if (err?.errorCode) {
      const e = err as ApiError;
      str += 'Error: ' + e.message;
    } else {
      try {
        str += 'Error: ' + JSON.stringify(err, Object.getOwnPropertyNames(err));
      } catch (e) {
        str +=
          'Error: ' +
          (flash.value?.error?.message ?? 'error could not be serialized');
      }
    }
  }
  return str;
});

const dismissed = () => {
  store3.setFlash(null);
};

watch(
  () => flash.value,
  () => {
    if (flash.value && routeMatches.value && !notInfo.value) {
      toast.add({
        severity: 'info',
        summary: 'Info',
        detail: flash.value?.text,
        life: 3000,
      });
    }
  },
);
</script>
