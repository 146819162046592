<template>
  <div
    class="hs-dropdown relative inline-flex"
    ref="target"
    data-el="FormDropDown"
    :data-dropdown-open="isVisible ? '1' : '0'"
  >
    <button
      class=""
      type="button"
      :class="{
        'inline-flex items-center justify-center gap-2 align-middle transition-all hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white': true,
        'border-transparent': inGroup,
        'border border-gray-300 shadow-sm': !noButton && !inGroup,
        'bg-white text-sm font-medium text-gray-700': !noButton,
        'rounded-full': circle && !inGroup,
        'rounded-md': !circle && !inGroup,
        'px-3 py-1': !noButton && size === 'xs',
        'px-4 py-3': !noButton && size === 'md',
        'px-3 py-2': !noButton && size === 'sm',
      }"
      @click="isVisible = !isVisible"
    >
      <slot name="button-content">
        <!-- fallback -->
        <span>{{ label }}</span>
      </slot>
      <svg
        class="text-gray-600 group-focus:rotate-180 hs-dropdown-open:rotate-180"
        v-if="!noButton"
        :class="{ 'h-2.5 w-2.5': size !== 'xs' || size === 'xs' }"
        width="10"
        height="10"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>
    <div
      class="hs-dropdown-menu absolute z-50 min-w-[15rem] max-w-3xl rounded-lg border border-gray-300 bg-white p-2 shadow-xl transition-[opacity,margin] duration-[0.1ms]"
      :class="{
        'bottom-0 left-0 mt-2 translate-y-full': position === 'bottom-right',
        'bottom-0 right-0 mt-2 translate-y-full': position === 'bottom-left',
        'left-0 top-0 mb-2 -translate-y-full': position === 'top-right',
        'right-0 top-0 mb-2 -translate-y-full': position === 'top-left',
        'invisible opacity-0': !isVisible,
        'visible opacity-100': isVisible,
      }"
      aria-labelledby="hs-dropdown-default"
    >
      <slot>
        <!-- fallback -->
        <FormDropDownItem
          v-for="item in items"
          :key="item.to + '-' + item.label"
          :label="item.label"
          :external="item.external"
          :to="item.to"
        />
      </slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
import FormDropDownItem from './FormDropDownItem.vue';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import type { FormSize } from '../../lib/services/types';

const isVisible = ref(false);
const clicked = () => {
  isVisible.value = false;
};

const target = ref(null);

onClickOutside(target, () => clicked());

withDefaults(
  defineProps<{
    inGroup?: boolean;
    label?: string;
    items?: { label: string; to: string; external?: boolean }[];
    size?: FormSize;
    // where tl origin of dropdown is in relation to the button
    position?: 'bottom-left' | 'bottom-right' | 'top-right' | 'top-left';
    noButton?: boolean;
    circle?: boolean;
  }>(),
  {
    inGroup: false,
    noButton: false,
    label: '',
    size: 'sm',
    position: 'bottom-right',
    items: () => [],
    circle: false,
  },
);
</script>
