<template>
  <NavBarAlert
    :loading="loading"
    :done="done"
    theme="info"
    data-id="requires-verification-banner"
  >
    <template #done>
      <p>Email sent to {{ emailAddress }}. Please click the link.</p>
    </template>
    <template #content>
      <p>
        <strong> Email verification required: </strong>
        <button
          class="underline hover:no-underline"
          type="button"
          data-id="resend-verification-email"
          @click="resend"
        >
          Send email
        </button>
      </p>
    </template>
  </NavBarAlert>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useStore3 } from '../../../store/main';

import { computed, ref } from 'vue';
import { loadFetch } from '../../../lib/services/helpers';
import NavBarAlert from '../NavBarAlert.vue';

const resend = async () => {
  await loadFetch(
    () =>
      store.signIn({
        emailAddress: emailAddress.value || '',
        preventMultipleUsers: undefined,
        requestedPlanId: undefined,
      }),
    store,
    loading,
  );
  done.value = true;
  setTimeout(() => {
    done.value = false;
  }, 5000);
};
const store = useStore3();

const { user } = storeToRefs(store);
const emailAddress = computed(() => user.value?.emailAddress);
const done = ref(false);
const loading = ref(false);
</script>
