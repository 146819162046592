<template>
  <component
    v-bind="$attrs"
    :is="tagComponent"
    data-el="Btn"
    :data-variant="variant"
    :data-tag="tag"
    :type="type"
    :title="disabled ? 'Disabled' : title"
    :disabled="disabled ? true : undefined"
    :tabindex="0"
    :name="name"
    :href="tag === 'a' ? href || to : undefined"
    :to="tag === 'NuxtLink' ? to || href : undefined"
    :rel="rel"
    :target="target"
    :class="{
      'relative w-full': fill,
      relative: iconBadge,
      'inline-flex flex-row text-nowrap': true,
      'divide divide-x divide-gray-900/20': icon,
      'rounded-md shadow-sm': !inGroup,
      'is-pointer items-center justify-center text-center font-medium no-underline focus:outline-none focus:ring-4': true,
      'border-sm cursor-not-allowed opacity-70': disabled,
      'focus:ring-blue-300': !disabled,
      border: !inGroup,
      ...classes,
    }"
    @click="onClick"
  >
    <span
      v-if="icon && iconPosition === 'left'"
      :class="{ ...paddingClass, ...textClass }"
    >
      <Icon :icon="icon" />
    </span>
    <span
      v-if="loading || $slots.default"
      :class="{
        'flex-1': fill,
        ...textClass,
        ...paddingClass,
      }"
    >
      <span v-if="loading" :class="slotClass">Loading</span>
      <span v-else :class="slotClass">
        <slot />
      </span>
    </span>
    <span
      v-if="icon && iconPosition === 'right'"
      :class="{ ...paddingClass, ...textClass }"
    >
      <Icon :icon="icon" />
    </span>
    <span
      class="absolute right-0 top-0 ml-3 inline-flex h-3 w-3 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full bg-gray-300 p-3 text-sm font-medium text-gray-800 shadow-sm"
      v-if="iconBadge"
    >
      <Icon class="text-xs" :icon="iconBadge" />
    </span>
  </component>
</template>
<style>
.btn-orange {
  background-image: linear-gradient(
    to right,
    rgb(249, 115, 22),
    rgb(236, 72, 153)
  ) !important;
  color: white !important;
  text-decoration: none !important;
}

.btn-orange:focus {
  outline: 1px dotted orange !important;
}

.btn-orange:hover {
  background-image: linear-gradient(
    to right,
    rgb(249, 115, 22),
    rgb(219, 39, 119)
  ) !important;
  color: white !important;
  text-decoration: none !important;
}
</style>
<script lang="ts" setup>
import Icon from '../../components/form/Icon.vue';
import { Variant } from '../../store/types';
import { computed } from 'vue';
import type { FormSize, MsIcon } from '../../lib/services/types';
import { NuxtLink } from '#components';
import { getLogger } from '../../lib/services/getLogger';

const tagComponent = computed(() => {
  return props.tag === 'NuxtLink' ? NuxtLink : props.tag;
});

const props = withDefaults(
  defineProps<{
    tag: 'a' | 'NuxtLink' | 'button';
    loading?: boolean;
    disabled?: boolean;
    variant?: Variant | 'onboarding';
    to?: string;
    href?: string;
    rel?: string;
    target?: string;
    name?: string;
    type?: string;
    icon?: MsIcon;
    iconBadge?: MsIcon;
    iconPosition?: 'right' | 'left';
    size?: FormSize;
    outline?: boolean;
    title?: string;
    inGroup?: boolean;
    fill?: boolean;
  }>(),
  {
    inGroup: false,
    outline: false,
    loading: false,
    disabled: false,
    iconBadge: undefined,
    iconPosition: 'left',
    variant: Variant.primary,
    type: 'button',
    href: undefined,
    rel: undefined,
    target: undefined,
    to: undefined,
    icon: undefined,
    size: undefined,
    name: undefined,
    title: undefined,
    fill: false,
  },
);
const emit = defineEmits<{
  (e: 'click'): void;
}>();
const log = getLogger('Btn');
const onClick = () => {
  log('Btn click');
  emit('click');
};

const textClass = computed(() => {
  const size = props.size;
  return {
    'text-xs': size === 'xs',
    'text-sm': size === 'sm',
    'text-md': !size || size === 'md',
    'text-lg font-medium': size === 'lg',
  };
});

const slotClass = computed(() => {
  return props.fill
    ? 'absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center'
    : '';
});

const paddingClass = computed(() => {
  const size = props.size;
  return {
    'px-1.5 py-0.5': size === 'xs',
    'px-3 py-1': size === 'sm',
    'px-5 py-1.5': !size || size === 'md',
    'px-5 py-2': size === 'lg',
  };
});

const classes = computed<{ [k: string]: boolean | string | undefined | null }>(
  () => {
    switch (props.variant) {
      case Variant.white:
        return {
          'bg-white hover:bg-gray-50': !props.disabled,
        };
      case Variant.primary:
        return {
          'hover:text-white hover:bg-blue-700': !props.disabled,
          'text-white bg-blue-600 border-blue-600': !props.outline,
          'text-blue-900 border-blue-700': props.outline,
        };
      case Variant.warning:
        return {
          'hover:text-amber-900 hover:bg-amber-400': !props.disabled,
          'text-amber-900 bg-amber-300 border-amber-300': !props.outline,
          'text-amber-900 border-amber-300': props.outline,
        };
      case Variant.danger:
        return {
          'hover:text-white hover:bg-red-800': !props.disabled,
          'text-white bg-red-700 border-red-700': !props.outline,
          'text-red-800 border-red-700': props.outline,
        };
      case Variant.success:
        return {
          'hover:text-white hover:bg-emerald-700': !props.disabled,
          'text-white bg-emerald-600 border-emerald-600': !props.outline,
          'text-emerald-600 border-emerald-600': props.outline,
        };
      case Variant.info:
        return {
          'text-white bg-indigo-500 border-indigo-500': true,
          'hover:bg-indigo-600': true,
        };
      case Variant.light:
        return {
          'text-gray-900 bg-gray-200': true,
          'hover:bg-gray-300': true,
        };
      case Variant.secondary:
        return {
          'text-white bg-gray-700': !props.outline,
          'text-gray-700 border-gray-700 border-gray-700': props.outline,
          'hover:text-white hover:bg-gray-800': !props.disabled,
        };
      case Variant.upgrade:
      default:
        return { 'btn-orange': true };
    }
  },
);
</script>
