export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"key":"description","name":"description","content":"Email and SMS testing and automation. Create email addresses on demand. Send and receive emails and attachments in code and tests."},{"key":"version","name":"version","content":"972dab6058f6e8695a3c0003da888cf8f6004021"},{"key":"isproduction","name":"isproduction","content":true},{"property":"og:title","content":"Email Account API Dashboard"},{"property":"og:image","content":"https://www.mailslurp.com/assets/screenshots/dash-or-code.jpg"},{"property":"og:image:secure_url","content":"https://www.mailslurp.com/assets/screenshots/dash-or-code.jpg"},{"property":"og:image:type","content":"image/jpeg"},{"property":"og:image:width","content":"1428"},{"property":"og:image:height","content":"505"},{"property":"og:image:alt","content":"MailSlurp email account dashboard and API access."},{"name":"theme-color","content":"#079669"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"prefetch","href":"/assets/logo.svg"},{"rel":"prefetch","href":"/open-email.svg"}],"style":[],"script":[],"noscript":[],"title":"MailSlurp Dashboard"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000