<template>
  <div
    class="flex min-h-full w-full bg-slate-800"
    data-el="auth-page"
    :data-id="dataId"
    :data-loading="loading ? '1' : '0'"
    :data-auth-card-active-session="hasActiveSession ? '1' : '0'"
  >
    <div
      class="relative flex min-h-full w-full shrink-0 justify-center md:px-12 lg:px-0"
    >
      <article
        class="relative z-10 flex w-full flex-1 flex-col bg-white px-4 py-6 shadow-2xl md:flex-none md:justify-center md:px-20 lg:py-10"
      >
        <nav
          class="static left-0 top-0 mb-8 flex w-full flex-col items-start justify-between gap-3 md:absolute md:mb-0 md:p-10 lg:flex-row"
        >
          <NuxtLink
            class="align-center flex flex-row justify-center gap-2 text-center"
            tabindex="-1"
            data-el="auth-page-logo"
            to="/"
          >
            <img class="h-8" src="/assets/logo-icon.svg" alt="MailSlurp" />
            <span class="text-2xl font-bold tracking-wide">MailSlurp</span>
          </NuxtLink>
          <CardPopOverLinks
            v-if="hasActiveSession && !loading"
            data-flash-id="current-session-banner"
            :rounded="'all'"
          >
            <NuxtLink
              class="text-sm text-gray-600 no-underline hover:underline"
              data-id="alert-button"
              data-alert-to="/logout"
              to="/logout"
              >Logout
            </NuxtLink>
            <NuxtLink
              class="text-sm text-indigo-600 underline hover:no-underline"
              data-id="alert-button"
              data-alert-to="/dashboard"
              to="/dashboard"
              >Resume your active session
              <span class="text-xs"><Icon icon="arrowRight" /></span
            ></NuxtLink>
          </CardPopOverLinks>
        </nav>
        <AuthCard v-if="loading" enable-flash :loading="loading">
          <ResourceLoading />
        </AuthCard>
        <AuthCard
          v-else-if="done"
          :loading="loading"
          enable-flash
          data-id="done-display"
        >
          <slot name="done" />
        </AuthCard>
        <section v-else>
          <AuthCard
            class="flex flex-col gap-6"
            v-if="$slots.form"
            :loading="loading"
            show-session-banner
            enable-flash
            :title="title"
            :description="subtitle"
            :terms="terms"
          >
            <section class="flex flex-col gap-6">
              <div v-if="!showForm && !showFormForce && showFederatedLogin">
                <div class="grid grid-cols-1 gap-4">
                  <AuthBtn
                    :context="context"
                    provider="google"
                    data-id="sso-btn-google"
                    :href="`${authUrl}/auth/google`"
                    text="Google"
                  >
                    <template #icon>
                      <svg
                        class="h-5 w-5"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                      >
                        <path
                          d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                          fill="#EA4335"
                        />
                        <path
                          d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                          fill="#4285F4"
                        />
                        <path
                          d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                          fill="#34A853"
                        />
                      </svg>
                    </template>
                  </AuthBtn>
                  <AuthBtn
                    :context="context"
                    provider="microsoft"
                    data-id="sso-btn-microsoft"
                    :href="`${authUrl}/auth/microsoft`"
                    text="Microsoft"
                  >
                    <template #icon>
                      <svg
                        class="h-5 w-5"
                        viewBox="0 0 16 16"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                      >
                        <path fill="#F35325" d="M1 1h6.5v6.5H1V1z" />
                        <path fill="#81BC06" d="M8.5 1H15v6.5H8.5V1z" />
                        <path fill="#05A6F0" d="M1 8.5h6.5V15H1V8.5z" />
                        <path fill="#FFBA08" d="M8.5 8.5H15V15H8.5V8.5z" />
                      </svg>
                    </template>
                  </AuthBtn>

                  <AuthBtn
                    :context="context"
                    provider="github"
                    data-id="sso-btn-github"
                    :href="`${authUrl}/auth/github`"
                    text="GitHub"
                  >
                    <template #icon>
                      <svg
                        class="h-5 w-5 fill-[#24292F]"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </template>
                  </AuthBtn>
                </div>
              </div>
              <div
                class="relative"
                v-if="!showForm && !showFormForce && showFederatedLogin"
              >
                <div
                  class="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div class="w-full border-t border-gray-200" />
                </div>
                <div
                  class="relative flex justify-center text-sm font-medium leading-6"
                >
                  <span class="bg-white px-6 text-gray-900">Or</span>
                </div>
              </div>
              <div>
                <div class="flex" v-if="!showForm && !showFormForce">
                  <Btn
                    type="button"
                    :fill="true"
                    :tabindex="submitTabindex"
                    data-id="card-page-submit"
                    data-show-form
                    tag="button"
                    size="lg"
                    :variant="Variant.primary"
                    icon="arrowRight"
                    icon-position="right"
                    @click="showFormForce = true"
                  >
                    Use email
                  </Btn>
                </div>
                <form
                  class="space-y-6"
                  v-if="showForm || showFormForce"
                  @submit.prevent="onSubmit"
                >
                  <slot name="form" />
                  <div>
                    <Btn
                      type="submit"
                      :fill="true"
                      :tabindex="submitTabindex"
                      data-id="card-page-submit"
                      tag="button"
                      size="lg"
                      :variant="Variant.primary"
                      icon="arrowRight"
                      icon-position="right"
                    >
                      {{ formButton }}
                    </Btn>
                  </div>
                </form>
              </div>

              <p
                class="lg:text-md mt-1 text-sm font-normal text-gray-900 lg:mt-2 lg:text-lg"
                v-if="$slots.subtitle"
              >
                <slot name="subtitle" />
              </p>
              <section
                class="align-center flex flex-row justify-start gap-5 text-xs"
                v-if="$slots.links"
              >
                <slot name="links" />
              </section>
            </section>
          </AuthCard>
        </section>
        <footer class="mt-8" v-if="$slots.notes && (done || showNotes)">
          <slot name="notes" />
        </footer>
      </article>
    </div>
  </div>
</template>
<script setup lang="ts">
import ResourceLoading from '../../components/charts/ResourceLoading.vue';
import AuthCard from '../../components/auth/AuthCard.vue';
import CardPopOverLinks from '../../components/tw/CardPopOverLinks.vue';
import { watch, onMounted, ref } from 'vue';
import { useStore3 } from '../../store/main';
import Btn from '../../components/form/Btn.vue';
import { Variant } from '../../store/types';
import AuthBtn from '../../components/auth/AuthBtn.vue';
import { useRuntimeConfig } from '#imports';
import Icon from '../../components/Icon.vue';
import { storeToRefs } from 'pinia';
import { useNuxtApp } from '#imports';
const { $captureEvent: captureEvent } = useNuxtApp();
const props = withDefaults(
  defineProps<{
    dataId?: string;
    context: string;
    submitTabindex?: number;
    showFederatedLogin?: boolean;
    title: string;
    subtitle?: string;
    terms?: boolean;
    formButton?: string;
    done?: boolean;
    loading?: boolean;
    showNotes?: boolean;
    showForm?: boolean;
  }>(),
  {
    dataId: undefined,
    submitTabindex: 0,
    showFederatedLogin: false,
    formButton: 'Submit',
    done: false,
    terms: false,
    subtitle: '',
    showNotes: false,
    loading: false,
    showForm: false,
  },
);
const emit = defineEmits<{ (e: 'submit'): void }>();
const onSubmit = () => emit('submit');
const runtimeConfig = useRuntimeConfig();
const authUrl = runtimeConfig.public.authUrl;
const store3 = useStore3();
const { user } = storeToRefs(store3);
const hasActiveSession = ref(false);
const showFormForce = ref(false);
onMounted(() => {
  hasActiveSession.value = !!user.value;
  captureEvent('auth_page', {
    context: props.context,
  });
});
watch(
  () => store3.user,
  (newValue) => {
    hasActiveSession.value = !!newValue;
  },
);
</script>
