import { combineUrlAndParams } from './combineUrlAndParams';
import type { UpgradeAddon } from './types';
import {
  AccountDtoAddonsEnum,
  AppendProductAddonPlanAddonEnum
} from '@mailslurp/admin-sdk';
import {
  type AddonPagePages,
  pricingFeatureTableRowId,
  type PricingQueryParams,
  type UpgradeProductType
} from './helpers';
import { stringify } from 'qs';

export function getUpgradeCallbackUrl(productType?: UpgradeProductType) {
  if (productType) {
    const params: UpgradeCallbackParams = {
      productType: productType
    };
    return (
      '/upgrade/callback/?' +
      stringify(params, {
        skipNulls: true
      })
    );
  } else {
    return '/upgrade/callback/';
  }
}

export type UpgradeCallbackParams = {
  productType: UpgradeProductType;
};

export function getPricingPageUrl(params: PricingQueryParams): string {
  const hasHighlight = params.highlightFeature || params.highlightLimit;
  return `${combineUrlAndParams('/upgrade', params)}${
    hasHighlight
      ? '#' +
      pricingFeatureTableRowId(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (params.highlightLimit ?? params.highlightFeature) as any
      )
      : ''
  }`;
}

export function getUpgradePageUrl(planId?: string): string {
  return `/upgrade/${planId ?? ''}`;
}

export function addonToPlanAddonExclusive(
  addon: UpgradeAddon
): AccountDtoAddonsEnum | undefined {
  switch (addon) {
    case 'inboxes':
      return AccountDtoAddonsEnum.INBOXES;
    case 'emails':
      return AccountDtoAddonsEnum.EMAILS;
    case 'users':
      return undefined;
    case 'phones':
      return undefined;
    case 'validation':
      return AccountDtoAddonsEnum.VALIDATION;
    case 'connector':
      return AccountDtoAddonsEnum.CONNECTORS;
    case 'ai':
      return AccountDtoAddonsEnum.AI;
  }
}

export function addonToPlanAddon(
  addon: UpgradeAddon
): AppendProductAddonPlanAddonEnum | 'phones' | 'users' {
  switch (addon) {
    case 'guests':
      return AppendProductAddonPlanAddonEnum.GUEST;
    case 'inboxes':
      return AppendProductAddonPlanAddonEnum.INBOXES;
    case 'emails':
      return AppendProductAddonPlanAddonEnum.EMAILS;
    case 'users':
      return 'users';
    case 'phones':
      return 'phones';
    case 'validation':
      return AppendProductAddonPlanAddonEnum.VALIDATION;
    case 'connector':
      return AppendProductAddonPlanAddonEnum.CONNECTORS;
    case 'ai':
      return AppendProductAddonPlanAddonEnum.AI;
  }
}

export function getUpgradePageAddonUrl(
  addonType: AppendProductAddonPlanAddonEnum | 'phones' | 'users',
  params: AddonPagePages
): string {
  if (addonType === 'phones' || addonType === 'users') {
    return `${combineUrlAndParams(`/upgrade/${addonType}/`, params)}`;
  }
  return `${combineUrlAndParams(`/upgrade/addons/${addonType}/`, params)}`;
}