import {
  type AccessAccountsDto,
  type AccountDto,
  type AccountDtoAddonsEnum,
  type AIPlanDto,
  type AliasDto,
  type AllQuotesDto,
  type AttachmentEntity,
  type AttachmentMetaData,
  type BillingPortalDto,
  type BouncedEmailDto,
  type BouncedRecipientDto,
  type CanResult,
  type CheckEmailBodyFeatureSupportResults,
  type CheckEmailBodyResults,
  type CheckoutAppendSessionDto,
  type CheckoutSessionDto,
  type Complaint,
  type ConnectorDto,
  type ConnectorEventDto,
  type ConnectorImapConnectionDto,
  type ConnectorImapConnectionTestResult,
  type ConnectorPlanDto,
  type ConnectorSmtpConnectionDto,
  type ConnectorSmtpConnectionTestResult,
  type ConnectorSyncSettingsDto,
  type ContactDto,
  type DeliveryStatusDto as DeliveryStatus,
  type DescribeMailServerDomainResult,
  type DNSLookupResults,
  type DomainDto,
  type DomainGroupsDto,
  type DomainIssuesDto,
  type DomainPreview,
  type Email,
  type EmailPlanDto,
  type EmailPreview,
  type EmailThreadDto,
  type EmailThreadItemsDto,
  type EmailValidationSubscription,
  type EmailVerificationResult,
  type EmergencyAddressDto,
  type ErrorCodesDtoAppErrorCodesEnum,
  type EventAggregateResultsSeries,
  type ExpirationDefaults,
  type ExpiredInboxDto,
  type GroupDto,
  type HasOnboardedFeatures,
  type HasUserMadeActionDto,
  type ImapSmtpAccessDetails,
  type InboxDto,
  type InboxForwarderDto,
  type InboxForwarderEventDto,
  type InboxForwarderTestResult,
  type InboxPlanDto,
  type InboxReplierDto,
  type InboxRulesetDto,
  type InvoiceDto,
  type LatestMessagesEmailDto,
  type LatestMessagesSmsDto,
  type MissedEmailDto as MissedEmail,
  type MonthlyUsageCharts,
  type Notification,
  type NotificationMetrics,
  type NotificationProjection,
  type OpenQuotesDto,
  type Organization,
  type OrganizationSamlServiceProviderData,
  type OrganizationSamlSettingsDto,
  type OrganizationSettingsOptionalDto,
  type OtherAccountsDto,
  type PageAlias,
  type PageAttachmentEntity,
  type PageBouncedEmail,
  type PageBouncedRecipients,
  type PageComplaint,
  type PageConnector,
  type PageConnectorEvents,
  type PageContactProjection,
  type PageDeliveryStatus,
  type PageEmailProjection,
  type PageEmailThreadProjection,
  type PageEmailValidationRequest,
  type PageExpiredInboxRecordProjection,
  type PageGroupProjection,
  type PageInboxForwarderDto,
  type PageInboxForwarderEvents,
  type PageInboxProjection,
  type PageInboxReplierDto,
  type PageInboxReplierEvents,
  type PageInboxRulesetDto,
  type PageMissedEmailProjection,
  type PageOrganizationUser,
  type PagePhoneNumberProjection,
  type PageScheduledJobs,
  type PageSentEmailProjection,
  type PageSmsProjection,
  type PageStripeWebhookEvents,
  type PageTemplateProjection,
  type PageAliasThreadProjection as PageThreadProjection,
  type PageTrackingPixelProjection,
  type PageUserEventProjections,
  type PageWebhookProjection,
  type PageWebhookResult,
  type PageZapierNewAttachment,
  type PageZapierNewContact,
  type PageZapierNewEmail,
  type PaymentFailure,
  type PhoneNumberDto,
  type PhonePlanDto,
  type PlanDisplay,
  type PlanDisplayCurrencyEnum,
  type PlanDisplays,
  type PlanDisplayScheduleEnum,
  type PlanLimits,
  type ProductBatchedAddonPlanDisplays,
  type ProductPhoneNumberDisplays,
  type ProductPhonePlanDisplays,
  type ProductUserPlanDisplays,
  type QuoteDto,
  type QuoteInvoiceDto,
  type QuoteParams,
  type ReplyForSms,
  type ReputationMetrics,
  type ScheduledJobDto,
  type SentEmailDto,
  type SmsDto,
  type StatsConstants,
  type SubscriptionDto,
  type SubscriptionMetrics,
  type TemplateDto,
  type TemplateProjection,
  type TemporaryAccessKeyDto,
  type TestInboxRulesetReceivingResult,
  type TestInboxRulesetSendingResult,
  type AliasThreadProjection as ThreadProjection,
  type TrackingPixelDto,
  type UnblockDefaultSenderResult,
  type UsageDto,
  type UsageRecordSeries,
  type UsageRecordUpdateResultsSeries,
  type User,
  type UserDto,
  type UserPlanDto,
  type UserUnfreezeResult,
  type ValidateEmailAddressListResult,
  type ValidationDto,
  type WebhookDto,
  type WebhookResultDto,
  type ZapierConnection,
} from '@mailslurp/admin-sdk';
import type { GeoResult } from '../lib/services/LambdaService';
import type { OffboardingAction } from '../lib/services/helpers';
import type {
  ConnectorProviderSettingsDto,
  PageableObject as Pageable,
} from '@mailslurp/admin-sdk';
import { useStore3 } from './main';
import type {
  GuestPlanDto,
  GuestPortalDto,
  GuestPortalUserCreateDto,
  GuestPortalUserDto,
  PageGuestPortalUsers,
  PhonePlanAvailability,
} from '@mailslurp/admin-sdk';

export enum Variant {
  'white' = 'white',
  'light' = 'light',
  'danger' = 'danger',
  'success' = 'success',
  'info' = 'info',
  'warning' = 'warning',
  'secondary' = 'secondary',
  'upgrade' = 'upgrade',
  'primary' = 'primary',
}

export type Types = Flash & { variant: FlashVariant };
export type SearchSelectOption = {
  text: string;
  value: string;
  copyValue?: string;
  routerLink?: string;
};
export type FlashVariant = Variant;
export type ErrorPayload = {
  text?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};
export type Flash = {
  variant: Variant;
  text?: string;
  path?: RegExp;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  errorCodeVariant?: ErrorCodesDtoAppErrorCodesEnum;
  routerLink?: { to: string; text: string; external?: boolean };
};
/**
 * Define state of store
 */
export type IState = {
  ready: boolean;

  temporaryAccessKeyForAttachments: TemporaryAccessKeyDto | null;
  temporaryAccessKeyForEmails: TemporaryAccessKeyDto | null;
  complaintPagination: PageComplaint | null;
  deliveryStatus: DeliveryStatus | null;
  deliveryStatusPagination: PageDeliveryStatus | null;
  organizationUserLimits: CanResult | null;
  geo: null | GeoResult;

  sessionExpired: null | boolean;
  apiAccessToken: null | TemporaryAccessKeyDto;
  requestedPlan: null | string;
  requestedLanguage: null | string;
  user: null | UserDto;
  onboardingFeatures: null | HasOnboardedFeatures;
  hasUserMadeAction: null | HasUserMadeActionDto;

  phoneNumbers: null | PagePhoneNumberProjection;
  phoneNumbersPagination: null | PagePhoneNumberProjection;
  phoneNumber: null | PhoneNumberDto;
  smsMessages: null | PageSmsProjection;
  smsMessage: null | SmsDto;
  replyForSms: null | ReplyForSms;
  emergencyAddresses: null | EmergencyAddressDto[];

  testInboxRulesetSendingResult: null | TestInboxRulesetSendingResult;
  testInboxRulesetReceivingResult: null | TestInboxRulesetReceivingResult;
  allInboxRulesets: null | PageInboxRulesetDto;
  inboxInboxRulesets: null | PageInboxRulesetDto;
  inboxRuleset: null | InboxRulesetDto;

  inboxForwarderTestResult: null | InboxForwarderTestResult;
  allInboxForwarders: null | PageInboxForwarderDto;
  inboxInboxForwarders: null | PageInboxForwarderDto;
  inboxForwarderEvents: null | PageInboxForwarderEvents;
  inboxForwarder: null | InboxForwarderDto;
  inboxForwarderEvent: null | InboxForwarderEventDto;

  // tracking
  complaint: null | Complaint;
  bouncedEmailPagination: null | PageBouncedEmail;
  bouncedRecipientPagination: null | PageBouncedRecipients;
  bouncedEmail: null | BouncedEmailDto;
  bouncedRecipient: null | BouncedRecipientDto;

  quote: null | QuoteDto;
  quoteInvoice: null | QuoteInvoiceDto;
  quoteParams: null | QuoteParams;
  allQuotes: null | AllQuotesDto;
  userLimits: null | PlanLimits;
  openQuotes: null | OpenQuotesDto;
  otherAccounts: null | OtherAccountsDto;
  accounts: null | AccessAccountsDto;
  exportDownloadLink: null | string;
  alias: null | AliasDto;
  thread: null | ThreadProjection;
  aliasPagination: null | PageAlias;
  threadPagination: null | PageThreadProjection;
  validationRequestPagination: null | PageEmailValidationRequest;

  zapierConnection: null | ZapierConnection;
  zapierNewEmailRecords: null | PageZapierNewEmail;
  zapierNewContactRecords: null | PageZapierNewContact;
  zapierNewAttachmentRecords: null | PageZapierNewAttachment;

  unblockDefaultSenderResult: null | UnblockDefaultSenderResult;
  unfreezeUserResult: null | UserUnfreezeResult;
  flash: null | Flash;
  allPlans: null | PlanDisplays;
  stats: null | UsageDto;
  checkoutSession: null | CheckoutSessionDto;
  checkoutAppendSession: null | CheckoutAppendSessionDto;
  statsConstants: null | StatsConstants;
  usageCharts: null | MonthlyUsageCharts;
  billingPortal: null | BillingPortalDto;
  paymentFailures: null | Array<PaymentFailure>;
  invoices: null | Array<InvoiceDto>;
  subscription: null | SubscriptionDto;
  account: null | AccountDto;
  validateEmailListResult: null | ValidateEmailAddressListResult;

  // sent
  sent: null | SentEmailDto;
  sentsAllPagination: null | PageSentEmailProjection;
  sentsInboxPagination: null | PageSentEmailProjection;

  describeDomainResult: null | DescribeMailServerDomainResult;
  verifyEmailAddressResult: null | EmailVerificationResult;

  inboxRepliers: null | PageInboxReplierDto;
  inboxInboxRepliers: null | PageInboxReplierDto;
  inboxReplierEvents: null | PageInboxReplierEvents;
  inboxReplier: null | InboxReplierDto;

  // scheduled jobs
  scheduledJob: null | ScheduledJobDto;
  scheduledJobsPagination: null | PageScheduledJobs;

  // domains
  domain: null | DomainDto;
  domains: null | DomainPreview[];
  domainGroups: null | DomainGroupsDto;
  domainIssues: null | DomainIssuesDto;

  // attachments
  attachment: null | AttachmentEntity;
  attachmentPagination: null | PageAttachmentEntity;

  // metrics
  metricsNotifications: null | NotificationMetrics;
  metricsSubscription: null | SubscriptionMetrics;
  metricsReputation: null | ReputationMetrics;

  // latest messages
  latestMessagesEmails: null | LatestMessagesEmailDto;
  latestMessagesSms: null | LatestMessagesSmsDto;

  // connectors
  allConnectors: null | PageConnector;
  connector: null | ConnectorDto;
  connectorEvent: ConnectorEventDto | null;
  connectorImapConnection: null | ConnectorImapConnectionDto;
  connectorSmtpConnection: null | ConnectorSmtpConnectionDto;
  connectorSyncSettings: null | ConnectorSyncSettingsDto;

  inbox: null | InboxDto;
  email: null | Email;
  emailValidation: null | ValidationDto;
  emailRaw: null | string;
  expiredInbox: null | ExpiredInboxDto;
  phonePlansAvailability: null | PhonePlanAvailability;
  connectorProviderSetting: null | ConnectorProviderSettingsDto;
  connectorSmtpConnectionTestResult: null | ConnectorSmtpConnectionTestResult;
  connectorImapConnectionTestResult: null | ConnectorImapConnectionTestResult;
  connectorEventsPagination: null | PageConnectorEvents;
  expiredInboxRecords: null | PageExpiredInboxRecordProjection;
  inboxes: null | InboxDto[];
  inboxTags: null | string[];
  emails: null | EmailPreview[];
  emailThreadPagination: null | PageEmailThreadProjection;
  emailThreadItems: null | EmailThreadItemsDto;
  emailThread: null | EmailThreadDto;
  emailPagination: null | PageEmailProjection;
  inboxPagination: null | PageInboxProjection;
  inboxWebhooks: null | WebhookDto[];
  inboxEmailPagination: null | PageEmailProjection;
  favInboxPagination: null | PageInboxProjection;
  // for use with viewing an email
  attachments: null | AttachmentMetaData[];
  unreadCount: number;
  expirationDefaults: null | ExpirationDefaults;
  userEvents: null | PageUserEventProjections;
  stripeWebhookEvents: null | PageStripeWebhookEvents;
  webhook: null | WebhookDto;
  inboxWebhooksPagination: null | PageWebhookProjection;
  phoneWebhooksPagination: null | PageWebhookProjection;
  webhookResult: null | WebhookResultDto;
  webhookResults: null | PageWebhookResult;
  allWebhookResults: null | PageWebhookResult;
  webhooks: null | WebhookDto[];
  webhooksPagination: null | PageWebhookProjection;

  portal: null | GuestPortalDto;
  portals: null | GuestPortalDto[];
  guestsPagination: null | PageGuestPortalUsers;
  guest: null | GuestPortalUserDto;
  guestCreated: null | GuestPortalUserCreateDto;
  guestPlans: null | GuestPlanDto[];

  group: null | GroupDto;
  groups: null | GroupDto[];
  groupsPagination: null | PageGroupProjection;
  groupContactsPagination: null | PageContactProjection;

  contact: null | ContactDto;
  contacts: null | ContactDto[];
  contactsPagination: null | PageContactProjection;

  checkEmailBodyResults: null | CheckEmailBodyResults;
  checkEmailBodyFeatureSupportResults: null | CheckEmailBodyFeatureSupportResults;

  template: null | TemplateDto;
  templates: null | TemplateProjection[];
  templatesPagination: null | PageTemplateProjection;
  sidebarSmOpen: boolean | null;
  sidebarLgMinified: boolean;
  organizations: null | Organization[];
  organizationSamlSettings: null | OrganizationSamlSettingsDto;
  organizationSettings: null | OrganizationSettingsOptionalDto;
  organizationSamlServiceProviderData: null | OrganizationSamlServiceProviderData;
  organization: null | Organization;
  organizationUsers: null | User[];
  organizationUsersPaginated: null | PageOrganizationUser;
  imapSmtpAccess: null | ImapSmtpAccessDetails;
  seriesEventAggregates: null | EventAggregateResultsSeries;
  seriesUsage: null | UsageRecordSeries;
  seriesUsageRecordUpdates: null | UsageRecordUpdateResultsSeries;
  missedEmail: null | MissedEmail;
  missedEmailPagination: null | PageMissedEmailProjection;
  notifications: null | NotificationProjection[];
  notification: null | Notification;
  dnsLookupResults: null | DNSLookupResults;
  trackingPixel: null | TrackingPixelDto;
  allTrackingPixels: null | PageTrackingPixelProjection;
  sentEmailTrackingPixels: null | PageTrackingPixelProjection;
  allSentEmailTrackingPixels: null | PageTrackingPixelProjection;
  inboxTrackingPixels: null | PageTrackingPixelProjection;
  offboardingAction: null | OffboardingAction;

  dashboardLoaded: boolean;

  // upgrade stuff
  selectedCurrency: PlanDisplayCurrencyEnum;
  selectedSchedule: PlanDisplayScheduleEnum;
  selectedCurrencySymbol: string | null;
  // selected products, change when currency and schedule change
  selectedUserPlanQuantity: number;
  selectedTrialEnabled: boolean;
  // displays
  plans: PlanDisplay[] | null;
  productEmailValidation: null | ProductBatchedAddonPlanDisplays;
  productPhoneNumbers: null | ProductPhoneNumberDisplays;
  productPhonePlans: null | ProductPhonePlanDisplays;
  userAddons: null | AccountDtoAddonsEnum[];
  productEmailPlans: null | ProductBatchedAddonPlanDisplays;
  productInboxPlans: null | ProductBatchedAddonPlanDisplays;
  productUserPlans: null | ProductUserPlanDisplays;
  productConnectorPlans: null | ProductBatchedAddonPlanDisplays;
  productAIPlans: null | ProductBatchedAddonPlanDisplays;
  productGuestPlans: null | ProductBatchedAddonPlanDisplays;

  // user plans
  validationPlan: null | EmailValidationSubscription;
  userPlans: null | UserPlanDto[];
  phonePlans: null | PhonePlanDto[];
  emailPlans: null | EmailPlanDto[];
  inboxPlans: null | InboxPlanDto[];
  aiPlans: null | AIPlanDto[];
  connectorPlans: null | ConnectorPlanDto[];
};
// istate plus custom getters type for $store3. access
export type IGetters = IState & {
  isEnterprise: boolean;
  isChild: boolean;
  isVendor: boolean;
  hasUnreadNotifications: boolean;
  unreadNotificationsCount: number;
};
export type Page<T> = {
  page?: number;
  size?: number;
  sort?: T;
  since?: Date;
  before?: Date;
};
export type Sort = 'DESC' | 'ASC';
export type IGetter = keyof IState;
export type IStore = ReturnType<typeof useStore3>;
export type IAction = KeyOfType<
  IStore,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (() => any) | ((x: any) => any)
>;
type KeyOfType<T, V> = keyof {
  [P in keyof T as T[P] extends V ? P : never]: unknown;
};
export type IActionWithNoParams = KeyOfType<IStore, () => unknown>;
export type IActionReturningPageableResult<T> = KeyOfType<
  IStore,
  () => PageableResult<T>
>;
export type PageableResult<T> = {
  totalElements: number;
  content: Array<T>;
  pageable: Pageable;
};

export type PageRequestPayload = {
  unreadOnly?: boolean;
  page?: number;
  size?: number;
  searchFilter?: string;
  search?: string;
  sort?: Sort;
  before?: Date;
  since?: Date;
  tag?: string;
};
