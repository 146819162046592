<template>
  <header
    class="page-content-header"
    v-if="title"
    data-id="page-content-header"
  >
    <div class="flex-lg-row flex flex-col gap-2">
      <div class="flex w-full flex-1 flex-col items-start justify-start">
        <section
          class="flex w-full flex-row gap-2"
          data-el="content-page-header-section"
        >
          <div class="page-title-wrapper flex flex-1 items-center gap-2">
            <div class="align-center flex flex-row justify-center gap-3">
              <h1
                class="d-inline-flex break-all text-lg font-semibold text-gray-800 sm:text-3xl"
                data-el="content-page-header-title"
              >
                {{ title }}
              </h1>

              <p class="max-w-4xl text-sm text-gray-700" v-if="description">
                {{ description }}
              </p>
            </div>
            <span class="flex flex-row gap-2" v-if="tags && tags.length">
              <FormBadge
                v-for="tag in tags"
                size="xs"
                :variant="tag.variant"
                :key="tag.name"
                :data-id="tag.dataId"
              >
                <abbr :title="tag.info">
                  {{ tag.name }}
                </abbr>
              </FormBadge>
            </span>
            <Info
              v-if="moreInfo || subtitle"
              data-el="content-page-header-description"
              :value="moreInfo || subtitle"
            />
          </div>

          <nav
            class="hidden flex-row flex-wrap items-center justify-center gap-2 lg:flex"
          >
            <div class="button-row-item" v-if="button">
              <Btn
                size="sm"
                icon-position="left"
                :circle="true"
                :tag="button.external ? 'a' : 'NuxtLink'"
                data-el="cph-button"
                :data-id="
                  button.dataId ||
                  (button.external
                    ? 'page-button-external'
                    : 'page-button-internal')
                "
                :to="button.external ? undefined : button.to"
                :href="button.external ? button.to : undefined"
                :icon="button.icon"
                :variant="Variant.primary"
                :title="button.text"
              >
                {{ button.text }}
              </Btn>
            </div>
            <div class="button-row-item" v-if="notChild && showUpgrade">
              <Btn
                tag="NuxtLink"
                icon="upgrade"
                icon-position="right"
                :variant="Variant.warning"
                size="sm"
                to="/upgrade/"
              >
                {{ 'Upgrade account' }}
              </Btn>
            </div>
            <div class="button-row-item" v-if="showDocumentation">
              <Btn
                tag="a"
                href="https://docs.mailslurp.com"
                :variant="Variant.info"
                size="sm"
              >
                {{ 'Documentation' }}
              </Btn>
            </div>

            <div class="button-row-item" v-if="notChild && showGrantAccess">
              <Btn
                tag="button"
                size="sm"
                :variant="Variant.info"
                @click="onGrantAccess"
              >
                {{ 'Grant support access' }}
              </Btn>
            </div>
            <div class="button-row-item" v-if="notChild && showNotifications">
              <NotificationDropDown />
            </div>

            <slot />
          </nav>
        </section>
      </div>
    </div>
  </header>
</template>
<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue';
import NotificationDropDown from '../../components/NotificationDropDown.vue';
import Btn from '../../components/form/Btn.vue';
import { useStore3 } from '../../store/main';
import { storeToRefs } from 'pinia';
import FormBadge from '../../components/form/FormBadge.vue';
import { Variant } from '../../store/types';
import type { MsIcon, PageButton } from '../../lib/services/types';
const Info = defineAsyncComponent(
  () => import('../../components/form/Info.vue'),
);

withDefaults(
  defineProps<{
    moreInfo?: string;
    subtitle?: string;
    description?: string;
    title?: string;
    showGrantAccess?: boolean;
    showDocumentation?: boolean;
    showUpgrade?: boolean;
    showNotifications?: boolean;
    tags?: {
      name: string;
      info: string;
      variant?: Variant;
      dataId: string;
    }[];
    button?: PageButton;
  }>(),
  {
    moreInfo: undefined,
    title: undefined,
    subtitle: undefined,
    description: undefined,
    tags: undefined,
    button: undefined,
  },
);
const emit = defineEmits<{
  (e: 'grant'): void;
}>();
const store3 = useStore3();
const { isChild } = storeToRefs(store3);
const notChild = computed(() => !isChild.value);
const onGrantAccess = () => {
  emit('grant');
};
</script>
