<template>
  <div class="fill" :data-regression="isRegression ? '1' : '0'">
    <div class="fill flex flex-col">
      <article
        class="flex w-full flex-1 flex-row"
        :data-environment="environment"
      >
        <Page
          :title="title"
          fill-page
          :hide-sidebar="!user"
          :hide-nav="!user"
          hide-flash
        >
          <AlertCard
            :variant="Variant.danger"
            title="Unexpected Error"
            :text="text"
            :stack="stack"
            :links="links"
          >
            <p class="text-lg">
              We apologize for the exception. Please
              <a class="text-link" :href="support" target="_blank"
                >report your issue to support</a
              >.
            </p>
          </AlertCard>
        </Page>
      </article>
    </div>
  </div>
</template>

<script setup lang="ts">
import Page from './components/Page.vue';
import { computed, onMounted, ref } from 'vue';
import { useStore3 } from './store/main';
import { storeToRefs } from 'pinia';
import { Variant } from './store/types';
import { registerErrorLocally } from './lib/services/helpers';
import AlertCard from './components/tw/AlertCard.vue';
import type { AppConfig } from './lib/services/types';
import { useRuntimeConfig } from '#imports';
import type { NuxtError } from '#app';
import { links as linkList } from '@mailslurp/common/dist/links';

const CONFIG: AppConfig = useRuntimeConfig().public;

const support = linkList.support.url;
const props = defineProps<{
  error: NuxtError;
}>();

const isRegression = ref<boolean>(false);

const title = computed<string>(() => {
  if (props.error?.statusCode === 404) {
    return 'Page not found' as string;
  } else {
    return 'An unexpected error occurred.' as string;
  }
});

const environment = computed(() => {
  return CONFIG?.environment ?? 'unknown';
});
const stack = computed(() => {
  return props.error?.stack ?? undefined;
});
const links = computed(() => {
  return (props.error?.statusCode ?? 0) >= 400 &&
    (props.error?.statusCode ?? 0) < 500
    ? [
        {
          to: '/login',
          text: 'Login',
        },
      ]
    : [
        {
          href: 'https://docs.mailslurp.com/support',
          text: 'Support',
          target: '_blank',
        },
      ];
});

const text = computed<string>(() => {
  return props.error?.message ?? props.error;
});

const store3 = useStore3();

const { regressionTest, user } = storeToRefs(store3);
onMounted(() => {
  isRegression.value = regressionTest.value ?? false;
  registerErrorLocally(props.error);
});
</script>
