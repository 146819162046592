<template>
  <div
    class="inline-flex rounded-full"
    data-el="form-badge"
    :class="{
      [classes]: true,
      'px-3.5 py-1.5 text-sm': size === 'lg',
      'px-3 py-1.5 text-xs': size === 'md',
      'px-2 py-1 text-xs': size === 'sm',
      'px-1.5 py-0.5 text-xs': size === 'xs',
      'self-start': self === 'start',
      'self-center': self === 'center',
    }"
    :title="title"
  >
    <slot>
      {{ title }}
    </slot>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';

import { Variant } from '../../store/types';
import type { FormSize } from '../../lib/services/types';
import { assertUnreachable } from '../../lib/services/assertUnreachable';

const props = withDefaults(
  defineProps<{
    variant?: Variant;
    title?: string;
    size?: FormSize;
    self?: 'start' | 'center';
  }>(),
  {
    variant: Variant.light,
    title: undefined,
    size: 'md',
    self: 'center',
  },
);
const classes = computed(() => {
  switch (props.variant) {
    case Variant.white:
    case Variant.secondary:
      return 'text-white bg-gray-500 ';
    case Variant.info:
      return 'text-white bg-sky-300 ';
    case Variant.light:
      return 'text-gray-900 bg-gray-300 ';
    case Variant.danger:
      return 'text-white bg-red-500';
    case Variant.warning:
      return 'text-white bg-amber-500 ';
    case Variant.success:
      return 'text-white bg-green-500 ';
    case Variant.primary:
      return 'text-white bg-blue-500 ';
    case Variant.upgrade:
      return 'text-white bg-orange-600 ';
    default:
      return assertUnreachable(props.variant);
  }
});
</script>
