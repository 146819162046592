import { defineNuxtPlugin, watch } from '#imports';
import { useStore3 } from '../store/main';
import { storeToRefs } from 'pinia';
import { onMounted } from 'vue';
import { getLogger } from '../lib/services/getLogger';
import type { ApiError } from '../lib/services/helpers';

const getSentry = async () => import('@sentry/vue');
const log = getLogger('sentry.client');
export default defineNuxtPlugin((nuxtApp) => {
  const store3 = useStore3();
  const { user } = storeToRefs(store3);
  const { vueApp } = nuxtApp;
  const added = ref(false);
  if (!added.value) {
    added.value = true;
    getSentry().then(it => it?.init?.({
      dsn: 'https://06d6579b08704add942f541fef38f42c@o102373.ingest.sentry.io/1764251',
      app: vueApp,
      environment:
        process.env.NODE_CONFIG_ENV === 'dev' ? 'development' : 'production',
    }));
  }
  // tag user in sentry
  watch(
    () => user.value,
    (userValue) => {
      try {
        if (userValue) {
          log('Setting sentry user value');
          getSentry().then(it => it?.setUser?.({
            id: userValue.id,
            email: userValue.emailAddress,
            username: userValue.id,
          }));
        }
      } catch (e) {
        console.error(`Failed to set sentry user: value = ${userValue}`, e);
      }
    },
  );
  return {
    provide: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sentryCaptureException: (msg: string | ApiError, args: any) => getSentry().then(it => it?.captureException?.(msg, args)),
    },
  };
});
