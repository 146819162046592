<template>
  <FormDropDown
    no-button
    position="bottom-left"
    data-id="nav-user-settings"
    title="Profile"
    circle
  >
    <template #button-content>
      <span class="sr-only">Open user menu</span>
      <UserProfile v-if="user" :user="user" interactive rounded />
    </template>

    <div class="px-4 py-3" v-if="user">
      <span class="block truncate text-sm text-gray-400">{{
        truncate(user.emailAddress, 20)
      }}</span>
    </div>
    <FormDropDownItem
      v-for="item in userMenuItems"
      :key="item.text"
      :class="item.classes || ''"
      :to="item.to"
      :external="item.external"
      :label="item.text"
      :data-id="item.dataId"
    />
    <div class="px-4 py-3" v-if="hasOtherAccounts">
      <span class="block truncate text-sm text-gray-400">Workspaces</span>
    </div>

    <FormDropDownItem
      v-for="(account, i) in otherAccounts"
      :key="`workspace-${i}`"
      :data-id="'other-account-' + i"
      :to="
        account.organizationNameSlug
          ? orgLink(account.organizationNameSlug.slug)
          : '/settings'
      "
      :label="
        account.organizationNameSlug
          ? account.organizationNameSlug.name
          : 'Personal'
      "
    />
  </FormDropDown>
</template>
<script setup lang="ts">
import FormDropDownItem from '../form/FormDropDownItem.vue';
import FormDropDown from '../form/FormDropDown.vue';
import UserProfile from '../UserProfile.vue';
import { storeToRefs } from 'pinia';
import { computed, onMounted } from 'vue';
import type { OtherAccountDto, OtherAccountsDto } from '@mailslurp/admin-sdk';
import { links } from '@mailslurp/common/dist/links';
import { truncate } from '../../lib/services/helpers';
import { useStore3 } from '../../store/main';
import { getLogger } from '../../lib/services/getLogger';

const store3 = useStore3();
const { user } = storeToRefs(store3);
const orgLink = (slug: string): string => {
  return `/organization/${slug}/`;
};
const { otherAccounts: other, isChild } = storeToRefs(store3);

const otherAccounts = computed<OtherAccountDto[] | null>(() => {
  const dto: OtherAccountsDto | null = other.value;
  return dto && dto.others && dto.others.length > 0 ? dto.others : null;
});
const hasOtherAccounts = computed<boolean>(() => {
  return otherAccounts.value ? otherAccounts.value.length > 0 : false;
});
const log = getLogger('NavUserSettings');
function isNonNullable<T>(value: T): value is NonNullable<T> {
  return value != null;
}
const userMenuItems = computed<
  {
    text: string;
    to: string;
    external?: boolean;
    classes?: string;
    dataId: string;
  }[]
>(() => {
  return [
    {
      text: 'Settings',
      to: '/settings',
      dataId: 'settings',
    },
    isChild.value
      ? undefined
      : {
          text: 'Billing',
          to: '/upgrade',
          dataId: 'billing',
        },
    {
      text: 'Support',
      to: links.support.url,
      external: true,
      dataId: 'support',
    },
    {
      text: 'Logout',
      to: '/logout',
      dataId: 'logout',
    },
  ].filter(isNonNullable);
});

onMounted(async () => {
  try {
    await Promise.all([store3.getOtherAccounts()].filter((it) => it));
  } catch (e) {
    log('Error loading NavUserSettings');
  }
});
</script>
