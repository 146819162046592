import { useStore3 } from '../store/main';
import { defineNuxtRouteMiddleware, navigateTo } from '#imports';
import { Variant } from '../store/types';
import { storeToRefs } from 'pinia';
import { getLogger } from '../lib/services/getLogger';

const isPrivateRoute = (current: string) => {
  log('Check route: ' + current);
  if (['/'].includes(current)) {
    return false;
  }
  if (['/organizations'].includes(current)) {
    return true;
  }
  return !/sign.*|forgot-password|tools|tools.*|unblock.*|accounts|login|logout|verify|sso|payment.*|index|grant|organization.*/.test(
    current,
  );
};

const log = getLogger('auth.global');

export default defineNuxtRouteMiddleware(async (to) => {
  const store3 = useStore3();
  const { user, ready } = storeToRefs(store3);
  if (import.meta.server) {
    ready.value = true;
    return;
  }
  log(`Run auth to = ${to.path}`);
  if (!user.value) {
    try {
      log('Restore api key from cache: ' + to.path);
      await store3.trySetUserApiKeyFromCache();
    } catch (e: unknown) {
      log('Error restoring user from api key cache');
    }
  }
  const isPrivate = isPrivateRoute(to.path);
  if (!isPrivate) {
    log('Route non-private set ready and return');
    ready.value = true;
    return;
  }
  if (!user.value && isPrivate) {
    log('Route private and no user redirect to login');
    log('Redirecting to login');
    store3.setFlash({
      text: 'Please login.',
      variant: Variant.warning,
      path: /login/,
    });
    return navigateTo('/login');
  }
  ready.value = true;
});
