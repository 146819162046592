import { useRuntimeConfig } from '#imports';
import { defineStore } from 'pinia';
import {
  type AbstractWebhookPayload,
  type AccountDto,
  type AccountDtoAddonsEnum,
  type AddPhoneNumberOptions,
  type AddPhonePlanOptions,
  type AddUserPlanOptions,
  type AliasDto,
  type AppendProductAddonPlanRequest,
  type CancelScheduledJobRequest,
  type CanResult,
  type ContactDto,
  type CreateAliasOptions,
  type CreateConnectorImapConnectionRequest,
  type CreateConnectorRequest,
  type CreateConnectorSmtpConnectionRequest,
  type CreateConnectorWithOptionsRequest,
  type CreateContactOptions,
  type CreateDomainOptions,
  type CreateEmergencyAddressRequest,
  type CreateGroupOptions,
  type CreateInboxRequest,
  type CreateInboxRulesetOptions,
  type CreateNewInboxForwarderRequest,
  type CreateNewInboxReplierRequest,
  type CreateOnboardingSubmissionOptions,
  type CreateOrganizationSamlSettingsOptions,
  type CreateOrganizationSettingsOptions,
  type CreateSubscriptionEndedOptions,
  type CreateTemplateOptions,
  CreateTemporaryAccessKeyOptionsEndpointEnum,
  type CreateUpgradeSessionAddMissingExtras,
  type CreateUpgradeSessionWithExtras,
  type CreateUserFeedbackSubmissionOptions,
  type CreateWebhookOptions,
  type DeleteConnectorImapConnectionRequest,
  type DeleteConnectorRequest,
  type DeletePhoneNumberRequest,
  type DescribeMailServerDomainRequest,
  type DNSLookupOptions,
  type DNSLookupResults,
  type DNSLookupsOptions,
  type DomainDto,
  type DomainIssuesDto,
  type DomainPreview,
  type EmptyResponseDto,
  ErrorCodesDtoAppErrorCodesEnum,
  type ExportLink,
  type ExportOptions,
  type ForwardEmailRequest,
  type FractionLong,
  type GetAliasesRequest,
  type GetAliasThreadsRequest,
  type GetAllConnectorEventsRequest,
  type GetAllContactsRequest,
  type GetAllGroupsRequest,
  type GetAllInboxesOffsetPaginatedRequest,
  type GetAllInboxForwarderEventsRequest,
  type GetAllMissedEmailsRequest,
  type GetAllScheduledJobsRequest,
  type GetAllSentTrackingPixelsRequest,
  type GetAllTemplatesRequest,
  type GetAllTrackingPixelsRequest,
  type GetAllUnknownMissedEmailsRequest,
  type GetAllWebhookResultsRequest,
  type GetAllWebhooksRequest,
  type GetAttachmentsRequest,
  type GetBouncedEmailsRequest,
  type GetBouncedRecipientsRequest,
  type GetComplaintRequest,
  type GetComplaintsRequest,
  type GetConnectorEventRequest,
  type GetConnectorEventsRequest,
  type GetConnectorImapConnectionRequest,
  type GetConnectorRequest,
  type GetConnectorSmtpConnectionRequest,
  type GetConnectorsRequest,
  type GetEmailsOffsetPaginatedRequest,
  type GetEventAggregatesSeriesRequest,
  type GetExpiredInboxesRequest,
  type GetExpiredInboxRecordRequest,
  type GetExportLinkExportTypeEnum,
  type GetGroupWithContactsPaginatedRequest,
  type GetInboxForwarderEventsRequest,
  type GetInboxForwardersRequest,
  type GetInboxReplierEventsRequest,
  type GetInboxRepliersRequest,
  type GetInboxRulesetsRequest,
  type GetInboxSentEmailsRequest,
  type GetInboxWebhooksPaginatedRequest,
  type GetJsonSchemaForWebhookEventEventEnum,
  type GetMonthlyUsageChartsRequest,
  type GetOrganizationUserLimitsRequest,
  type GetOrganizationUsersPaginatedSortEnum,
  type GetPhoneNumberRequest,
  type GetPhoneNumbersRequest,
  type GetPhoneNumberWebhooksPaginatedRequest,
  type GetPlanDisplaysDesiresAddonPlanEnum,
  type GetPlanDisplaysPlanCurrencyEnum,
  type GetPlanDisplaysSubscriptionScheduleEnum,
  type GetReplyForSmsMessageRequest,
  type GetScheduledJobRequest,
  type GetScheduledJobsByInboxIdRequest,
  type GetSentDeliveryStatusesBySentIdSortEnum,
  type GetSentDeliveryStatusesRequest,
  type GetSentEmailsRequest,
  type GetSentEmailTrackingPixelsRequest,
  type GetSmsMessagesPaginatedRequest,
  type GetStatisticsFractionFractionTypeEnum,
  type GetTestWebhookPayloadEventNameEnum,
  type GetThreadsPaginatedRequest,
  type GetUsageRecordSeriesRequest,
  type GetUsageRecordUpdatesSeriesRequest,
  type GetUserEventsRequest,
  type GetUserStripeWebhookEventsRequest,
  type GetValidationRequestsRequest,
  type GetWebhookResultsRequest,
  type GrantAdminAccessToUserIdRequest as GrantAdminAccessToUserIdUsingPOSTRequest,
  type GroupDto,
  type HasOnboardedFeaturesHasOnboardedFeaturesEnum,
  type InboxForwarderDto,
  type InboxReplierDto,
  type InboxRulesetDto,
  type InviteOrganizationUserOptions,
  type JSONSchemaDto,
  type ListInboxTrackingPixelsRequest,
  type MissedEmailDto as MissedEmail,
  type Organization,
  type OrganizationSamlSettingsDto,
  type OrganizationSettingsOptionalDto,
  type PageBouncedRecipients,
  type PageDeliveryStatus,
  type PageMissedEmailProjection,
  type PageOrganizationUser,
  PlanAddonsSupportedAddonsEnum,
  type PlanDisplay,
  PlanDisplayCurrencyEnum,
  PlanDisplayScheduleEnum,
  type PlanDisplaysCurrenciesEnum,
  type PlanDisplaysSchedulesEnum,
  type RedriveWebhookResultRequest,
  type ReplyToEmailRequest,
  type ReplyToSmsMessageRequest,
  type SaveHasOnboardedFeatureFeatureEnum,
  type SendEmailOptions,
  type SentEmailDto,
  type SignUpOptions,
  type SubmitUnblockDefaultSenderRequestRequest,
  type SubmitUnfreezeRequestAnonymousRequest,
  type SubmitUnfreezeRequestRequest,
  SubscriptionDtoSubscriptionTypeEnum,
  type TemplateDto,
  type TemplateProjection,
  type TestConnectorImapConnectionOptionsRequest,
  type TestConnectorSmtpConnectionOptionsRequest,
  type TestInboxRulesetReceivingRequest,
  type TestInboxRulesetSendingRequest,
  type TestNewInboxForwarderRequest,
  type UpdateConnectorImapConnectionRequest,
  type UpdateConnectorRequest,
  type UpdateConnectorSmtpConnectionRequest,
  type UpdateDomainRequest,
  type UpdateHasOnboardedOptions,
  type UpdateImapAccessRequest,
  type UpdateInboxForwarderRequest,
  type UpdateInboxOptions,
  type UpdateInboxReplierRequest,
  type UpdateOrganizationSamlSettingsOptions,
  type UpdateOrganizationSettingsOptions,
  type UpdateOrganizationUserOptions,
  type UpdateSmtpAccessRequest,
  type User,
  type UserDto,
  UserDtoUserTypeEnum,
  type VerifyEmailAddressRequest,
  type WebhookDto,
  type WebhookRedriveResult,
  type WebhookTestResult,
  ZapierGetNewAttachmentRecordsSortEnum,
  ZapierGetNewContactRecordsSortEnum,
  type ZapierGetNewEmailRecordsRequest,
  type CreateGuestPortalRequest,
  type GuestPortalDto,
  type PageGuestPortalUsers,
  type GuestPortalUserDto,
  type GuestPortalUserCreateDto,
  type GetGuestPortalUsersRequest,
  type GetGuestPortalUserRequest,
  type CreateGuestPortalUserRequest,
  type GetGuestPortalRequest,
  type GetAllGuestPortalUsersRequest,
  type GetGuestPortalUserByIdRequest,
  type PageEmailThreadProjection,
  type EmailThreadItemsDto,
  type EmailThreadDto,
  type PhonePlanAvailability,
  type GetEmailThreadsRequest,
  type GetEmailThreadRequest,
} from '@mailslurp/admin-sdk';
import type { ApiError } from '../lib/services/helpers';
import {
  getErrorCodeVariant,
  getErrorMessage,
  registerErrorLocally,

} from '../lib/services/helpers';
import {
  type StorageKey,
  StorageService,
} from '../lib/services/StorageService';
import type { AdminService } from '../lib/api/AdminService';
import type { ErrorPayload, IState, Page, Types } from './types';
import { Variant } from './types';
import { wrapAdmin, wrapAdminContext3 } from './utils';
import type { AppConfig } from '../lib/services/types';
import { useNuxtApp } from '#imports';
import { getLogger } from '../lib/services/getLogger';
import { tempAccessTokenWillOrHasExpired } from '../lib/services/time';

const log = getLogger('store3');
// migration:
// - actions remove first param state
// - remove getters
const storageService = new StorageService();

export const useStore3 = defineStore('store3', {
  state: (): IState => ({
    ready: false,
    phonePlansAvailability: null,
    emailThreadPagination: null,
    emailThreadItems: null,
    emailThread: null,
    productGuestPlans: null,
    portal: null,
    portals: null,
    guestsPagination: null,
    guest: null,
    guestCreated: null,
    guestPlans: null,

    inboxPlans: null,
    productInboxPlans: null,
    domainGroups: null,
    seriesUsage: null,
    seriesEventAggregates: null,
    seriesUsageRecordUpdates: null,
    checkEmailBodyResults: null,
    complaint: null,
    onboardingFeatures: null,
    userLimits: null,
    accounts: null,
    organizationUsersPaginated: null,
    organizationSettings: null,
    temporaryAccessKeyForAttachments: null,
    sessionExpired: null,
    temporaryAccessKeyForEmails: null,
    inboxForwarderEvent: null,
    inboxForwarderTestResult: null,
    testInboxRulesetReceivingResult: null,
    testInboxRulesetSendingResult: null,
    checkEmailBodyFeatureSupportResults: null,
    unfreezeUserResult: null,
    unblockDefaultSenderResult: null,
    thread: null,
    complaintPagination: null,
    deliveryStatus: null,
    deliveryStatusPagination: null,
    geo: null,
    apiAccessToken: null,
    requestedPlan: null,
    requestedLanguage: null,
    user: null,
    hasUserMadeAction: null,
    phoneNumbers: null,
    phoneNumbersPagination: null,
    phoneNumber: null,
    smsMessages: null,
    smsMessage: null,
    replyForSms: null,
    emergencyAddresses: null,
    allInboxRulesets: null,
    inboxInboxRulesets: null,
    inboxRuleset: null,
    allInboxForwarders: null,
    inboxInboxForwarders: null,
    inboxForwarderEvents: null,
    inboxForwarder: null,
    // tracking
    bouncedEmailPagination: null,
    bouncedRecipientPagination: null,
    bouncedEmail: null,
    bouncedRecipient: null,
    quote: null,
    quoteInvoice: null,
    quoteParams: null,
    allQuotes: null,
    openQuotes: null,
    otherAccounts: null,
    exportDownloadLink: null,
    alias: null,
    aliasPagination: null,
    threadPagination: null,
    validationRequestPagination: null,
    zapierConnection: null,
    zapierNewEmailRecords: null,
    zapierNewContactRecords: null,
    zapierNewAttachmentRecords: null,
    flash: null,
    allPlans: null,
    productEmailValidation: null,
    productAIPlans: null,
    productConnectorPlans: null,
    stats: null,
    checkoutSession: null,
    checkoutAppendSession: null,
    statsConstants: null,
    usageCharts: null,
    billingPortal: null,
    paymentFailures: null,
    invoices: null,
    subscription: null,
    account: null,
    validateEmailListResult: null,
    // sent
    sent: null,
    sentsAllPagination: null,
    sentsInboxPagination: null,
    describeDomainResult: null,
    verifyEmailAddressResult: null,
    inboxRepliers: null,
    inboxInboxRepliers: null,
    inboxReplierEvents: null,
    inboxReplier: null,
    // scheduled jobs
    scheduledJob: null,
    scheduledJobsPagination: null,
    // domains
    domain: null,
    domains: null,
    domainIssues: null,
    // attachments
    attachment: null,
    attachmentPagination: null,
    // metrics
    metricsNotifications: null,
    metricsReputation: null,
    metricsSubscription: null,
    // latest messages
    latestMessagesEmails: null,
    latestMessagesSms: null,
    // connectors
    connectorEvent: null,
    connectorImapConnection: null,
    connectorSmtpConnection: null,
    connectorSyncSettings: null,
    allConnectors: null,
    connector: null,
    inbox: null,
    email: null,
    emailValidation: null,
    emailRaw: null,
    expiredInbox: null,
    connectorEventsPagination: null,
    connectorImapConnectionTestResult: null,
    connectorSmtpConnectionTestResult: null,
    connectorProviderSetting: null,
    expiredInboxRecords: null,
    inboxes: null,
    inboxTags: null,
    emails: null,
    emailPagination: null,
    inboxPagination: null,
    inboxWebhooks: null,
    inboxEmailPagination: null,
    favInboxPagination: null,
    // for use with viewing an email
    attachments: null,
    unreadCount: 0,
    expirationDefaults: null,
    userEvents: null,
    stripeWebhookEvents: null,
    webhook: null,
    inboxWebhooksPagination: null,
    phoneWebhooksPagination: null,
    webhookResult: null,
    webhookResults: null,
    allWebhookResults: null,
    webhooks: null,
    webhooksPagination: null,
    group: null,
    groups: null,
    groupsPagination: null,
    groupContactsPagination: null,
    contact: null,
    contacts: null,
    contactsPagination: null,
    template: null,
    templates: null,
    templatesPagination: null,
    sidebarSmOpen: false,
    sidebarLgMinified: false,
    organizations: null,
    organizationSamlSettings: null,
    organizationSamlServiceProviderData: null,
    organization: null,
    organizationUsers: null,
    imapSmtpAccess: null,
    missedEmail: null,
    missedEmailPagination: null,
    notifications: null,
    notification: null,
    dnsLookupResults: null,
    trackingPixel: null,
    allTrackingPixels: null,
    sentEmailTrackingPixels: null,
    allSentEmailTrackingPixels: null,
    inboxTrackingPixels: null,
    offboardingAction: null,
    dashboardLoaded: false,
    organizationUserLimits: null,
    // upgrade stuff
    selectedCurrency: PlanDisplayCurrencyEnum.USD,
    selectedSchedule: PlanDisplayScheduleEnum.YEARLY,
    selectedCurrencySymbol: '$',
    // upgrade state
    selectedUserPlanQuantity: 0,
    selectedTrialEnabled: false,
    // display
    userAddons: null,
    productEmailPlans: null,
    productUserPlans: null,
    productPhonePlans: null,
    productPhoneNumbers: null,
    // user objects
    plans: null,
    userPlans: null,
    emailPlans: null,
    phonePlans: null,
    connectorPlans: null,
    aiPlans: null,
    validationPlan: null,
  }),

  getters: {
    regressionTest: () => {
      const is = storageService.tryGet('REGRESSION_TEST');
      return is === '1';
    },

    hasUnreadNotifications: (state: IState) => {
      return state.notifications?.some((n) => !n.seen) ?? false;
    },
    unreadNotificationsCount: (state: IState) => {
      return state.notifications?.filter((n) => !n.seen).length ?? 0;
    },
    isEnterprise: (state: IState) => {
      return !!(
        state.subscription &&
        state.subscription.subscriptionType ===
          SubscriptionDtoSubscriptionTypeEnum.ENTERPRISE
      );
    },
    isVendor: (state: IState) => {
      return !!(state.user && state.user.vendorEmailAddress);
    },
    isChild: (state: IState) => {
      if (state.user && state.user.userType) {
        return (
          state.user.userType === UserDtoUserTypeEnum.CHILD_SOLO ||
          state.user.userType === UserDtoUserTypeEnum.CHILD_TEAM
        );
      } else {
        return false;
      }
    },
    planDisplayForSubscription(state: IState) {
      const x: PlanDisplay | null = state.subscription
        ? (state.allPlans?.plans ?? []).find(
            (it: PlanDisplay) =>
              it.subscriptionType?.toString() ===
              state.subscription?.subscriptionType?.toString(),
          ) ?? null
        : null;
      return x;
    },
    subscriptionSupportsUserPlans(): boolean {
      return this.planDisplayForSubscription?.features.userPlans ?? false;
    },
    subscriptionSupportsConnectorPlans(): boolean {
      return (
        this.planDisplayForSubscription?.addons.supportedAddons?.includes(
          PlanAddonsSupportedAddonsEnum.CONNECTORS,
        ) ?? false
      );
    },
    subscriptionSupportsAIPlans(): boolean {
      return (
        this.planDisplayForSubscription?.addons.supportedAddons?.includes(
          PlanAddonsSupportedAddonsEnum.AI,
        ) ?? false
      );
    },
    subscriptionSupportsInboxPlans(): boolean {
      return (
        this.planDisplayForSubscription?.addons.supportedAddons?.includes(
          PlanAddonsSupportedAddonsEnum.INBOXES,
        ) ?? false
      );
    },
    subscriptionSupportsEmailPlans(): boolean {
      return (
        this.planDisplayForSubscription?.addons.supportedAddons?.includes(
          PlanAddonsSupportedAddonsEnum.EMAILS,
        ) ?? false
      );
    },
    subscriptionSupportsVerificationPlans(): boolean {
      return (
        this.planDisplayForSubscription?.addons.supportedAddons?.includes(
          PlanAddonsSupportedAddonsEnum.VALIDATION,
        ) ?? false
      );
    },
    subscriptionSupportsPhonePlans(): boolean {
      return this.planDisplayForSubscription?.features.phonePlans ?? false;
    },
  },
  actions: {
    /**
     * Portals
     */
    async createPortal(payload: CreateGuestPortalRequest) {
      this.portal = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.createPortal(payload),
        useRuntimeConfig().public as AppConfig,
      );
      return this.portal;
    },
    async getPortal(payload: GetGuestPortalRequest) {
      this.portal = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getPortal(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getPortals() {
      this.portals = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getPortals(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getGuests(payload: GetAllGuestPortalUsersRequest) {
      this.guestsPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getGuests(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getGuest(payload: GetGuestPortalUserByIdRequest) {
      this.guest = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getGuest(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createGuest(payload: CreateGuestPortalUserRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.createGuest(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.guestCreated = res;
      this.guest = res.guest;
      return res.guest;
    },
    /**
     * Need to also call getAddon methods
     */
    async getAllSubscriptionData(
      opts: {
        userPlanQuantity?: number;
        subscriptionSchedule?: GetPlanDisplaysSubscriptionScheduleEnum;
        planCurrency?: GetPlanDisplaysPlanCurrencyEnum;
        desiresAddonPlan?: GetPlanDisplaysDesiresAddonPlanEnum;
        desiresUserPlan?: boolean;
        desiresPhonePlan?: boolean;
      } = {},
    ) {
      await Promise.all(
        [
          this.getPlans({
            subscriptionSchedule: opts.subscriptionSchedule,
            planCurrency: opts.planCurrency,
            desiresAddonPlan: opts.desiresAddonPlan,
            desiresUserPlan: opts.desiresUserPlan,
            desiresPhonePlan: opts.desiresPhonePlan,
          }),
          this.getSubscription(),
        ].filter((it) => it),
      );
      this.selectedUserPlanQuantity = opts.userPlanQuantity ?? 0;
      if (this.selectedTrialEnabled && this.subscription) {
        this.selectedTrialEnabled = false;
      }
      if (!this.subscription && this.allPlans) {
        this.selectedSchedule = this.allPlans?.defaultSchedule;
        this.selectedCurrency = this.allPlans?.defaultCurrency;
      }
      this.updateSelectedProducts();
    },
    updateSelectedProducts() {
      // plans that match currency and schedule
      this.plans =
        this.allPlans?.plans
          .filter((plan) => plan.cost > 0)
          .filter((plan) => {
            return (
              plan.currency.toString() === this.selectedCurrency.toString() &&
              plan.schedule.toString() === this.selectedSchedule.toString()
            );
          }) ?? null;
      // currency symbol
      this.selectedCurrencySymbol =
        this.allPlans?.symbols?.[this.selectedCurrency] ?? '';
      // user has no choice over which plans are include, we just calculate based on selection
      log(
        `Updating selected plans: currency=${this.selectedCurrency} schedule=${this.selectedSchedule} trial=${this.selectedTrialEnabled} quantity=${this.selectedUserPlanQuantity}`,
      );
    },
    // subscription stuff
    setSelectedCurrency(
      currency: PlanDisplayCurrencyEnum | PlanDisplaysCurrenciesEnum,
    ) {
      this.selectedCurrency = currency;
      this.updateSelectedProducts();
    },
    setSelectedSchedule(
      schedule: PlanDisplayScheduleEnum | PlanDisplaysSchedulesEnum,
    ) {
      this.selectedSchedule = schedule;
      this.updateSelectedProducts();
    },
    //
    async getOrganizationUserLimits(payload: GetOrganizationUserLimitsRequest) {
      const res = await wrapAdmin<CanResult>(
        this.apiAccessToken?.key,
        (admin) => admin.getOrganizationUserLimits(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationUserLimits = res;
      return res;
    },

    // unblock requests
    async submitUnblockDefaultSenderRequest(
      payload: SubmitUnblockDefaultSenderRequestRequest,
    ) {
      this.unblockDefaultSenderResult = await wrapAdmin(
        this.apiAccessToken?.key,
        (admin) => admin.submitUnblockDefaultSenderRequest(payload),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async submitUnfreezeRequestRequest(payload: SubmitUnfreezeRequestRequest) {
      this.unfreezeUserResult = await wrapAdmin(
        this.apiAccessToken?.key,
        (admin) => admin.submitUnfreezeRequestRequest(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async submitUnfreezeRequestRequestAnon(
      payload: SubmitUnfreezeRequestAnonymousRequest,
    ) {
      this.unfreezeUserResult = await wrapAdmin(
        this.apiAccessToken?.key,
        (admin) => admin.submitUnfreezeRequestRequestAnony(payload),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },

    // auth
    async logout() {
      try {
        await wrapAdmin(
          this.apiAccessToken?.key,
          (admin) => admin.logout(),
          useRuntimeConfig().public as AppConfig,
        );
      } catch (e) {
        log('Error logging out in store');
      }
      this.reset();
    },
    getIsSamlRedirectAndClear() {
      return storageService.tryGetAndClear('isSamlRedirect');
    },
    getRequestedLanguageAndClear() {
      return storageService.tryGetAndClear('requestedLanguage');
    },
    getRequestedPlanAndClear() {
      return storageService.tryGetAndClear('requestedPlan');
    },
    setRequestedLanguage(r: string) {
      storageService.trySet('requestedLanguage', r);
    },
    setRequestedPlan(r: string) {
      storageService.trySet('requestedPlan', r);
    },
    setIsSamlRedirect(r: string) {
      storageService.trySet('isSamlRedirect', r);
    },
    reset() {
      log('reset store');
      const _oldFlash = this.flash;
      this.$reset();
      this.flash = _oldFlash;
      this.user = null;
      this.apiAccessToken = null;
      this.account = null;
      log('clear local storage');
      for (const k of [
        'requestedPlan',
        'apiKeyCache',
        'userFeedbackTime',
        'requestedLanguage',
        'isSamlRedirect',
      ] as StorageKey[]) {
        log('Clear local storage ' + k);
        storageService.tryClear(k);
      }
      this.ready = true;
    },
    async checkEmailBody(emailId: string) {
      this.checkEmailBodyResults = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.checkEmailBody(emailId);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async checkEmailBodyFeatureSupport(emailId: string) {
      this.checkEmailBodyFeatureSupportResults = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.checkEmailBodyFeatureSupport(emailId);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async forgotLink(payload: {
      emailAddress: string;
      preventMultipleUsers?: boolean;
    }): Promise<void> {
      await wrapAdmin(
        null,
        (admin) =>
          admin.forgotLink(payload.emailAddress, payload.preventMultipleUsers),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async loginLink(payload: {
      emailAddress: string;
      preventMultipleUsers?: boolean;
    }): Promise<void> {
      await wrapAdmin(
        null,
        (admin) =>
          admin.loginLink(payload.emailAddress, payload.preventMultipleUsers),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async loginPassword(payload: {
      emailAddress: string;
      password: string;
    }): Promise<UserDto> {
      return wrapAdmin(
        null,
        (admin) => admin.loginPassword(payload.emailAddress, payload.password),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async signUp(
      signUpOptions: SignUpOptions,
      requestedPlanId: string | undefined,
    ): Promise<UserDto> {
      return await wrapAdmin(
        null,
        (admin: AdminService) =>
          admin.signUp({
            signUpOptions: signUpOptions,
            redirectDevUrl: (useRuntimeConfig()?.public as AppConfig)
              ?.redirectDevUrl,
            requestedPlanId: requestedPlanId,
          }),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async signIn(payload: {
      emailAddress: string;
      requestedPlanId: string | undefined;
      preventMultipleUsers?: boolean;
      isSignUp?: boolean;
    }): Promise<void> {
      return wrapAdmin(
        null,
        (admin: AdminService) =>
          admin.sendAccessLinkToSoloUser(
            payload.emailAddress,
            (useRuntimeConfig()?.public as AppConfig)?.redirectDevUrl,
            payload.requestedPlanId,
            payload.preventMultipleUsers,
            payload.isSignUp,
          ),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async verifyOrganizationAccess(payload: {
      organizationSlug: string;
      challengeCode: string;
    }): Promise<UserDto> {
      log('Verify organization access');
      const user = await wrapAdmin(
        null,
        (admin) =>
          admin.verifyOrganizationUser(
            payload.organizationSlug,
            payload.challengeCode,
          ),
        useRuntimeConfig().public as AppConfig,
        true,
      );
      await this.setUserApiKey(user);
      return user;
    },
    async verifySsoToken(payload: { token: string }): Promise<UserDto> {
      log('Verify SSO token');
      const user = await wrapAdmin(
        null,
        (admin) => admin.verifySsoToken(payload.token),
        useRuntimeConfig().public as AppConfig,
        true,
      );
      await this.setUserApiKey(user);
      return user;
    },
    async verifyAccess(payload: {
      challengeCode: string;
      captchaToken: string | undefined;
    }): Promise<UserDto> {
      log('Verify access');
      const user = await wrapAdmin(
        null,
        (admin) =>
          admin.verifySoloUser(payload.challengeCode, payload.captchaToken),
        useRuntimeConfig().public as AppConfig,
        true,
      );
      log('User found: ' + user?.id);
      await this.setUserApiKey(user);
      return user;
    },
    // emails
    async forwardEmail(payload: ForwardEmailRequest) {
      this.sent = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.forwardEmail(payload.emailId, payload.forwardEmailOptions),
        useRuntimeConfig().public as AppConfig,
      );
    },
    // messages
    setFlash(payload: Types | null) {
      log('Set flash ' + payload);
      this.flash = payload;
    },
    setError(payload: ErrorPayload) {
      registerErrorLocally(payload.error ?? payload.text);
      const err = payload.error as ApiError;
      log('SET_ERROR ' + err);
      const variant = err?.apiResult
        ? getErrorCodeVariant(err.apiResult)
        : Variant.danger;
      log('Setting error ' + payload);
      const text = err?.apiResult
        ? getErrorMessage(err)
        : err.message ?? JSON.stringify(err);
      if (variant === 'danger') {
        try {
          log('SEND SENTRY error => ' + err);
          const u = this.user;
          const { $sentryCaptureException } = useNuxtApp();
          $sentryCaptureException(err ?? text, {
            tags: {
              errorCode: err?.apiResult?.errorCode ?? '-',
              caseNumber: err?.apiResult?.caseNumber ?? '-',
              url: err?.apiResult?.url ?? '-',
              status: err?.apiResult?.status ?? '-',
            },
            user: {
              email: u?.emailAddress ?? '-',
              username: u?.emailAddress ?? '-',
              id: u?.id ?? '-',
            },
          });
        } catch (e) {
          log('Ignore error no sentry');
          console.error(e);
        }
      } else {
        log('No sentry or danger in alert');
      }
      this.setFlash({
        variant,
        text,
        errorCodeVariant: err?.apiResult?.errorCode ?? undefined,
        error: err,
      });
      if (
        err?.apiResult?.errorCode ===
        ErrorCodesDtoAppErrorCodesEnum.W_401_UNAUTHORIZED
      ) {
        this.sessionExpired = true;
      }
    },
    async grantAdminAccess(
      payload: GrantAdminAccessToUserIdUsingPOSTRequest,
    ): Promise<EmptyResponseDto> {
      return wrapAdmin(
        null,
        (admin) => admin.grantAdminAccess(payload),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async getPaymentFailures(): Promise<void> {
      this.paymentFailures = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getPaymentFailures(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getOpenQuotes(): Promise<void> {
      this.openQuotes = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOpenQuotes(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getQuotes(): Promise<void> {
      this.allQuotes = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAllQuotes(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getInvoices(): Promise<void> {
      this.invoices = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getInvoices(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getBillingPortal(): Promise<void> {
      this.billingPortal = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getBillingPortal(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getExpirationDefaults(): Promise<void> {
      this.expirationDefaults = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getExpirationDefaults(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductEmailValidation(): Promise<void> {
      this.productEmailValidation = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          const countryCode = this?.geo?.country ?? undefined;
          return admin.getProductEmailValidation(countryCode);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    setSidebarLgMinimized(min: boolean) {
      this.sidebarLgMinified = min;
    },
    async getPlans(opts: {
      subscriptionSchedule?: GetPlanDisplaysSubscriptionScheduleEnum;
      planCurrency?: GetPlanDisplaysPlanCurrencyEnum;
      desiresAddonPlan?: GetPlanDisplaysDesiresAddonPlanEnum;
      desiresUserPlan?: boolean;
      desiresPhonePlan?: boolean;
    }): Promise<void> {
      this.allPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          const countryCode = this?.geo?.country ?? undefined;
          return admin.getPlanDisplays({
            countryCode,
            ...opts,
          });
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async addNewContacts(payload: { value: boolean }): Promise<void> {
      this.user = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.updateAddNewContacts(payload.value),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async changeEmailAddress(payload: { newEmailAddress: string }) {
      const user = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.updateAccountEmail(payload.newEmailAddress),
        useRuntimeConfig().public as AppConfig,
      );
      this.setFlash({
        text: 'User email address updated',
        variant: Variant.success,
      });
      this.user = user;
    },
    async waitForSubscriptionRefresh(payload: { timeout: number }) {
      const subscription = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.waitForRefreshSessionUpgrade(payload.timeout),
        useRuntimeConfig().public as AppConfig,
      );
      this.subscription = subscription.subscription ?? null;
    },
    async validateEmailList(payload: string[]) {
      this.validateEmailListResult = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.validateEmailList(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getSubscription() {
      const account = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAccount(),
        useRuntimeConfig().public as AppConfig,
      );
      this.sessionExpired = false;
      this.subscription = account.subscription ?? null;
      this.account = account ?? null;
      this.userAddons = account.addons ?? null;
      this.user = account.user ?? null;
      if (this.subscription?.subscriptionSchedule) {
        this.selectedSchedule = this.subscription.subscriptionSchedule;
      }
      if (this.subscription?.currency) {
        this.selectedCurrency = this.subscription.currency;
      }
    },
    async getStatsConstants() {
      this.statsConstants = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getStatsConstants(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getUsageCharts(payload: GetMonthlyUsageChartsRequest) {
      this.usageCharts = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getUsageCharts(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getStats() {
      try {
        this.stats = await wrapAdminContext3(
          this.apiAccessToken?.key,
          (admin) => admin.getStats(),
          useRuntimeConfig().public as AppConfig,
        );
      } catch (e) {
        // throws often should deprecate
      }
    },
    async getStatisticsFraction(payload: {
      fractionType: GetStatisticsFractionFractionTypeEnum;
    }): Promise<FractionLong> {
      // note this doesn't use getters/store because only called inside <Fraction/> component
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getStatsFraction(payload.fractionType),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteProductValidation() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteProductValidation(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async addProductPhoneNumber(payload: AddPhoneNumberOptions) {
      this.checkoutAppendSession = null;
      this.checkoutAppendSession = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createAppendProductNumberSession(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async addProductAddonPlan(payload: AppendProductAddonPlanRequest) {
      this.checkoutAppendSession = null;
      this.checkoutAppendSession = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createAppendProductAddonPlanSession(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async addProductUserPlan(payload: AddUserPlanOptions) {
      this.checkoutAppendSession = null;
      this.checkoutAppendSession = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createAppendProductUserPlanSession(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async addProductPhonePlan(payload: AddPhonePlanOptions) {
      this.checkoutAppendSession = null;
      this.checkoutAppendSession = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createAppendProductPhonePlanSession(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getQuoteInvoice(id: string) {
      this.quoteInvoice = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getQuoteInvoice(id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async cancelQuote(id: string) {
      this.quote = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.cancelQuote(id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async acceptQuote(id: string) {
      this.quote = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.acceptQuote(id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getQuote(id: string) {
      this.quote = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getQuote(id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getQuoteParams() {
      this.quoteParams = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getQuoteParams(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getUserLimits() {
      this.userLimits = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getUserLimits(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAllQuotes() {
      this.allQuotes = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAllQuotes(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createCheckoutSessionAddMissingExtras(
      payload: CreateUpgradeSessionAddMissingExtras,
    ) {
      this.checkoutSession = null;
      this.checkoutSession = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createCheckoutSessionAddMissingExtras(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createCheckoutSession(payload: CreateUpgradeSessionWithExtras) {
      this.checkoutSession = null;
      this.checkoutSession = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createCheckoutSession(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteCustomer(payload: CreateSubscriptionEndedOptions) {
      log(`Delete customer (Reason: ${payload.reason})`);
      // delete subscription
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteCustomer(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.subscription = null;
      await this.getSubscription();
    },
    async submitUserFeedback(payload: CreateUserFeedbackSubmissionOptions) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.submitUserFeedback(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteSubscription(payload: CreateSubscriptionEndedOptions) {
      log(`Delete subscription (Reason: ${payload.reason})`);
      // delete subscription
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteSubscription(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.subscription = null;
      await this.getSubscription();
    },
    async deleteUser(payload: CreateSubscriptionEndedOptions) {
      log(`Delete user (Reason: ${payload.reason})`);
      await this.deleteSubscription(payload);
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteUser(),
        useRuntimeConfig().public as AppConfig,
      );
      this.setFlash({
        variant: Variant.success,
        text:
          'Your account and subscription have been closed. Thanks for trying MailSlurp. ' +
          'We hope to see you again. Please email us if there is any way we could improve: contact@mailslurp.dev',
      });
      await this.logout();
    },
    /**
     * Webhooks
     */
    async deleteWebhookForPhone(payload: {
      inboxId: string | null;
      webhookId: string;
    }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.deleteWebhook(payload.inboxId, payload.webhookId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteWebhook(payload: {
      inboxId: string | null;
      webhookId: string;
    }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.deleteWebhook(payload.inboxId, payload.webhookId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getWebhookJsonSchemaForEvent(payload: {
      event: GetJsonSchemaForWebhookEventEventEnum;
    }): Promise<JSONSchemaDto> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getWebhookJsonSchemaForEvent(payload.event),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getWebhookJsonSchema(payload: {
      webhookId: string;
    }): Promise<JSONSchemaDto> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getWebhookJsonSchema(payload.webhookId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getWebhookPayloadForEvent(payload: {
      event: GetTestWebhookPayloadEventNameEnum;
    }): Promise<AbstractWebhookPayload> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getWebhookPayloadForEvent(payload.event),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getWebhookPayload(payload: {
      webhookId: string;
    }): Promise<AbstractWebhookPayload> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getWebhookPayload(payload.webhookId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async testWebhook(payload: {
      webhookId: string;
    }): Promise<WebhookTestResult> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.testWebhook(payload.webhookId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async redriveWebhookResult(
      payload: RedriveWebhookResultRequest,
    ): Promise<WebhookRedriveResult> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.redriveWebhookResult(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAllWebhookResults(payload: GetAllWebhookResultsRequest) {
      this.allWebhookResults = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllWebhookResults(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async redriveAllFailedWebhooks() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.redriveAllFailedWebhooks(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getWebhookResults(payload: GetWebhookResultsRequest) {
      this.webhookResults = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getWebhookResults(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getWebhookResult(payload: { id: string }) {
      this.webhookResult = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getWebhookResult(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
    },

    async getWebhook(payload: { webhookId: string }) {
      this.webhook = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getWebhook(payload.webhookId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getWebhooksPagination(payload: GetAllWebhooksRequest) {
      this.webhooksPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllWebhooks(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createWebhook(payload: {
      inboxId: string | null;
      webhookOptions: CreateWebhookOptions;
      phoneId: string | null;
    }): Promise<WebhookDto> {
      const response: WebhookDto = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) =>
          m.createWebhook(
            payload.inboxId,
            payload.webhookOptions,
            payload.phoneId,
          ),
        useRuntimeConfig().public as AppConfig,
      );
      this.webhook = response;
      return response;
    },
    /**
     * Attachments
     */
    async getAttachment(payload: { attachmentId: string }) {
      this.attachment = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m: AdminService) => m.getAttachment(payload.attachmentId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteAllAttachments() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m: AdminService) => m.deleteAllAttachments(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteAttachment(payload: { attachmentId: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m: AdminService) => m.deleteAttachment(payload.attachmentId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAttachmentsPagination(payload: GetAttachmentsRequest) {
      const attachmentsPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllAttachments(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.attachmentPagination = attachmentsPagination;
    },
    /**
     * Templates
     */
    async deleteTemplate(payload: { id: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteTemplate(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getTemplate(payload: { id: string }) {
      this.template = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getTemplate(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getTemplates() {
      const templates: TemplateProjection[] = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getTemplates(),
        useRuntimeConfig().public as AppConfig,
      );
      this.templates = templates;
    },
    async getTemplatesPagination(payload: GetAllTemplatesRequest) {
      const templatesPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllTemplates(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.templatesPagination = templatesPagination;
    },
    async createTemplate(payload: CreateTemplateOptions): Promise<TemplateDto> {
      const template = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.createTemplate(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.template = template;
      return template;
    },
    /** Email validation */
    async getEmailValidationRequestPagination(
      payload: GetValidationRequestsRequest,
    ) {
      const validationRequestPage = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getEmailValidationRequestPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.validationRequestPagination = validationRequestPage;
    },
    /**
     * Contacts
     */
    async deleteContact(payload: { id: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteContact(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getContact(payload: { id: string }) {
      const contact = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getContact(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
      this.contact = contact;
    },
    async getContactsPagination(payload: GetAllContactsRequest) {
      this.contactsPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllContacts(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createContact(payload: CreateContactOptions): Promise<ContactDto> {
      const contact = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.createContact(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.contact = contact;
      return contact;
    },
    /**
     * Groups
     */
    async deleteGroup(payload: { id: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteGroup(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getGroup(payload: { id: string }) {
      this.group = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getGroup(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getGroupsPagination(payload: GetAllGroupsRequest) {
      this.groupsPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllGroups(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getGroupContactsPagination(
      payload: GetGroupWithContactsPaginatedRequest,
    ) {
      this.groupContactsPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getGroupContacts(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createGroup(payload: CreateGroupOptions): Promise<GroupDto> {
      const group = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.createGroup(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.group = group;
      return group;
    },
    /*
     * Expired
     */
    async getExpiredInbox(payload: GetExpiredInboxRecordRequest) {
      this.expiredInbox = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getExpiredInbox(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getExpiredInboxRecords(payload: GetExpiredInboxesRequest) {
      const expiredInboxRecords = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getExpiredInboxRecords(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.expiredInboxRecords = expiredInboxRecords;
    },
    async getAllConnectorEventsPagination(
      payload: GetAllConnectorEventsRequest,
    ) {
      this.connectorEventsPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAllConnectorEventsPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getConnectorProviderSettings() {
      this.connectorProviderSetting = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getConnectorProviderSettings(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getConnectorEventsPagination(payload: GetConnectorEventsRequest) {
      this.connectorEventsPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getConnectorEventsPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async testConnectorImapConnection(
      payload: TestConnectorImapConnectionOptionsRequest,
    ) {
      this.connectorImapConnectionTestResult = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.testConnectorImapConnection(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async testConnectorSmtpConnection(
      payload: TestConnectorSmtpConnectionOptionsRequest,
    ) {
      this.connectorSmtpConnectionTestResult = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.testConnectorSmtpConnection(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getPhonePlansAvailability() {
      this.phonePlansAvailability = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getPhonePlansAvailability(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async refreshUserAndSubscription() {
      const account = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAccount(),
        useRuntimeConfig().public as AppConfig,
      );
      this.sessionExpired = false;
      this.user = account.user;
      this.userAddons = account.addons ?? null;
      this.subscription = account.subscription ?? null;
      this.account = account ?? null;
      if (this.selectedTrialEnabled && account.subscription) {
        this.selectedTrialEnabled = false;
      }
    },
    /**
     * User events
     */
    async getUserEvents(payload: GetUserEventsRequest) {
      const userEvents = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getUserEvents(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.userEvents = userEvents;
    },
    async getStripeWebhookEvents(payload: GetUserStripeWebhookEventsRequest) {
      const stripeWebhookEvents = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getStripeWebhookEvents(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.stripeWebhookEvents = stripeWebhookEvents;
    },
    /*
     * Inboxes
     */
    async getInboxes(includeInboxId?: string) {
      const inboxes = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getInboxes(includeInboxId),
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxes = inboxes;
    },
    async getInboxTags(): Promise<Array<string>> {
      const tags = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getInboxTags(),
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxTags = tags;
      return tags;
    },
    async createInbox(payload: CreateInboxRequest) {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.createInbox(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createInboxWithDefaults() {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.createInboxWithDefaults(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async sendInboxTestEmail(payload: { inboxId: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.sendInboxTestEmail(payload.inboxId),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async toggleFavouriteInbox(payload: {
      id: string;
      previousState: boolean;
    }) {
      const inboxId = payload.id;
      const newFavouriteState = !payload.previousState;
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.setInboxFavourite(inboxId, newFavouriteState),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getInboxPagination(payload: GetAllInboxesOffsetPaginatedRequest) {
      this.inboxPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllInboxes(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getFavInboxPagination(payload: GetAllInboxesOffsetPaginatedRequest) {
      this.favInboxPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) =>
          m.getAllInboxes({
            ...payload,
            favourite: true,
          }),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async updateInbox(payload: { id: string; options: UpdateInboxOptions }) {
      this.inbox = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.updateInbox(payload.id, payload.options),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getInbox(id: string) {
      this.inbox = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getInbox(id),
        useRuntimeConfig().public as AppConfig,
      );
      return this.inbox;
    },
    async emptyInbox(id: string) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.emptyInbox(id),
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxEmailPagination = null;
    },
    async deleteInbox(id: string) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteInbox(id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteAllInboxes() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteAllInboxes(),
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxes = null;
      this.inboxPagination = null;
    },
    async deleteAllEmails() {
      log('Delete all emails');
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteAllEmails(),
        useRuntimeConfig().public as AppConfig,
      );
      this.emailPagination = null;
    },
    async deleteAllWebhooks() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteAllWebhooks(),
        useRuntimeConfig().public as AppConfig,
      );
      this.webhooks = null;
      this.webhooksPagination = null;
    },
    async getPhoneWebhooksPagination(
      payload: GetPhoneNumberWebhooksPaginatedRequest,
    ) {
      this.phoneWebhooksPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getPhoneWebhooksPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getInboxWebhooksPagination(
      payload: GetInboxWebhooksPaginatedRequest,
    ) {
      this.inboxWebhooksPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getInboxWebhooksPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getInboxWebhooks(id: string) {
      this.inboxWebhooks = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getInboxWebhooks(id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Plans
     */
    async deleteConnectorPlans() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteConnectorPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorPlans = null;
    },
    async deleteGuestPlans() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteGuestPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.guestPlans = null;
    },
    async deleteAIPlans() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAIPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.aiPlans = null;
    },
    async deleteInboxPlans() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteInboxPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxPlans = null;
    },

    async deleteEmailPlans() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteEmailPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.emailPlans = null;
    },
    async getGuestPlans() {
      this.guestPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getGuestPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductGuestPlans() {
      this.productGuestPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getProductGuestPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAIPlans() {
      this.aiPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAIPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getConnectorPlans() {
      this.connectorPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getConnectorPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getInboxPlans() {
      this.inboxPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getEmailPlans() {
      this.emailPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getEmailPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getUserPlans() {
      this.userPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getUserPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductInboxPlans() {
      this.productInboxPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getProductInboxPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getUserAddons() {
      this.userAddons = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin
            .getUserAddons()
            .then((it) => it.addons as unknown as AccountDtoAddonsEnum[]);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductEmailPlans() {
      this.productEmailPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getProductEmailPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductAIPlans() {
      this.productAIPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getProductAIPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductConnectorPlans() {
      this.productConnectorPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getProductConnectorPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductUserPlans() {
      this.productUserPlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getProductUserPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Phones
     */
    async getProductPhonePlans() {
      this.productPhonePlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          const countryCode = this?.geo?.country ?? undefined;
          return admin.getProductPhonePlan(countryCode);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getProductPhoneNumbers() {
      this.productPhoneNumbers = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          const countryCode = this?.geo?.country ?? undefined;
          return admin.getProductPhoneNumber(countryCode);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Phone
     *
     */
    async deleteUserPlans() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteUserPlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.userPlans = null;
    },
    async getValidationPlans() {
      this.validationPlan = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getValidationPlans();
        },

        useRuntimeConfig().public as AppConfig,
      );
    },
    async getPhonePlans() {
      this.phonePlans = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getPhonePlans();
        },

        useRuntimeConfig().public as AppConfig,
      );
    },
    async deletePhonePlans() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deletePhonePlans();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteAllSms(payload: { phoneNumberId?: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllSms(payload.phoneNumberId);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async sendTestSms(payload: { phoneNumberId: string; message: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.sendTestSms(payload.phoneNumberId, payload.message);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getReplyForSms(payload: GetReplyForSmsMessageRequest) {
      this.replyForSms = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getReplyForSms(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async replyToSms(payload: ReplyToSmsMessageRequest) {
      const reply = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.replyToSms(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.replyForSms = { reply };
    },
    async deletePhoneNumber(payload: DeletePhoneNumberRequest) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deletePhoneNumber(payload.phoneNumberId);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getPhoneNumber(payload: GetPhoneNumberRequest) {
      this.phoneNumber = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getPhoneNumber(payload);
        },

        useRuntimeConfig().public as AppConfig,
      );
    },
    async getPhoneNumbers(payload: GetPhoneNumbersRequest) {
      this.phoneNumbers = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getPhoneNumbers(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getPhoneNumbersPaginated(payload: GetPhoneNumbersRequest) {
      this.phoneNumbersPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getPhoneNumbers(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Emergency address
     */
    async getEmergencyAddresses() {
      this.emergencyAddresses = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getEmergencyAddresses();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createEmergencyAddress(payload: CreateEmergencyAddressRequest) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createEmergencyAddress(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * SMS
     */
    async getSmsMessages(payload: GetSmsMessagesPaginatedRequest) {
      this.smsMessages = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getSmsMessages(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getSmsMessage(payload: { smsId: string }) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getSmsMessage(payload.smsId);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.smsMessage = res;
    },
    async deleteSmsMessage(payload: { smsId: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteSmsMessage(payload.smsId);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },

    /*
     * Emails
     */
    async getInboxEmailPagination(payload: GetEmailsOffsetPaginatedRequest) {
      const emails = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAllEmails(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxEmailPagination = emails;
    },
    async markAsUnread(id: string) {
      log('Mark as unread id ' + id);
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.markAsRead(id, false),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteEmail(payload: { id: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteEmail(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
    },

    async getEmailThreadItems(payload: GetEmailThreadRequest) {
      this.emailThreadItems = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getEmailThreadItems(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },

    async getEmailThread(payload: GetEmailThreadRequest) {
      this.emailThread = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getEmailThread(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getEmailThreads(payload: GetEmailThreadsRequest) {
      this.emailThreadPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getEmailThreadPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },

    async getEmailPagination(payload: GetEmailsOffsetPaginatedRequest) {
      const emailPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getAllEmails(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.emailPagination = emailPagination;
      return emailPagination;
    },

    async getEmail(id: string) {
      const email = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getEmail(id),
        useRuntimeConfig().public as AppConfig,
      );
      this.email = email;
    },

    async getEmailValidation(id: string) {
      const validation = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getEmailValidation(id),
        useRuntimeConfig().public as AppConfig,
      );
      this.emailValidation = validation;
    },

    async refreshApiKey() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.refreshApiKey(),
        useRuntimeConfig().public as AppConfig,
      );
      this.setFlash({
        variant: Variant.success,
        text: 'Your API Key has been reset.',
      });
    },

    async getAttachments(id: string) {
      const attachments = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAttachments(id),
        useRuntimeConfig().public as AppConfig,
      );
      this.attachments = attachments;
    },
    async replyToEmail(payload: ReplyToEmailRequest) {
      const sentEmail = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.replyToEmail(payload.emailId, payload.replyToEmailOptions),
        useRuntimeConfig().public as AppConfig,
      );
      this.sent = sentEmail;
    },

    async getUnreadEmailCount() {
      const count = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getUnreadEmailCount(),
        useRuntimeConfig().public as AppConfig,
      );
      this.unreadCount = count?.count ?? 0;
    },

    /**
     * Schedules
     */
    async cancelScheduledJob(payload: CancelScheduledJobRequest) {
      const job = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.cancelScheduledJob(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.scheduledJob = job;
    },
    async getScheduledJob(payload: GetScheduledJobRequest) {
      const job = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getScheduledJob(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.scheduledJob = job;
    },
    async getScheduledJobsPaginationForInbox(
      payload: GetScheduledJobsByInboxIdRequest,
    ) {
      const jobs = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getScheduledJobsForInbox(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.scheduledJobsPagination = jobs;
    },
    async getScheduledJobsPagination(payload: GetAllScheduledJobsRequest) {
      const jobs = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getScheduledJobs(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.scheduledJobsPagination = jobs;
    },
    async sendEmailWithSchedule(
      payload: SendEmailOptions & { inboxId: string; timestamp: Date },
    ) {
      log('Send email with schedule ' + payload);
      // send the email
      const { inboxId, timestamp, ...sendEmailOptions } = payload;
      return await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.sendEmailWithSchedule(inboxId, sendEmailOptions, timestamp),
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Queue
     */
    async sendEmailWithQueue(payload: SendEmailOptions & { inboxId: string }) {
      log('Send email with queue ' + payload);
      // send the email
      const { inboxId, ...sendEmailOptions } = payload;
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.sendEmailWithQueue(inboxId, sendEmailOptions),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async sendEmail(
      payload: SendEmailOptions & { inboxId: string },
    ): Promise<SentEmailDto> {
      log('Send email ' + payload);
      // send the email
      const { inboxId, ...sendEmailOptions } = payload;
      const sentEmail = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.sendEmail(inboxId, sendEmailOptions),
        useRuntimeConfig().public as AppConfig,
      );
      // set the sent email
      this.sent = sentEmail;
      return sentEmail;
    },
    /*
     * Domains
     */
    async getDomainGroups() {
      const domains = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getDomainGroups({}),
        useRuntimeConfig().public as AppConfig,
      );
      this.domainGroups = domains;
    },
    async getDomains(): Promise<DomainPreview[]> {
      const domains = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getDomains(),
        useRuntimeConfig().public as AppConfig,
      );
      this.domains = domains;
      return domains;
    },
    async getDomainIssues(): Promise<DomainIssuesDto> {
      const domainIssues = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.getDomainIssues(),
        useRuntimeConfig().public as AppConfig,
      );
      this.domainIssues = domainIssues;
      return domainIssues;
    },
    async getDomain(id: string) {
      this.domain = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) =>
          m.getDomain({
            id,
            checkForErrors: true,
          }),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async updateDomain(options: UpdateDomainRequest) {
      this.domain = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (m) => m.updateDomain(options),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async addDomainCatchAll(id: string) {
      const domain = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.addDomainCatchAll(id),
        useRuntimeConfig().public as AppConfig,
      );
      this.domain = domain;
    },
    async deleteDomain(id: string) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteDomain(id),
        useRuntimeConfig().public as AppConfig,
      );
      this.domain = null;
    },
    async createDomain(payload: CreateDomainOptions): Promise<DomainDto> {
      const response = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createDomain(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.domain = response;
      return response;
    },
    /*
     * Missed Emails
     */
    async getUnknownMissedEmails(payload: GetAllUnknownMissedEmailsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getUnknownMissedEmails(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.missedEmailPagination = res;
      return res;
    },
    async getMissedEmails(
      payload: GetAllMissedEmailsRequest,
    ): Promise<PageMissedEmailProjection> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getMissedEmails(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.missedEmailPagination = res;
      return res;
    },
    async getMissedEmail(payload: { id: string }): Promise<MissedEmail> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getMissedEmail(payload.id),
        useRuntimeConfig().public as AppConfig,
      );
      this.missedEmail = res;
      return res;
    },
    /**
     * Onboarding
     */
    hasOnboardedFeature(feature: HasOnboardedFeaturesHasOnboardedFeaturesEnum) {
      return (
        this.onboardingFeatures?.hasOnboardedFeatures?.includes(feature) ??
        false
      );
    },
    async deleteAllOnboarding() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteAllOnboarding(),
        useRuntimeConfig().public as AppConfig,
      );
      this.onboardingFeatures = null;
    },
    async updateOnboardingFeature(feature: SaveHasOnboardedFeatureFeatureEnum) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.updateOnboardedFeature(feature),
        useRuntimeConfig().public as AppConfig,
      );
      this.onboardingFeatures = res;
    },
    async getOnboardingFeatures() {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOnboardedFeatures(),
        useRuntimeConfig().public as AppConfig,
      );
      this.onboardingFeatures = res;
    },
    async createOnboardingSubmission(
      options: CreateOnboardingSubmissionOptions,
    ) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createOnboardingSubmission(options),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async updateHasOnboarded(options: UpdateHasOnboardedOptions) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.updateHasOnboarded(options),
        useRuntimeConfig().public as AppConfig,
      );
      this.user = res;
      return res;
    },
    /*
     * Organizations
     */
    async remindOrganizations(emailAddress: string) {
      await wrapAdmin(
        null,
        (admin) => admin.remindOrganizations(emailAddress),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },
    async createOrganization(payload: {
      name: string;
      slug: string;
    }): Promise<Organization> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createOrganization(payload.name, payload.slug),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteOrganizationAndUsers(): Promise<void> {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteAllOrganizationsAndUsers(),
        useRuntimeConfig().public as AppConfig,
      );
      this.organization = null;
      this.organizations = null;
    },
    async deleteOrganization(slug: string): Promise<void> {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteOrganization(slug),
        useRuntimeConfig().public as AppConfig,
      );
      this.organization = null;
    },
    async getOrganizationSamlServiceProviderData(payload: { slug: string }) {
      const providerData = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOrganizationSamlServiceProviderData(payload.slug),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSamlServiceProviderData = providerData;
    },
    async updateOrganizationSettings(payload: {
      slug: string;
      options: UpdateOrganizationSettingsOptions;
    }): Promise<OrganizationSettingsOptionalDto> {
      const organization = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.updateOrganizationSettings(payload.slug, payload.options),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSettings = organization;
      return organization;
    },
    async updateOrganizationSamlSettings(payload: {
      slug: string;
      options: UpdateOrganizationSamlSettingsOptions;
    }): Promise<OrganizationSamlSettingsDto> {
      const organization = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.updateOrganizationSamlSettings(payload.slug, payload.options),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSamlSettings = organization;
      return organization;
    },
    async deleteOrganizationIcon(payload: { slug: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteOrganizationIcon(payload.slug),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getOrganizationSettings(payload: {
      slug: string;
    }): Promise<OrganizationSettingsOptionalDto> {
      const organization = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOrganizationSettings(payload.slug),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSettings = organization;
      return organization;
    },
    async createOrganizationSettings(payload: {
      slug: string;
      options: CreateOrganizationSettingsOptions;
    }): Promise<OrganizationSettingsOptionalDto> {
      const organization = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.createOrganizationSettings(payload.slug, payload.options),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSettings = organization;
      return organization;
    },
    async createOrganizationSamlSettings(payload: {
      slug: string;
      options: CreateOrganizationSamlSettingsOptions;
    }): Promise<OrganizationSamlSettingsDto> {
      const organization = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.createOrganizationSamlSettings(payload.slug, payload.options),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSamlSettings = organization;
      return organization;
    },
    async deleteOrganizationSamlSettings(slug: string) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteOrganizationSamlSettings(slug),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSamlSettings = null;
    },
    async getHasUserMadeAction() {
      const hasUserMadeActionDto = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.hasUserMadeAction(),
        useRuntimeConfig().public as AppConfig,
      );
      this.hasUserMadeAction = hasUserMadeActionDto;
    },
    async trySetUserApiKeyFromCache() {
      const apiKey = storageService.tryGet('apiKeyCache');
      if (apiKey) {
        log('API Key cache found');
        this.apiAccessToken = { key: apiKey, expiresAt: '' };
        await this.refreshUserAndSubscription();
      } else {
        log('No API Key cache found');
      }
    },
    async setUserApiKey(user: UserDto) {
      if (user.apiAccessToken) {
        this.apiAccessToken = user.apiAccessToken;
      }
      this.user = user;
      storageService.trySet(
        'apiKeyCache',
        user.apiAccessToken?.key ?? user.apiKey,
      );
    },
    async getUserForToken(payload: string): Promise<UserDto> {
      const account: AccountDto = await wrapAdmin(
        payload,
        (admin) => admin.getAccount(),
        useRuntimeConfig().public as AppConfig,
        false,
      );
      this.sessionExpired = false;
      this.user = account.user;
      this.subscription = account.subscription ?? null;
      this.userAddons = account.addons ?? null;
      this.account = account ?? null;
      if (this.selectedTrialEnabled && account.subscription) {
        this.selectedTrialEnabled = false;
      }
      return account.user;
    },
    async getOrganizationSamlSettings(
      slug: string,
    ): Promise<OrganizationSamlSettingsDto> {
      const organization = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOrganizationSamlSettings(slug),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationSamlSettings = organization;
      return organization;
    },
    async getOrganization(slug: string): Promise<Organization> {
      const organization = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOrganization(slug),
        useRuntimeConfig().public as AppConfig,
      );
      this.organization = organization;
      return organization;
    },
    async getOrganizationUsersPaginated(
      page: Page<GetOrganizationUsersPaginatedSortEnum> & {
        slug: string;
        searchFilter?: string;
      },
    ): Promise<PageOrganizationUser> {
      const organizationUsers = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.getOrganizationUsersPaginated({
            slug: page.slug,
            page: page.page,
            size: page.size,
            sort: page.sort,
            searchFilter: page.searchFilter ? page.searchFilter : undefined,
          }),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationUsersPaginated = organizationUsers;
      return organizationUsers;
    },
    async getOrganizationUsers(slug: string): Promise<User[]> {
      const organizationUsers = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOrganizationUsers(slug),
        useRuntimeConfig().public as AppConfig,
      );
      this.organizationUsers = organizationUsers;
      return organizationUsers;
    },
    async sendOrganizationUserAccess(payload: {
      organizationSlug: string;
      emailAddress: string;
    }) {
      await wrapAdmin(
        null,
        (admin) =>
          admin.sendAccessLink(
            payload.organizationSlug,
            payload.emailAddress,
            (useRuntimeConfig()?.public as AppConfig)?.redirectDevUrl,
          ),
        useRuntimeConfig().public as AppConfig,
        true,
      );
    },

    async updateSmtpAccess(payload: UpdateSmtpAccessRequest) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.updateSmtpSmtpAccess(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async updateImapAccess(payload: UpdateImapAccessRequest) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.updateImapAccess(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async refreshImapSmtpSecrets() {
      this.user = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.refreshImapSmtpSecrets(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getImapSmtpAccess(payload: { inboxId: string | undefined }) {
      this.imapSmtpAccess = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getImapSmtpAccess(payload.inboxId),
        useRuntimeConfig().public as AppConfig,
      );
    },

    /**
     * Series
     */
    async getSeriesEventAggregates(payload: GetEventAggregatesSeriesRequest) {
      this.seriesEventAggregates = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getSeriesEventAggregates(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getSeriesUsage(payload: GetUsageRecordSeriesRequest) {
      this.seriesUsage = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getSeriesUsage(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getSeriesUsageRecordUpdates(
      payload: GetUsageRecordUpdatesSeriesRequest,
    ) {
      this.seriesUsageRecordUpdates = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getSeriesUsageRecordUpdates(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },

    async editOrganizationUser(payload: {
      organizationSlug: string;
      userId: string;
      options: UpdateOrganizationUserOptions;
    }): Promise<User> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.updateOrganizationUser(
            payload.organizationSlug,
            payload.userId,
            payload.options,
          ),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async addOrganizationUser(payload: {
      organizationSlug: string;
      options: InviteOrganizationUserOptions;
    }): Promise<UserDto> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.inviteOrganizationUser(
            payload.organizationSlug,
            payload.options,
          ),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteOrganizationUser(payload: {
      organizationSlug: string;
      userId: string;
    }): Promise<void> {
      return wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.deleteOrganizationUser({
            slug: payload.organizationSlug,
            userId: payload.userId,
          }),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getOrganizations() {
      this.organizations = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOrganizations(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getDnsLookupsResults(
      dNSLookupsOptions: DNSLookupsOptions,
    ): Promise<DNSLookupResults> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getDnsLookups({ dNSLookupsOptions }),
        useRuntimeConfig().public as AppConfig,
      );
      this.dnsLookupResults = res;
      return res;
    },
    async getDnsLookupResults(
      dnsLookupOptions: DNSLookupOptions,
    ): Promise<DNSLookupResults> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getDnsLookup({ dNSLookupOptions: dnsLookupOptions }),
        useRuntimeConfig().public as AppConfig,
      );
      this.dnsLookupResults = res;
      return res;
    },
    /**
     * Accounts
     */
    async getAccounts(emailAddress: string) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAccounts(emailAddress),
        useRuntimeConfig().public as AppConfig,
        true,
      );
      this.accounts = res;
    },
    async getOtherAccounts() {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getOtherAccounts(),
        useRuntimeConfig().public as AppConfig,
      );
      this.otherAccounts = res;
    },
    async setPassword(payload: { password: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.setPassword(payload.password),
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Notifications
     */
    async markReadNotifications() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.markAllNotificationsRead(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getNotifications() {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getNotifications(),
        useRuntimeConfig().public as AppConfig,
      );
      this.notifications = res;
    },
    async getNotification(id: string) {
      this.notification = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getNotification(id),
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Alias
     */
    async getThreadPagination(payload: GetAliasThreadsRequest) {
      this.threadPagination = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getThreadPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAllThreadsPagination(payload: GetThreadsPaginatedRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAllThreadsPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.threadPagination = res;
    },
    async getThread(threadId: string) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getThread(threadId),
        useRuntimeConfig().public as AppConfig,
      );
      this.thread = res;
    },
    async getAliasPagination(payload: GetAliasesRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAliasPagination(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.aliasPagination = res;
    },

    async deleteAlias(aliasId: string): Promise<void> {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.deleteAlias(aliasId),
        useRuntimeConfig().public as AppConfig,
      );
      this.alias = null;
    },
    async createAlias(
      createAliasOptions: CreateAliasOptions,
    ): Promise<AliasDto> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.createAlias({ createAliasOptions }),
        useRuntimeConfig().public as AppConfig,
      );
      this.alias = res;
      return res;
    },
    async getAlias(aliasId: string) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getAlias(aliasId),
        useRuntimeConfig().public as AppConfig,
      );
      this.alias = res;
    },
    /**
     * Sents
     */
    async getAllSentPagination(payload: GetSentEmailsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getSentForUser(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.sentsAllPagination = res;
    },
    async getInboxSentPagination(payload: GetInboxSentEmailsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getSentForInbox(payload),
        useRuntimeConfig().public as AppConfig,
      );
      this.sentsInboxPagination = res;
    },
    async getSent(id: string) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getSent(id),
        useRuntimeConfig().public as AppConfig,
      );
      this.sent = res;
    },
    async verifyEmailAddress(verifyOptions: VerifyEmailAddressRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.verifyEmailAddress(verifyOptions);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.verifyEmailAddressResult = res;
    },
    async deleteAllVerificationRequests() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllEmailValidationRequests();
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async deleteVerificationRequest(id: string) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteEmailValidationRequest({ id });
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async describeDomain(
      describeDomainOptions: DescribeMailServerDomainRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.mailServerDescribeDomain(describeDomainOptions);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.describeDomainResult = res;
    },
    async deleteZapierConnection() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteZapierConnection();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.zapierConnection = null;
    },
    async getZapierConnection() {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getZapierConnection();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.zapierConnection = res;
    },
    async getZapierNewContacts(page: Page<ZapierGetNewContactRecordsSortEnum>) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getZapierNewContactRecords({
            page: page.page,
            size: page.size ?? undefined,
            sort: page.sort ?? ZapierGetNewContactRecordsSortEnum.DESC,
          });
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.zapierNewContactRecords = res;
    },
    async getZapierNewAttachments(
      page: Page<ZapierGetNewAttachmentRecordsSortEnum>,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getZapierNewAttachmentRecords({
            page: page.page,
            size: page.size ?? undefined,
            sort: page.sort ?? ZapierGetNewAttachmentRecordsSortEnum.DESC,
          });
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.zapierNewAttachmentRecords = res;
    },
    async getZapierNewEmails(page: ZapierGetNewEmailRecordsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getZapierNewEmailRecords(page);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.zapierNewEmailRecords = res;
    },
    async exportData(payload: {
      exportType: GetExportLinkExportTypeEnum;
      exportOptions: ExportOptions;
    }) {
      const res: ExportLink = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.exportData(payload.exportType, payload.exportOptions);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.exportDownloadLink = res.downloadLink;
    },
    /**
     * Inbox repliers
     */
    async getAllInboxRepliers(payload: GetInboxRepliersRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllInboxRepliers({
            ...payload,
          });
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxRepliers = res;
    },
    async getInboxInboxRepliers(payload: GetInboxRepliersRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.getInboxInboxRepliers({
            ...payload,
          }),
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxInboxRepliers = res;
    },
    async updateInboxReplier(
      payload: UpdateInboxReplierRequest,
    ): Promise<InboxReplierDto> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.updateInboxReplier(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxReplier = res;
      return res;
    },
    async createInboxReplier(
      payload: CreateNewInboxReplierRequest,
    ): Promise<InboxReplierDto> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createInboxReplier(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxReplier = res;
      return res;
    },
    async getInboxReplierEvents(payload: GetInboxReplierEventsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxReplierEvents(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxReplierEvents = res;
    },
    async deleteAllInboxRepliers() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllInboxRepliers();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxRepliers = null;
      this.inboxInboxRepliers = null;
    },
    async deleteInboxReplier(payload: { id: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteInboxReplier({ id: payload.id });
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxReplier = null;
    },
    async getInboxReplier(payload: { id: string }) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxReplier(payload.id);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxReplier = res;
    },
    /**
     * Temporary access key
     */
    async getTemporaryAccessKeyForEmails() {
      if (
        this.temporaryAccessKeyForEmails &&
        !tempAccessTokenWillOrHasExpired(
          this.temporaryAccessKeyForEmails.expiresAt,
        )
      ) {
        // do nothing if not expired and has token
      } else {
        log('Temporary token is expired or not-existing fetching new token');
        const res = await wrapAdminContext3(
          this.apiAccessToken?.key,
          (admin) => {
            return admin.getTemporaryAccessKey(
              CreateTemporaryAccessKeyOptionsEndpointEnum.GET_EMAIL,
            );
          },
          useRuntimeConfig().public as AppConfig,
        );
        this.temporaryAccessKeyForEmails = res;
      }
    },
    async getTemporaryAccessKeyForAttachments() {
      if (
        this.temporaryAccessKeyForAttachments &&
        !tempAccessTokenWillOrHasExpired(
          this.temporaryAccessKeyForAttachments.expiresAt,
        )
      ) {
        // do nothing if not expired
      } else {
        const res = await wrapAdminContext3(
          this.apiAccessToken?.key,
          (admin) => {
            return admin.getTemporaryAccessKey(
              CreateTemporaryAccessKeyOptionsEndpointEnum.GET_ATTACHMENT,
            );
          },
          useRuntimeConfig().public as AppConfig,
        );
        this.temporaryAccessKeyForAttachments = res;
      }
    },
    /**
     * Latest messages
     */
    async getLatestMessagesEmails() {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getLatestMessagesEmails();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.latestMessagesEmails = res;
    },
    async getLatestMessagesSms() {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getLatestMessagesSms();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.latestMessagesSms = res;
    },
    /**
     * Metrics
     */
    async getMetricsNotifications() {
      this.metricsNotifications = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getMetricsNotifications(),

        useRuntimeConfig().public as AppConfig,
      );
    },
    async getMetricsReputation() {
      this.metricsReputation = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getMetricsReputation(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getMetricsSubscription() {
      this.metricsSubscription = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => admin.getMetricsSubscription(),
        useRuntimeConfig().public as AppConfig,
      );
    },
    /**
     * Connectors
     */
    async deleteAllInboxConnectors() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllInboxConnectors();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.allConnectors = null;
    },
    async getAllInboxConnectors(payload: GetConnectorsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllInboxConnectors(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.allConnectors = res;
    },
    async deleteInboxConnector(payload: DeleteConnectorRequest) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteInboxConnector(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connector = null;
    },
    async deleteConnectorSmtpConnection(
      payload: GetConnectorSmtpConnectionRequest,
    ) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteConnectorSmtpConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorSmtpConnection = null;
    },
    async deleteConnectorImapConnection(
      payload: DeleteConnectorImapConnectionRequest,
    ) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteConnectorImapConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorImapConnection = null;
    },
    async getConnectorEvent(payload: GetConnectorEventRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getConnectorEvent(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorEvent = res;
    },
    async getConnectorSyncSettings(payload: GetConnectorImapConnectionRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getConnectorSyncSettings(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorSyncSettings = res.result ?? null;
    },
    async getConnectorImapConnection(
      payload: GetConnectorImapConnectionRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getConnectorImapConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorImapConnection = res.result ?? null;
    },
    async createConnectorImapConnection(
      payload: CreateConnectorImapConnectionRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createConnectorImapConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorImapConnection = res ?? null;
    },
    async editConnectorImapConnection(
      payload: UpdateConnectorImapConnectionRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.updateConnectorImapConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorImapConnection = res ?? null;
    },
    async editConnectorSmtpConnection(
      payload: UpdateConnectorSmtpConnectionRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.updateConnectorSmtpConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorSmtpConnection = res ?? null;
    },
    async createConnectorSmtpConnection(
      payload: CreateConnectorSmtpConnectionRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createConnectorSmtpConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorSmtpConnection = res ?? null;
    },
    async getConnectorSmtpConnection(
      payload: GetConnectorSmtpConnectionRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getConnectorSmtpConnection(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connectorSmtpConnection = res.result ?? null;
    },
    async getInboxConnector(payload: GetConnectorRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxConnector(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connector = res;
    },
    async updateInboxConnector(payload: UpdateConnectorRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.updateInboxConnector(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connector = res;
      return res;
    },
    async createInboxConnectorWithOptions(
      payload: CreateConnectorWithOptionsRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createInboxConnectorWithOptions(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connector = res;
      return res;
    },
    async createInboxConnector(payload: CreateConnectorRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createInboxConnector(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.connector = res;
      return res;
    },
    /**
     * Inbox forwarders
     */
    async testNewInboxForwarder(payload: TestNewInboxForwarderRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.testNewInboxForwarder(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarderTestResult = res;
    },
    async getAllInboxForwarders(payload: GetInboxForwardersRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllInboxForwarders({
            ...payload,
          });
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.allInboxForwarders = res;
    },
    async getInboxInboxForwarders(payload: GetInboxForwardersRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) =>
          admin.getInboxInboxForwarders({
            ...payload,
          }),
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxInboxForwarders = res;
    },
    async updateInboxForwarder(
      payload: UpdateInboxForwarderRequest,
    ): Promise<InboxForwarderDto> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.updateInboxForwarder(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarder = res;
      return res;
    },
    async createInboxForwarder(
      payload: CreateNewInboxForwarderRequest,
    ): Promise<InboxForwarderDto> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createInboxForwarder(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarder = res;
      return res;
    },
    async getAllInboxForwarderEvents(
      payload: GetAllInboxForwarderEventsRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllInboxForwarderEvents(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarderEvents = res;
    },
    async getInboxForwarderEventsForInbox(
      payload: GetInboxForwarderEventsRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxForwarderEvents(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarderEvents = res;
    },
    async getInboxForwarderEvents(payload: GetInboxForwarderEventsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxForwarderEvents(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarderEvents = res;
    },
    async deleteInboxInboxForwarders(payload: { inboxId: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllInboxInboxForwarders(payload.inboxId);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxInboxForwarders = null;
    },
    async deleteAllInboxForwarders() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllInboxForwarders();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.allInboxForwarders = null;
      this.inboxInboxForwarders = null;
    },
    async deleteInboxForwarder(payload: { id: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteInboxForwarder({ id: payload.id });
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarder = null;
    },
    async getInboxForwarderEvent(payload: { id: string }) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxForwarderEvent(payload.id);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarderEvent = res;
    },
    async getInboxForwarder(payload: { id: string }) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxForwarder(payload.id);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxForwarder = res;
    },

    async getDeliveryStatus(payload: { id: string }) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getDeliveryStatus({ deliveryId: payload.id });
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.deliveryStatus = res;
    },

    async getDeliveryStatusPagination(
      page: GetSentDeliveryStatusesRequest & { sentId?: string },
    ) {
      const res: PageDeliveryStatus = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return page.sentId
            ? admin.getDeliveryStatusesBySentId({
                ...page,
                sort: page.sort as unknown as GetSentDeliveryStatusesBySentIdSortEnum,
                sentId: page.sentId,
              })
            : admin.getDeliveryStatuses(page);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.deliveryStatusPagination = res;
    },

    async getComplaintEmailPagination(payload: GetComplaintsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getComplaints(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.complaintPagination = res;
    },

    /**
     * Bounced
     */
    async getComplaint(payload: GetComplaintRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getComplaint(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.complaint = res;
    },
    async getBouncedEmailPagination(payload: GetBouncedEmailsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllBouncedEmails(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.bouncedEmailPagination = res;
    },
    async getBouncedRecipientPagination(payload: GetBouncedRecipientsRequest) {
      const res: PageBouncedRecipients = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllBouncedRecipients(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.bouncedRecipientPagination = res;
    },
    async getBouncedEmail(id: string) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getBouncedEmail(id);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.bouncedEmail = res;
    },
    async getBouncedRecipient(id: string) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getBouncedRecipient(id);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.bouncedRecipient = res;
    },
    /**
     * Inbox rulesets
     */
    async getTestInboxRulesetReceiving(
      payload: TestInboxRulesetReceivingRequest,
    ) {
      this.testInboxRulesetReceivingResult = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.testInboxRulesetReceiving(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getTestInboxRulesetSending(payload: TestInboxRulesetSendingRequest) {
      this.testInboxRulesetSendingResult = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.testInboxRulesetSending(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAllInboxRulesets(payload: GetInboxRulesetsRequest) {
      this.allInboxRulesets = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllInboxRulesets(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getInboxInboxRulesets(
      payload: GetInboxRulesetsRequest,
    ): Promise<void> {
      this.inboxInboxRulesets = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxInboxRulesets(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async createInboxRuleset(payload: {
      inboxId: string | null;
      options: CreateInboxRulesetOptions;
    }): Promise<InboxRulesetDto> {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.createInboxRuleset(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxRuleset = res;
      return res;
    },
    async deleteInboxInboxRulesets(payload: { inboxId: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllInboxInboxRulesets(payload.inboxId);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxInboxRulesets = null;
    },
    async deleteAllInboxRulesets() {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteAllInboxRulesets();
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.allInboxRulesets = null;
      this.inboxInboxRulesets = null;
    },
    async deleteInboxRuleset(payload: { id: string }) {
      await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.deleteInboxRuleset(payload.id);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxRuleset = null;
    },
    async getInboxRuleset(payload: { id: string }) {
      this.inboxRuleset = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxRuleset(payload.id);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    // tracking
    async getTrackingPixel(payload: { id: string }) {
      this.trackingPixel = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getTrackingPixel({ id: payload.id });
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAllTrackingPixels(payload: GetAllTrackingPixelsRequest) {
      this.allTrackingPixels = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllTrackingPixels(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
    },
    async getAllSentEmailTrackingPixels(
      payload: GetAllSentTrackingPixelsRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getAllSentEmailTrackingPixels(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.allSentEmailTrackingPixels = res;
    },
    async getSentEmailTrackingPixels(
      payload: GetSentEmailTrackingPixelsRequest,
    ) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getSentEmailTrackingPixels(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.sentEmailTrackingPixels = res;
    },
    async getInboxTrackingPixels(payload: ListInboxTrackingPixelsRequest) {
      const res = await wrapAdminContext3(
        this.apiAccessToken?.key,
        (admin) => {
          return admin.getInboxTrackingPixels(payload);
        },
        useRuntimeConfig().public as AppConfig,
      );
      this.inboxTrackingPixels = res;
    },
    toggleSidebarSmOpen() {
      this.sidebarSmOpen = !this.sidebarSmOpen;
    },
  },
});
