<template>
  <div
    class="antialiased"
    data-el="layout-default"
    :data-ready="ready ? '1' : '0'"
    :class="{ fill: true, 'bg-gray-50': true }"
  >
    <Toast />
    <client-only>
      <div
        :data-regression="isRegression ? '1' : '0'"
        :data-has-user="hasUser ? '1' : '0'"
      />
    </client-only>
    <div data-id="onboarding-header" />
    <div class="fill flex flex-col">
      <article
        class="flex w-full flex-1 flex-row"
        data-id="default-layout"
        :data-environment="environment"
        :data-ready="ready"
      >
        <NuxtPage v-if="ready" />
        <div class="flex w-full" v-else>
          <AuthPage
            data-id="loading"
            context="loading"
            :loading="true"
            title="Loading"
          />
        </div>
      </article>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineAsyncComponent, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useStore3 } from './store/main';
import AuthPage from './components/auth/AuthPage.vue';
import { useRuntimeConfig } from '#imports';
import Toast from 'primevue/toast';

const store3 = useStore3();


const environment = computed(() => {
  return useRuntimeConfig().public?.environment ?? 'unknown';
});

const { user, ready, regressionTest } = storeToRefs(store3);

const isRegression = computed(() => {
  return regressionTest.value;
});
const hasUser = computed(() => !!user.value);
</script>
