<template>
  <!-- eslint-disable-next-line  -->
  <aside
    class="fixed left-0 top-0 z-50 h-screen shadow-xl sm:translate-x-0"
    data-id="sidebar"
    :data-minimized="sidebarMinimized ? '1' : '0'"
    :class="{
      'translate-x-0': sidebarSmOpen,
      '-translate-x-full': !sidebarSmOpen,
      'w-60': !sidebarMinimized,
      'w-20': sidebarMinimized,
    }"
    aria-label="Sidebar"
    @mouseover="sidebarMinimized = false"
    @focus="sidebarMinimized = false"
  >
    <div
      class="flex h-full flex-col overflow-y-auto bg-white px-3"
      :class="sidebarMinimized ? 'items-center justify-center' : ''"
    >
      <ul
        class="flex-1 pt-3 font-medium sm:pt-0"
        :class="
          sidebarMinimized ? 'flex flex-col items-center gap-2' : 'space-y-1'
        "
        data-id="sidebar-link-wrapper"
      >
        <NuxtLink
          class="hidden items-center py-4 no-underline sm:flex"
          to="/dashboard/"
          data-test-id="sidebar-home-logo"
        >
          <img
            class="h-6"
            :class="!sidebarMinimized ? 'mr-3' : ''"
            src="/assets/logo.svg"
            alt="MailSlurp"
          />
          <span
            class="hidden self-center whitespace-nowrap text-lg font-bold text-gray-900 sm:block"
            v-if="!sidebarMinimized"
            data-test-id="logo"
            >MailSlurp</span
          >
        </NuxtLink>
        <li
          v-for="item in navItems"
          :key="item.title"
          :data-nav-li-="item.title"
          :class="{ 'rounded-lg': true, 'bg-slate-50': hasOpenChildren(item) }"
        >
          <SidebarDivider v-if="item.borderTop" />
          <SidebarLink
            :item="item"
            :current-path="currentPath"
            :has-open-children="hasOpenChildren(item)"
            :minimized="sidebarMinimized"
          />
          <ul class="space-y-1 py-1" v-if="hasOpenChildren(item)">
            <li v-for="child in item.children" :key="child.title">
              <SidebarLink
                :item="child"
                is-child
                :current-path="currentPath"
                :minimized="sidebarMinimized"
              />
            </li>
          </ul>
        </li>
      </ul>
      <div
        class="flex flex-row py-4"
        :class="sidebarMinimized ? 'justify-center' : 'justify-end'"
      >
        <button
          class="self-end rounded-lg bg-slate-50 p-2"
          type="button"
          :title="sidebarMinimized ? 'Expand' : 'Collapse'"
          @click="toggleMinized"
        >
          <Icon
            class="text-gray-900"
            :icon="sidebarMinimized ? 'angleDoubleRight' : 'angleDoubleLeft'"
          />
        </button>
      </div>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { hasActiveChildren, isActivePath } from '../../lib/services/helpers';
import { computed } from 'vue';

import { useStore3 } from '../../store/main';
import { storeToRefs } from 'pinia';
import SidebarLink from './SidebarLink.vue';
import SidebarDivider from './SidebarDivider.vue';
import { useRoute } from '#imports';
import Icon from '../../components/Icon.vue';
import type { NavItem } from '../../lib/services/types';

const toggleMinized = () => {
  store3.setSidebarLgMinimized(!sidebarMinimized.value);
};

const route = useRoute();
const store3 = useStore3();

const currentPath = computed<string>(() => {
  return route?.path ?? '/';
});
const {
  sidebarLgMinified: sidebarMinimized,
  sidebarSmOpen,
  isChild,
  unreadCount,
} = storeToRefs(store3);

const hasOpenChildren = (item: NavItem) => {
  return expandedItem(item) && item.children && item.children.length > 0;
};
const expandedItem = (i: NavItem) => {
  return (
    isActivePath(currentPath.value, i) ||
    hasActiveChildren(currentPath.value, i.children)
  );
};

const navItems = computed<NavItem[]>(() => {
  const items: NavItem[] = [
    {
      to: '/dashboard',
      title: 'Home',
      icon: 'home',
      dataId: 'sidebar-home',
    },
    {
      to: '/compose',
      title: 'Compose',
      icon: 'compose',
      dataId: 'sidebar-compose',
      children: [
        {
          to: '/templates',
          title: 'Templates',
          icon: 'templates',
          dataId: 'sidebar-templates',
        },
        {
          to: '/contacts',
          title: 'Contacts',
          icon: 'contacts',
          dataId: 'sidebar-contacts',
        },
      ],
    },

    {
      to: '/inboxes',
      title: 'Inboxes',
      icon: 'inbox',
      dataId: 'sidebar-inboxes',
    },
    {
      to: '/emails',
      dataId: 'sidebar-emails',
      icon: 'emails',
      title: 'Emails',
    },
    {
      borderTop: true,
      to: '/phone-numbers',
      title: 'Phones',
      icon: 'phones',
      dataId: 'sidebar-phone',
    },
    {
      to: '/sms',
      title: 'SMS',
      icon: 'sms',
      dataId: 'sidebar-sms',
    },
    {
      borderTop: true,
      to: '/webhooks',
      title: 'Webhooks',
      icon: 'webhooks',
      dataId: 'sidebar-webhooks',
    },
    {
      to: '/automations',
      title: 'Automations',
      icon: 'automations',
      dataId: 'sidebar-automations',
    },
    {
      to: '/email-validation',
      title: 'Validation',
      icon: 'validation',
      dataId: 'sidebar-email-validation',
    },
    {
      borderTop: true,
      to: '/plugins',
      title: 'Plugins',
      icon: 'plugins',
      dataId: 'sidebar-plugins',
    },
    {
      to: '/ai',
      title: 'AI',
      icon: 'ai',
      dataId: 'sidebar-ai',
    },
    {
      borderTop: true,
      to: '/upgrade',
      bold: true,
      icon: 'upgrade',
      dataId: 'upgrade-btn-nav',
      dontShowToChild: true,
      title: 'Plans',
    },
    {
      to: '/settings',
      title: 'Settings',
      icon: 'cog',
      dataId: 'sidebar-settings',
      children: [
        {
          to: '/organizations',
          title: 'Team',
          icon: 'users',
          dataId: 'sidebar-team',
          dontShowToChild: true,
        },
        {
          to: '/portals',
          title: 'Portals',
          icon: 'portals',
          dataId: 'sidebar-portal',
        },
        {
          to: '/usage',
          title: 'Usage',
          icon: 'usage',
          dataId: 'sidebar-usage',
          dontShowToChild: true,
        },
        {
          to: '/reputation',
          title: 'Reputation',
          icon: 'reputation',
          dataId: 'sidebar-reputation',
          dontShowToChild: true,
        },
        {
          to: '/notifications',
          title: 'Notifications',
          icon: 'notifications',
          dataId: 'sidebar-notifications',
          dontShowToChild: true,
        },
      ],
    },
  ];
  const all: NavItem[] = items.map((x: NavItem) => {
    if (x.withCount) {
      x.count = unreadCount.value ?? 0;
    }
    return x;
  });
  // filter if child
  if (isChild.value) {
    return all
      .filter((n) => !n.dontShowToChild)
      .map((it) => {
        return {
          ...it,
          children: (it.children ?? []).filter((c) => !c.dontShowToChild),
        };
      });
  } else {
    return all;
  }
});
</script>
