<template>
  <IconRender :icon="icon" />
</template>
<script setup lang="ts">
import type { MsIcon } from '../lib/services/types';
import { defineAsyncComponent } from 'vue';

const IconRender = defineAsyncComponent({
  loader: () => import('./IconRender.vue'),
});
const props = defineProps<{
  icon: MsIcon;
}>();
</script>
