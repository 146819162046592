import {
  type ApiResponse,
  AppendProductAddonPlanAddonEnum,
  CreateInboxInboxTypeEnum,
  CreateWebhookOptionsEventNameEnum,
  ErrorCodesDtoAppErrorCodesEnum,
  type GetPlanDisplaysDesiresAddonPlanEnum,
  type OrganizationSamlSettingsConfigAuthnRequestBindingEnum,
  type OrganizationSamlSettingsConfigDigestAlgorithmEnum,
  type OrganizationSamlSettingsConfigProtocolEnum,
  type OrganizationSamlSettingsConfigSignatureAlgorithmEnum,
  type OrganizationSamlSettingsDto,
  PhonePlanDtoPhoneCountryEnum,
  PlanAddonsSupportedAddonsEnum,
  type PlanFeatures,
  type PlanLimits,
  type SendEmailOptionsValidateEmailAddressesEnum,
  type User,
  type UserDto,
} from '@mailslurp/admin-sdk';
import { type InjectionKey, type Ref } from 'vue';
import type { useStore3 } from '../../store/main';
import { Variant } from '../../store/types';
import {
  type ApiResult,
  type ConnectorConnectionType,
  type FormSize,
  type NavItem,
  type TabItemRef,
  type TabSelectItemRef,
} from './types';
import { getLogger } from './getLogger';
import { assertUnreachable } from './assertUnreachable';

export const SELECT_ALL_INBOXES = 'ALL_INBOXES';
export const SELECT_ALL_PHONES = 'ALL_PHONES';

// should be same as the /upgrade/ pages

export function sizeToPrime(size?: FormSize) {
  if (size === 'lg') {
    return 'large';
  }
  if (!size || size === 'md') {
    return 'normal';
  }
  return 'small';
}

export type CardCallback = () => void;
export const getDomId = () =>
  `id-${Math.random().toString(36).substr(2, 9)}-${Date.now().toString(36)}`;

type IStore3 = ReturnType<typeof useStore3>;

export type KeyValueItems<T> = {
  content: T;
  items: {
    key: keyof T;
    label: string;
    ago?: boolean;
  }[];
};

const log = getLogger('helpers');

export const debug = log;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const registerErrorLocally = (error: any) => {
  // track the errors locally for testing
  const key = '__errors';
  try {
    if (localStorage) {
      log('Register error locally ' + error);
      if (!localStorage.getItem(key)) {
        localStorage.setItem(key, '[]');
      }
      const old = JSON.parse(localStorage.getItem(key) ?? '[]');
      localStorage.setItem(key, JSON.stringify([...old, error]));
    }
  } catch (e) {
    console.error('Could not register error locally', e);
  }
};

export const SAMLHelpers = {
  acs(slug: string): string {
    return `https://enterprise.mailslurp.com/saml/${slug}${
      isDev() ? '?staging=1' : ''
    }`;
  },

  logoutLink() {
    return `https://enterprise.mailslurp.com/logout${
      isDev() ? '?staging=1' : ''
    }`;
  },

  samlLink(
    slug: string,
    organizationSamlSettings: OrganizationSamlSettingsDto | undefined,
  ): string | undefined {
    return organizationSamlSettings
      ? `https://enterprise.mailslurp.com/login?slug=${slug}${
          isDev() ? '&staging=1' : ''
        }`
      : undefined;
  },
};

export function truncate(str: string | number, length: number = 40) {
  if (typeof str !== 'string') {
    return str;
  }
  return str.length > length ? str.substring(0, length) + '...' : str;
}

export function blankOrEmptyToUndefined(
  inp: string | null | undefined,
): string | undefined {
  if (!inp || inp.trim() === '') {
    return undefined;
  } else {
    return inp;
  }
}

export type UpgradeProductType =
  | 'plan'
  | 'email'
  | 'inboxes'
  | 'ai'
  | 'connector'
  | 'phone'
  | 'verification'
  | 'user'
  | 'missing';
export const pricingFeatureTableId = 'pricing-features-table';
export const pricingFeatureTableRowId = (
  row: keyof PlanFeatures | keyof PlanLimits,
) => (row ? `pricing-features-table-${row}` : pricingFeatureTableId);

export function getProductTypeForAddon(
  addon: PlanAddonsSupportedAddonsEnum | AppendProductAddonPlanAddonEnum,
): UpgradeProductType | undefined {
  switch (addon) {
    case PlanAddonsSupportedAddonsEnum.INBOXES:
      return 'inboxes';
    case PlanAddonsSupportedAddonsEnum.EMAILS:
      return 'email';
    case PlanAddonsSupportedAddonsEnum.VALIDATION:
      return 'verification';
    case PlanAddonsSupportedAddonsEnum.CONNECTORS:
      return 'connector';
    case PlanAddonsSupportedAddonsEnum.AI:
      return 'ai';
    default:
      return undefined;
  }
}

export type AddonPagePages = {
  expedite: '1' | null;
};
export type EditConnectorConnectionParams = {
  connection: 'smtp' | 'imap';
};
export type CreateConnectorConnectionParams = {
  create?: '1' | null;
  inboxId?: string | null;
  connectorType?: ConnectorConnectionType;
};
export type PricingQueryParams = {
  highlightAddon:
    | PlanAddonsSupportedAddonsEnum
    | AppendProductAddonPlanAddonEnum
    | null;
  highlightFeature: keyof PlanFeatures | null;
  highlightLimit: keyof PlanLimits | null;
  userPlanQuantity?: string | null;
  desiresAddonPlan?:
    | GetPlanDisplaysDesiresAddonPlanEnum
    | AppendProductAddonPlanAddonEnum
    | null;
  desiresUserPlan?: '1' | null;
  desiresPhonePlan?: '1' | null;
};

export type SAMLSettingsCardData = {
  entryPoint: string;
  binding: OrganizationSamlSettingsConfigAuthnRequestBindingEnum | null;
  certificate: string;
  issuer: string;
  authnContext: string | null;

  privateKey: string | null;
  protocol: OrganizationSamlSettingsConfigProtocolEnum | null;
  digestAlgorithm: OrganizationSamlSettingsConfigDigestAlgorithmEnum | null;
  signatureAlgorithm: OrganizationSamlSettingsConfigSignatureAlgorithmEnum | null;

  wantAssertionsSigned: boolean | null;
  wantAuthnSigned: boolean | null;
};

export const RadioGroupValues = {
  webhookCreate: {},
};

export function isPhoneSupported(
  eventType: CreateWebhookOptionsEventNameEnum | null,
): boolean {
  return eventType === CreateWebhookOptionsEventNameEnum.NEW_SMS;
}

export function isInboxSupported(
  eventType: CreateWebhookOptionsEventNameEnum | null,
): boolean {
  return (
    eventType !== CreateWebhookOptionsEventNameEnum.BOUNCE_RECIPIENT &&
    eventType !== CreateWebhookOptionsEventNameEnum.BOUNCE &&
    eventType !== CreateWebhookOptionsEventNameEnum.NEW_SMS
  );
}

export function hasActiveChildren(
  path: string,
  children: { to: string; title?: string; children?: unknown[] }[] | undefined,
) {
  if (!children || !children.length) {
    return false;
  }
  return (
    children!.filter((x) => {
      return section(x.to) === section(path);
    }).length > 0
  );
}

/**
 * Take a code snippet source and replace the template vars with passed variables if provided
 */
export function templateCodeSnippet(
  sourceCode: string,
  variables?: { user?: User | UserDto },
  quote: boolean = true,
): string {
  let out = sourceCode;
  [
    [
      '__API_KEY__',
      `${quote ? '"' : ''}${variables?.user?.apiKey}${quote ? '"' : ''}`,
    ],
  ].forEach(([a, b]) => {
    if (a && b) {
      out = out.replace(new RegExp(a, 'g'), b);
    }
  });
  // remove last line break
  return out.replace(/\n$/, '');
}

export const isActivePath = (currentPath: string, i: NavItem) => {
  return section(currentPath) === section(i.to);
};

export function section(str?: string): string {
  if (!str) {
    return '';
  }
  return str.split('/').filter((x) => x)?.[0] ?? '';
}

export class ApiError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public apiResult: ApiResult<any>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(result: ApiResult<any>) {
    super(result.error);
    this.apiResult = result;
    // Restore prototype chain
    Object.setPrototypeOf(this, new.target.prototype);
  }

  override toString() {
    return JSON.stringify({ apiResult: this.apiResult });
  }
}

// export async function callOrThrow_<T>(
//   f: () => Promise<ApiResponse<T>>,
// ): Promise<T> {
//   const maxRetries = 1;
//   let attempt = 0;
//   let lastError: any;
//   while (attempt < maxRetries) {
//     try {
//       log(`Call or throw (${attempt}/${maxRetries}): ${f}`);
//       const response: ApiResult<T> = await mapResponse(f);
//       if (!response.error) return response.result!;
//       lastError = new ApiError(response);
//       // Only retry if status is null
//       if (response.status !== null) break;
//     } catch (error) {
//       lastError = error.error;
//       // Break if the status property exists and is not null
//       if (error && (error as any).status) break;
//     }
//     attempt++;
//   }
//   throw lastError;
// }

export async function callOrThrow<T>(
  f: () => Promise<ApiResponse<T>>,
): Promise<T> {
  const response: ApiResult<T> = await mapResponse(f);
  if (response.error) {
    log('Error in callOrThrow %o' + response);
    throw new ApiError(response);
  } else {
    return response.result!;
  }
}

async function mapResponse<T>(
  f: () => Promise<ApiResponse<T>>,
): Promise<ApiResult<T>> {
  try {
    const res = await f();
    return mapToResult(res);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.response) {
      try {
        const error = await e.response.json();
        log('mapResponse error %o' + error);
        return {
          errorClass: error.errorClass,
          error: error.error ?? error.message,
          url: error.url ?? e.response.url,
          status: error.status ?? e.response.status,
          caseNumber: error.caseNumber,
          errorCode: error.errorCode,
          result: null,
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (er: any) {
        log('mapResponse no json error %o' + er);
        return {
          error: er?.message ?? JSON.stringify(er),
          errorClass: null,
          errorCode: null,
          url: null,
          status: null,
          caseNumber: null,
          result: null,
        };
      }
    } else {
      log('mapResponse unknown exception %o' + e);
      return {
        url: null,
        errorCode: null,
        errorClass: null,
        error: e?.message ?? JSON.stringify(e),
        result: null,
        status: null,
        caseNumber: null,
      };
    }
  }
}

function numberSuffix(d: number, s: string): string {
  return `${d.toFixed(0)}${s}+`;
}

/**
 * turn a number from 1040 to 1K+ etc
 * @param n
 */
export function formatNumberTruncate(n: number): string {
  if (n < 1_000) {
    return `${n}`;
  } else if (n < 1_000_000) {
    return numberSuffix(n / 1_000, 'K');
  } else {
    return numberSuffix(n / 1_000_000, 'M');
  }
}

/**
 * ERROR HANDLER
 * @param response
 * WILL THROW
 */
async function mapToResult<T>(response: ApiResponse<T>): Promise<ApiResult<T>> {
  const url = response.raw.url;
  const status = response.raw.status;
  if (status >= 400) {
    // as ErrorResponse
    const json = await response.raw.json();
    log(`mapToResult url ${url} ERROR ${json}`);
    return {
      caseNumber: json?.caseNumber,
      url,
      error: json?.message ?? 'Unknown error',
      errorCode: json?.errorCode ?? undefined,
      status,
      errorClass: json?.errorClass ?? undefined,
      result: null,
    };
  } else {
    const result = await response.value();
    return {
      url,
      error: undefined,
      errorCode: undefined,
      errorClass: undefined,
      caseNumber: undefined,
      status,
      result,
    };
  }
}

export function getErrorMessage(err: ApiError): string {
  const e = err?.apiResult?.errorCode;
  switch (e) {
    default:
      return (err?.apiResult?.status ?? 0) >= 500
        ? 'An API server error occurred calling path ' + err?.apiResult.url
        : err.message ?? JSON.stringify(err);
  }
}

// get error code
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorCodeVariant(result?: ApiResult<any>): Variant {
  const e = result?.errorCode;
  log(`get variant ${e}`);
  // accept any error response code starting with w
  if (e && e[0] === 'W') {
    return Variant.warning;
  }
  switch (e) {
    case ErrorCodesDtoAppErrorCodesEnum.I_001_UPGRADE_REQUIRED:
      return Variant.primary;
    default:
      return Variant.danger;
  }
}

export const inboxTypesItems = [
  {
    text: 'HTTP',
    value: CreateInboxInboxTypeEnum.HTTP_INBOX,
  },
  {
    text: 'SMTP',
    value: CreateInboxInboxTypeEnum.SMTP_INBOX,
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Store3Action = () => Promise<any> | any;

export type CreateInboxOptions = {
  useDomainPool: boolean | undefined;
  favourite: boolean | undefined;
  description: string | undefined;
  name: string | undefined;
  expiresAt: string | undefined;
  tags: string[] | undefined;
  virtualInbox: boolean | undefined;
  useShortAddress: boolean | undefined;
};

export type OffboardingAction =
  | 'delete-subscription'
  | 'delete-account'
  | 'delete-customer';

export type TagsValidationRule = RegExp | ((p: { text: string }) => boolean);
export type TagsValidationItem = {
  rule: TagsValidationRule;
  classes: string;
  disableAdd: boolean;
};
export const headerNameValueMissingValue: (p: { text: string }) => boolean = ({
  text,
}) => (text.match(/(.+)(:)(.+)/) || []).length !== 4;
export const headerNameValueInvalidColonCount: (p: {
  text: string;
}) => boolean = ({ text }) => (text.match(/:/g) || []).length !== 1;
export const headerNameValueNoColon: (p: { text: string }) => boolean = ({
  text,
}) => (text.match(/:/g) || []).length === 0;

export async function loadFetchSeq(
  actionPayloads: Store3Action[],
  store: IStore3,
  loading: Ref<boolean>,
): Promise<void> {
  try {
    loading.value = true;
    for (const actionPayload of actionPayloads) {
      await actionPayload();
    }
  } catch (e) {
    store.setError({
      error: e,
    });
  } finally {
    loading.value = false;
  }
}

export async function loadFetchMany(
  actionPayloads: Store3Action[],
  store: IStore3,
  loading: Ref<boolean>,
): Promise<void> {
  try {
    loading.value = true;
    await Promise.all(actionPayloads.map((actionPayload) => actionPayload()));
  } catch (e) {
    store.setError({
      error: e,
    });
  } finally {
    loading.value = false;
  }
}

export async function loadFetch(
  cb: Store3Action,
  store: IStore3,
  loading: Ref<boolean>,
): Promise<void> {
  try {
    loading.value = true;
    await cb();
  } catch (e) {
    log(`Load fetch error: ${e}`);
    store.setError({
      error: e,
    });
  } finally {
    loading.value = false;
  }
}

export function formatNumber(num?: number | string | null, digits = 0): string {
  if (num && num.toLocaleString) {
    return num.toLocaleString(undefined, { minimumFractionDigits: digits });
  } else if (num === undefined) {
    return '';
  } else {
    return num + '';
  }
}

export type SendOptionsSubmitOptions = {
  useName: boolean;
  addPixelTracking: boolean;
  filterBouncedRecipients: boolean;
  validateEmailAddresses: SendEmailOptionsValidateEmailAddressesEnum;
  sendWithQueue: boolean;
  sendWithSchedule: boolean;
  scheduleTimestamp: Date | null;
};

export enum RecipientSelectType {
  MANUAL = 0,
  GROUPS = 1,
  CONTACT = 2,
  INBOX = 3,
}

export type RecipientsEvent = {
  to: string[] | null;
  bcc: string[] | null;
  cc: string[] | null;
  toGroup: string | null;
  toContacts: string[] | null;
};

export const PROVIDE_KEY_ADD_TAB = Symbol() as InjectionKey<
  (tab: TabItemRef) => void
>;
export const PROVIDE_KEY_ADD_TABSELECTITEM = Symbol() as InjectionKey<
  (tab: TabSelectItemRef) => void
>;

export function copyStringToClipboard(str: string) {
  if (!document || !document.execCommand) {
    return;
  }
  // Create new element
  const el = document.createElement('textarea');
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand('copy');
  // Remove temporary element
  document.body.removeChild(el);
}

// get a string for the limit number (-1 can mean unlimited)
export function getDisplayLimit(limit: number): string {
  if (limit === 0) {
    return '';
  } else if (limit === -1) {
    return 'Unlimited';
  } else {
    return formatNumber(limit);
  }
}

export function splitTrim(s?: string): string[] {
  return s ? s.split(',').map((v) => v.trim()) : [];
}

export interface Field<T> {
  key?: keyof T;
  noHtml?: boolean;
  label?: string;
  expiresAt?: boolean;
  ago?: boolean;
  uuid?: boolean;
  inboxEmailAddress?: boolean;
  supply?: (t: T) => string;
  supplyTo?: (t: T) => string;
  sortable?: boolean;
  phoneCountry?: boolean;
  badge?: boolean;
  badgeVariant?: (t: T) => Variant | undefined;
  attachmentCount?: boolean;
  byteCount?: boolean;
  dataId?: string;
}

export function isDev() {
  return process.env.NUXT_PUBLIC_ENVIRONMENT === 'dev';
}



export function getFormInputClasses(options: {
  size?: FormSize;
  readonly?: boolean;
  disabled?: boolean;
}) {
  return {
    'px-4 py-3 text-lg': options.size === 'lg',
    'text-md px-4 py-3': options.size === 'md',
    'px-3 py-2 text-sm': options.size === 'sm' || !options.size,
    'px-2 py-1 text-sm': options.size === 'xs',
    'bg-gray-50 focus:ring-gray-300': options.readonly || options.disabled,
  };
}

export function phoneCountryPrefix(p: PhonePlanDtoPhoneCountryEnum) {
  switch (p) {
    case PhonePlanDtoPhoneCountryEnum.US:
      return '+1';
    case PhonePlanDtoPhoneCountryEnum.GB:
      return '+44';
    case PhonePlanDtoPhoneCountryEnum.AU:
      return '+61';
    default:
      assertUnreachable(p);
  }
}

export function phoneCountryLabel(p: PhonePlanDtoPhoneCountryEnum) {
  switch (p) {
    case PhonePlanDtoPhoneCountryEnum.US:
      return 'United States (US)';
    case PhonePlanDtoPhoneCountryEnum.GB:
      return 'United Kingdom (GB)';
    case PhonePlanDtoPhoneCountryEnum.AU:
      return 'Australia (AU)';
    default:
      return assertUnreachable(p);
  }
}

export enum ContentTypeIcon {
  PDF = 'file-pdf',
  ARCHIVE = 'file-archive',
  IMAGE = 'file-image',
  FILE = 'file',
}

export function getContentTypeIcon(
  contentType: string | undefined | null,
): ContentTypeIcon {
  switch (contentType) {
    case 'application/pdf': {
      return ContentTypeIcon.PDF;
    }
    case 'application/zip': {
      return ContentTypeIcon.ARCHIVE;
    }
    case 'image/png':
    case 'image/jpeg':
    case 'image/gif':
    case 'image/svg':
    case 'image/svg+xml': {
      return ContentTypeIcon.IMAGE;
    }
    default: {
      return ContentTypeIcon.FILE;
    }
  }
}
