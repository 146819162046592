<template>
  <nav v-if="hasParts">
    <nav class="flex" aria-label="Breadcrumb">
      <ol class="inline-flex items-center space-x-0.5">
        <li class="inline-flex items-center">
          <NuxtLink
            class="inline-flex items-center text-sm font-medium text-slate-600 hover:text-green-600"
            to="/dashboard"
          >
            <svg
              class="mr-2.5 h-2.5 w-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"
              />
            </svg>
          </NuxtLink>
        </li>
        <li v-for="part in items" :key="part.url">
          <div class="flex items-center break-all">
            <svg
              class="h-2.5 w-2.5 text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <NuxtLink
              class="ml-0.5 truncate break-all text-xs font-light text-gray-600 hover:text-green-600 hover:underline md:ml-2"
              :to="part.url"
              >{{ part.title }}</NuxtLink
            >
          </div>
        </li>
      </ol>
    </nav>
  </nav>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { useStore3 } from '../store/main';
import { storeToRefs } from 'pinia';

const props = defineProps<{ path: string }>();

const store3 = useStore3();
const { user } = storeToRefs(store3);

const hasParts = computed(() => {
  return parts.value.length > 0;
});

const parts = computed<string[]>(() => {
  return props?.path?.split?.('/')?.filter?.((p) => !!p) ?? [];
});

const items = computed(() => {
  return parts.value.map((part, i) => {
    return {
      title: part,
      url: '/' + parts.value.slice(0, i + 1).join('/'),
    };
  });
});
</script>
