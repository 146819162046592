import { useStore3 } from '../store/main';
import { defineNuxtRouteMiddleware } from '#imports';

import { getLogger } from '../lib/services/getLogger';

const log = getLogger('params.global');

export default defineNuxtRouteMiddleware((to) => {
  const store3 = useStore3();
  if (import.meta.server) {
    return;
  }
  const { requestedLanguage, requestedPlan } = to.query ?? {};
  if (requestedLanguage) {
    log('Setting requested language: ' + requestedLanguage);
    store3.setRequestedLanguage(requestedLanguage as string);
  }
  if (requestedPlan) {
    log('Setting requested plan: ' + requestedPlan);
    store3.setRequestedPlan(requestedPlan as string);
  }
});
