<template>
  <button
    class="is-pointer px-4 py-2 hover:bg-gray-100"
    v-bind="$attrs"
    tabindex="0"
    :data-test-id="`${id}-action`"
    :title="title"
    type="submit"
    size="sm"
    @click="onClick"
    @keydown.enter="onClick"
    @keydown.space="onClick"
  >
    <span class="flex flex-row items-center justify-center" :class="iconClass">
      <Icon class="text-xs" :class="{ [textClass]: true }" :icon="icon" />
    </span>
  </button>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { Variant } from '../../store/types';
import Icon from '../../components/form/Icon.vue';
import type { MsIcon } from '../../lib/services/types';

const props = withDefaults(
  defineProps<{
    icon: MsIcon;
    iconClass?: string;
    variant?: Variant;
    title: string;
  }>(),
  { variant: Variant.secondary, iconClass: '' },
);

const emit = defineEmits<{ (e: 'click'): void }>();

const onClick = () => {
  emit('click');
};

const textClass = computed(() => {
  switch (props.variant) {
    case 'warning':
      return 'text-yellow-300';
    case 'light':
      return 'text-dark';
    default:
      return 'text-white';
  }
});

const id = computed(() => {
  return props.title.toLowerCase().replace(/\s/g, '-');
});
</script>
