import { getLocation } from '../lib/services/LambdaService';
import { useStore3 } from '../store/main';
import { defineNuxtRouteMiddleware } from '#imports';
import { storeToRefs } from 'pinia';
import { getLogger } from '../lib/services/getLogger';
const log = getLogger('geo.global');

export default defineNuxtRouteMiddleware(() => {
  if (import.meta.server) {
    return;
  }
  const store3 = useStore3();
  const { geo } = storeToRefs(store3);
  if (!geo.value) {
    log('Geo not set. Setting geolocation.');
    // don't await so we don't block
    getLocation()
      .then((res) => {
        if (res.success && res.result) {
          geo.value = res.result;
        } else {
          log('Geo fetch failed');
        }
        return 1;
      })
      .catch((err) => {
        log(`Get location failed ${err}`);
      });
  }
});
