<template>
  <component
    class="-center flex w-full cursor-pointer gap-x-3.5 rounded-md text-sm text-gray-800 no-underline hover:bg-gray-100 focus:ring-2 focus:ring-blue-500"
    :is="tag"
    :class="{
      'px-3 py-1 text-sm': size === 'sm',
      'text-md px-5 py-2': !size || size === 'md',
    }"
    :href="external ? to ?? href : undefined"
    :to="external ? undefined : to ?? href"
    :data-id="dataId"
    @click="onClick"
  >
    <slot>
      <!-- fallback -->
      <span>{{ label }}</span>
    </slot>
  </component>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { NuxtLink } from '#components';
import { getLogger } from '../../lib/services/getLogger';

const log = getLogger('FormFormDownItem');
const emit = defineEmits<{ (e: 'click'): void }>();
const props = withDefaults(
  defineProps<{
    label?: string;
    to?: string;
    href?: string;
    external?: boolean;
    button?: boolean;
    tag?: string;
    size?: 'sm' | 'md';
    dataId?: string;
  }>(),
  {
    dataId: '',
    label: undefined,
    to: undefined,
    href: undefined,
    external: false,
    button: false,
    tag: undefined,
    size: 'md',
  },
);
const tag = computed(() => {
  return (
    props.tag ??
    (props.button || !(props.to ?? props.href)
      ? 'button'
      : props.external
        ? 'a'
        : NuxtLink)
  );
});
const onClick = () => {
  log('click');
  emit('click');
};
</script>
