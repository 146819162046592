import { stringify } from 'qs';

export function combineUrlAndParams(
  url: string,
  params: { [key: string]: string | null | undefined | boolean }
): string {
  return (
    url +
    '?' +
    stringify(params, {
      skipNulls: true
    })
  );
}