<template>
  <header data-el="card-header">
    <section
      class="card-header-section flex flex-col gap-1 md:flex-row md:items-center md:justify-start"
    >
      <div
        class="mb-lg-0 mb-0 hidden flex-1 flex-row content-center items-center gap-3 sm:flex"
      >
        <span
          class="card-header-icon hidden text-slate-400 lg:flex"
          v-if="icon"
        >
          <Icon :class="iconClass ? iconClass : 'text-md'" :icon="icon" />
        </span>
        <div
          class="flex flex-1 items-center justify-start gap-2"
          v-if="titleTo || title || length"
          data-id="title"
        >
          <slot name="title">
            <span class="card-header-title font-medium" :class="titleClass">
              <NuxtLink v-if="titleTo" :to="titleTo">{{ title }}</NuxtLink>
              <span v-else>{{ title }}</span>
            </span>
          </slot>
          <CountBadge
            v-if="length"
            :count="length"
            :data-length="length"
            self="center"
            data-id="data-table-badge"
            size="xs"
            :variant="Variant.secondary"
          />
        </div>
        <slot name="before" />
      </div>
      <div class="flex flex-row" data-el="card-header:actions">
        <slot />
        <ButtonGroup
          v-if="
            onToggle ||
            onSend ||
            onSeen ||
            onReply ||
            onForward ||
            onDelete ||
            onDeleteAll ||
            onEdit ||
            onDownload ||
            onCompose ||
            onNew ||
            onSave ||
            onRefresh
          "
        >
          <Star
            v-if="onToggle"
            :id="toggleId"
            :button="true"
            :enabled="toggleValue"
            size="sm"
            :variant="variant"
            @toggle="onToggle"
          />
          <CardHeaderButton
            v-if="onSend"
            data-id="card-action-send"
            icon="compose"
            :title="'Send'"
            :variant="variant"
            @click="onSend"
          />
          <CardHeaderButton
            v-if="onSeen"
            data-id="card-action-mark-unread"
            icon="seen"
            :title="'Mark unread'"
            :variant="variant"
            @click="onSeen"
          />
          <CardHeaderButton
            v-if="onReply"
            data-id="card-action-reply"
            icon="reply"
            :title="'Reply'"
            :variant="variant"
            @click="onReply"
          />
          <CardHeaderButton
            v-if="onForward"
            data-id="card-action-forward"
            icon="forward"
            :title="'Forward'"
            :variant="variant"
            @click="onForward"
          />
          <CardHeaderButton
            v-if="onDelete"
            data-id="card-action-delete"
            icon="delete"
            :title="'Delete'"
            :variant="variant"
            @click="onDelete"
          />
          <CardHeaderButton
            v-if="onDeleteAll"
            data-id="card-action-delete-all"
            icon="deleteAll"
            :title="'Delete all'"
            :variant="variant"
            @click="onDeleteAll"
          />
          <CardHeaderButton
            v-if="onEdit"
            data-id="card-action-edit"
            icon="edit"
            :title="'Edit'"
            :variant="variant"
            @click="onEdit"
          />
          <CardHeaderButton
            v-if="onDownload"
            data-id="card-action-download"
            icon="download"
            :title="'Download'"
            :variant="variant"
            @click="onDownload"
          />
          <CardHeaderButton
            v-if="onCompose"
            data-id="card-action-compose"
            icon="compose"
            :title="composeText ? composeText : 'Compose email'"
            :variant="variant"
            @click="onCompose"
          />
          <CardHeaderButton
            v-if="onNew"
            data-id="card-action-new"
            icon="create"
            :title="'New'"
            :variant="variant"
            @click="onNew"
          />
          <CardHeaderButton
            v-if="onSave"
            data-id="card-action-save"
            icon="save"
            :title="'Save'"
            :variant="variant"
            @click="onSave"
          />
          <CardHeaderButton
            v-if="onRefresh"
            :iconClass="loading ? 'animate-spin' : ''"
            :variant="variant"
            data-id="card-action-refresh"
            icon="refresh"
            :title="'Refresh'"
            @click="onRefresh"
          />
        </ButtonGroup>
      </div>
    </section>
    <section class="text-gray-800" v-if="subtitle || $slots.content">
      <slot name="content">
        <p class="font-size-1 mb-0 mt-2 text-base">{{ subtitle }}</p>
      </slot>
    </section>
  </header>
</template>
<script setup lang="ts">
import Icon from '../components/form/Icon.vue';
import Star from '../components/Star.vue';
import CountBadge from '../components/CountBadge.vue';
import CardHeaderButton from '../components/card/CardHeaderButton.vue';
import type { CardCallback } from '../lib/services/helpers';
import { computed } from 'vue';
import { Variant } from '../store/types';
import ButtonGroup from '../components/form/ButtonGroup.vue';
import type { MsIcon } from '../lib/services/types';

withDefaults(
  defineProps<{
    length?: string | number;
    icon?: MsIcon;
    titleTo?: string;
    titleClass?: string;
    iconClass?: string;
    composeText?: string;
    toggleId?: string;
    title?: string;
    subtitle?: string;
    toggleValue?: boolean;
    onSend?: CardCallback;
    onSeen?: CardCallback;
    onCompose?: CardCallback;
    onDownload?: CardCallback;
    onForward?: CardCallback;
    onReply?: CardCallback;
    onDelete?: CardCallback;
    onDeleteAll?: CardCallback;
    onEdit?: CardCallback;
    onNew?: CardCallback;
    onSave?: CardCallback;
    onToggle?: CardCallback;
    onRefresh?: CardCallback;
    loading?: boolean;
  }>(),
  {
    length: undefined,
    icon: undefined,
    titleTo: '',
    iconClass: '',
    composeText: '',
    titleClass: '',
    toggleId: '',
    subtitle: '',
    title: '',
    onSend: undefined,
    onSeen: undefined,
    onCompose: undefined,
    onDownload: undefined,
    onForward: undefined,
    onReply: undefined,
    onDelete: undefined,
    onDeleteAll: undefined,
    onEdit: undefined,
    onNew: undefined,
    onSave: undefined,
    onToggle: undefined,
    onRefresh: undefined,
  },
);
const variant = computed<Variant>(() => Variant.light);
</script>
