import { useStore3 } from '../store/main';
import { defineNuxtRouteMiddleware } from '#imports';
import { storeToRefs } from 'pinia';

import { getLogger } from '../lib/services/getLogger';
const log = getLogger('routeChange.global');

export default defineNuxtRouteMiddleware(() => {
  const store3 = useStore3();
  const { sidebarSmOpen } = storeToRefs(store3);
  if (sidebarSmOpen.value) {
    log('Closing sidebar');
    sidebarSmOpen.value = false;
  }
});
