import { endOfYear, startOfYear, subMonths, subWeeks } from 'date-fns';

import { assertUnreachable } from './assertUnreachable';

export enum DateSelection {
  All = 'All time',
  Last7Days = '7 days',
  Last30Days = '30 days',
  ThisYear = 'This year',
}

export const minDate: Date = new Date('2000-01-01T00:00:00.000Z');
export const maxDate: Date = new Date('3000-01-01T00:00:00.000Z');

export type DateRange = { since: Date; before: Date };

export function getDateRangeOptions(): {
  text: string;
  value: DateSelection;
}[] {
  return [
    DateSelection.All,
    DateSelection.Last7Days,
    DateSelection.Last30Days,
    DateSelection.ThisYear,
  ].map((e: DateSelection) => ({ text: e, value: e }));
}

export function getCurrentTime() {
  return new Date().getTime();
}

export function getElapsedTime(givenTime: number) {
  return getCurrentTime() - givenTime;
}

export const getNow = (): number => {
  return Date.now();
};


function now(): Date {
  return new Date();
}

export function getSinceBefore(e: DateSelection): DateRange {
  const n = now();
  switch (e) {
    case DateSelection.ThisYear:
      return { since: startOfYear(n), before: endOfYear(n) };
    case DateSelection.Last30Days:
      return { since: subMonths(n, 1), before: maxDate };
    case DateSelection.Last7Days:
      return { since: subWeeks(n, 1), before: maxDate };
    case DateSelection.All:
      return { since: minDate, before: maxDate };
    default:
      assertUnreachable(e);
  }
}
