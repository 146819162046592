<template>
  <MIcon v-if="isFA" :icon="icon" />
  <span v-else>-</span>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import type { MsIcon } from '../../lib/services/types';
import MIcon from '../../components/Icon.vue';

withDefaults(
  defineProps<{
    icon: MsIcon;
  }>(),
  {},
);
// Add support for other libs
const isFA = computed(() => {
  return true;
});
</script>
