<template>
  <div
    v-bind="$attrs"
    data-el="card"
    :data-no-card="noCard ? '1' : '0'"
    :data-has-header="hasHeader ? '1' : '0'"
    :data-has-footer="hasFooter ? '1' : '0'"
    :data-loading="loading ? '1' : '0'"
    :class="{
      'rounded-md border border-gray-200 bg-white shadow-md': !noCard,
      'flex flex-col': true,
      'overflow-hidden': overflowHidden,
    }"
  >
    <!-- header -->
    <CardHeaderWrapper
      v-if="hasHeader"
      data-el="card:header"
      :has-header="hasHeader"
      :borders="false"
    >
      <slot name="header">
        <CardHeader
          v-if="header"
          :title="header"
          :icon="icon"
          :loading="loading"
        />
      </slot>
    </CardHeaderWrapper>
    <!-- body -->
    <div
      data-el="card:body"
      :class="{
        'p-4': !noPadding || loading,
        [bodyClass]: bodyClass,
      }"
    >
      <Spinner v-if="loading" :flush="noCard" />
      <slot v-else />
    </div>
    <!-- footer -->
    <div
      v-if="hasFooter"
      :class="{ 'p-4': hasFooter, 'tw-card-footer group': true }"
      data-el="card:footer"
    >
      <slot name="footer" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, useSlots } from 'vue';
import CardHeader from '../CardHeader.vue';
import Spinner from '../Spinner.vue';
import CardHeaderWrapper from '../CardHeaderWrapper.vue';
import type { MsIcon } from '../../lib/services/types';

const slots = useSlots();
const hasHeader = computed(() => !!(slots.header || props.header));
const hasFooter = computed(() => !!slots.footer && !props.noFooter);
const props = withDefaults(
  defineProps<{
    loading?: boolean;
    noCard?: boolean;
    noFooter?: boolean;
    overflowHidden?: boolean;
    flush?: boolean;
    noBody?: boolean;
    bodyClass?: string;
    header?: string;
    icon?: MsIcon;
  }>(),
  {
    noCard: false,
    boolean: false,
    flush: false,
    noBody: false,
    overflowHidden: false,
    header: '',
    bodyClass: '',
    icon: undefined,
  },
);
const noPadding = computed(() => props.noBody || props.flush);
</script>
