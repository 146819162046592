<template>
  <NavBarAlert
    :loading="loading"
    :done="done"
    theme="info"
    data-id="requires-password-banner"
  >
    <template #done>
      <p>Password set</p>
    </template>
    <template #content>
      <p>
        <strong> Password required: </strong>
        please add a password to your account.
        <button
          class="underline hover:no-underline"
          type="button"
          data-id="set-password"
          @click="setPassword"
        >
          Set password
        </button>
        .
      </p>
    </template>
  </NavBarAlert>
</template>
<script setup lang="ts">
import { useStore3 } from '../../../store/main';

import { ref } from 'vue';
import { loadFetchSeq } from '../../../lib/services/helpers';
import { Variant } from '../../../store/types';
import NavBarAlert from '../NavBarAlert.vue';

const setPassword = async () => {
  const password = window.prompt('Please enter your new password');
  if (password) {
    await loadFetchSeq(
      [
        () =>
          store.setPassword({
            password: password,
          }),
        () => store.refreshUserAndSubscription(),
      ],
      store,
      loading,
    );
    store.setFlash({
      variant: Variant.success,
      text: 'Password set',
    });
    done.value = true;
    setTimeout(() => {
      done.value = false;
    }, 5000);
  }
};
const store = useStore3();

const done = ref(false);
const loading = ref(false);
</script>
