<template>
  <div
    class="page-wrapper"
    :class="{
      'fill-card-page': fillPage,
      'page-no-content': noContent,
      [wrapperClass]: !!wrapperClass,
      ['bg-slate-50']: !wrapperClass,
    }"
    data-test-id="page-content"
    :data-page-loading="loading ? '1' : '0'"
    :data-page-is-child="isChild ? '1' : '0'"
    :data-upgrade-button="showUpgrade ? '1' : '0'"
    :data-requires-password="requiresPassword ? '1' : '0'"
    :data-requires-verification="requiresVerification ? '1' : '0'"
  >
    <client-only>
      <NavBarDashboard
        v-if="!noNavbar"
        :show-upgrade-button="showUpgrade"
        :requires-verification="requiresVerification"
        :requires-password="requiresPassword"
        :has-complaints="hasComplaints"
      >
        <Breadcrumbs
          class="hidden md:block"
          v-if="!noBreadcrumbs"
          data-test-id="breadcrumbs"
          :path="path"
        />
        <slot name="settings-bar" />
      </NavBarDashboard>
      <SidebarDashboard v-if="!hideNav" />
      <button
        class="fixed bottom-0 left-0 right-0 top-0 z-40 bg-gray-500 opacity-70"
        v-if="sidebarSmOpen"
        type="button"
        data-el="sidebar-mask"
        @click="sidebarSmOpen = false"
      ></button>
      <div
        :class="{
          hideNav: 'hidden',
          'flex flex-1': !hideNav,
          'sm:ml-60': !sidebarLgMinified,
          'sm:ml-20': sidebarLgMinified,
        }"
      >
        <section class="x-container w-full">
          <article class="page">
            <slot name="header" />
            <!-- page contents -->
            <div
              class="page-wrapper"
              :class="{
                'page-has-breadcrumb': !noBreadcrumbs,
              }"
            >
              <div class="page-content">
                <article v-if="notFound">
                  <div class="xcontainer">
                    <Flash v-if="!hideFlash" />
                    <NotFoundCard
                      :text="notFoundMessage || 'Resource not found.'"
                    />
                  </div>
                </article>
                <article v-else :class="containerClass">
                  <div class="page-content-row w-full justify-center py-3">
                    <div
                      class="page-content-page-inner"
                      :data-virtual-inbox="virtualInbox ? '1' : '0'"
                      :data-expiring-inbox="expiringInbox ? '1' : '0'"
                      :class="pageClass || ''"
                    >
                      <!-- content -->
                      <Flash class="mb-5" v-if="!hideFlash" />
                      <!-- header -->
                      <ContentPageHeader
                        :title="noHeaders ? '' : title"
                        :description="noHeaders ? '' : description"
                        :button="button"
                        :has-breadcrumbs="!noBreadcrumbs"
                        :help-link="helpLink"
                        :show-grant-access="showGrantAccess"
                        :show-notifications="showNotifications"
                        :show-documentation="showDocumentation"
                        :show-upgrade="showUpgrade"
                        :more-info="moreInfo"
                        :has-slot="!!$slots.default"
                        :tags="tags"
                        @grant="onGrantAccess"
                      >
                        <slot
                          v-if="$slots['header-actions']"
                          name="header-actions"
                        />
                      </ContentPageHeader>
                      <!-- Loader or show content slot -->
                      <div v-if="loading" :class="{ 'pt-3': !noHeaders }">
                        <LoadingCard />
                      </div>
                      <section
                        class="page-content-slot-wrapper"
                        v-else-if="$slots.default"
                        :class="{ 'pt-4': !noHeaders }"
                      >
                        <!-- MAIN CONTENT -->
                        <slot />
                      </section>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </article>
          <div class="div flex flex-1" />
        </section>
      </div>
      <PageFooter
        v-if="!hideNav && !noFooter"
        :class="hideNav ? '' : sidebarLgMinified ? 'sm:ml-20' : 'sm:ml-60'"
        enable-user-comments
        :has-onboarding="!!$slots.onboarding"
        :has-feedback="!!$slots.feedback"
      >
        <template #onboarding>
          <slot name="onboarding" />
        </template>
        <!--      <template #feedback>-->
        <!--        <OnboardingCard flush class="p-6 flex-1 flex flex-col h-full">-->
        <!--          <OnboardingLink icon="video" external :to="social.youtube" text="Videos"/>-->
        <!--          <OnboardingLink icon="book" external :to="links.docs.index" text="Documentation"/>-->
        <!--          <OnboardingLink icon="book" external :to="links.examples" text="Code examples"/>-->
        <!--        </OnboardingCard>-->
        <!--      </template>-->
      </PageFooter>
    </client-only>
  </div>
</template>
<script lang="ts" setup>
import { computed, watch } from 'vue';
import Flash from './Flash.vue';
import SidebarDashboard from '../components/nav/SidebarDashboard.vue';
import NavBarDashboard from '../components/nav/NavBarDashboard.vue';
import LoadingCard from '../components/LoadingCard.vue';
import PageFooter from '../components/Footer.vue';
import NotFoundCard from '../components/card/NotFoundCard.vue';
import ContentPageHeader from '../components/page/ContentPageHeader.vue';
import { useStore3 } from '../store/main';
import { storeToRefs } from 'pinia';
import { Variant } from '../store/types';
import Breadcrumbs from '../components/Breadcrumbs.vue';
import { UserDtoVerifiedEnum } from '@mailslurp/admin-sdk';
import { useHead, useRoute, navigateTo } from '#imports';
import type { PageButton } from '../lib/services/types';

const props = withDefaults(
  defineProps<{
    noHeaders?: boolean;
    virtualInbox?: null | boolean;
    expiringInbox?: null | string;
    description?: string;
    noFooter?: boolean;
    noContent?: boolean;
    notFound?: boolean;
    button?: PageButton;
    helpLink?: string;
    notFoundMessage?: string;
    wrapperClass?: string;
    title?: string;
    moreInfo?: string;
    pageClass?: string;
    fillPage?: boolean;
    hideFlash?: boolean;
    hideNav?: boolean;
    showDocumentation?: boolean;
    showNotifications?: boolean;
    showGrantAccess?: boolean;
    loading?: boolean;
    noBreadcrumbs?: boolean;
    noNavbar?: boolean;
    containerClass?: string;
    showUpgrade?: boolean;
  }>(),
  {
    showUpgrade: false,
    expiringInbox: '',
    description: '',
    noNavbar: false,
    noFooter: false,
    button: undefined,
    hideFlash: false,
    helpLink: '',
    notFoundMessage: '',
    wrapperClass: '',
    title: '',
    moreInfo: '',
    pageClass: '',
    containerClass: 'xcontainer',
  },
);
const emit = defineEmits<{ (e: 'grant'): void }>();
const store3 = useStore3();
const route = useRoute();

const path = computed(() => {
  return route.path ?? '';
});

const {
  user,
  isChild,
  sidebarSmOpen,
  sidebarLgMinified,
  complaintPagination,
  sessionExpired,
} = storeToRefs(store3);
watch(sessionExpired, async (val) => {
  if (val) {
    sessionExpired.value = false;
    await navigateTo('/logout');
  }
});
const requiresPassword = computed(() => {
  return !user.value?.hasPassword;
});
const requiresVerification = computed(() => {
  return (
    user.value?.verified === UserDtoVerifiedEnum.PENDING &&
    !user.value?.ssoProvider
  );
});
const hasComplaints = computed(() => {
  return (complaintPagination.value?.numberOfElements ?? 0) > 0;
});

useHead({
  title: `MailSlurp | ` + (props.title ?? 'Dashboard'),
});
const tags = computed<{ name: string; info: string; variant: Variant }[]>(
  () => {
    return [
      ...(props.expiringInbox
        ? [
            {
              name: 'Expires',
              info: 'This inbox expires at ' + props.expiringInbox,
              variant: Variant.info,
              dataId: 'tag-expires',
            },
          ]
        : []),
      ...(props.virtualInbox
        ? [
            {
              name: 'Virtual',
              info: `Virtual inbox uses a fake SMTP server and won't send any emails`,
              variant: Variant.warning,
              dataId: 'tag-virtual',
            },
          ]
        : []),
    ];
  },
);

const onGrantAccess = () => {
  emit('grant');
};
</script>
