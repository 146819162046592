<template>
  <NuxtLink
    :class="cls"
    @click="onClick"
    :to="to"
    :external="external"
    :target="external ? '_blank' : undefined"
  >
    <Icon class="mr-1" v-if="icon && iconPosition === 'left'" :icon="icon" />
    <slot />
    <Icon class="ml-1" v-if="icon && iconPosition === 'right'" :icon="icon" />
  </NuxtLink>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Icon from '../components/form/Icon.vue';
import type { EventName, FormSize, MsIcon } from '../lib/services/types';
import { useNuxtApp } from '#imports';
const { $captureEvent: ce } = useNuxtApp();
const onClick = async () => {
  if (props.captureEvent) {
    ce(props.captureEvent.name, props.captureEvent.data);
  }
};

const props = withDefaults(
  defineProps<{
    captureEvent?: {
      name: EventName;
      data?: string | number | object;
    };
    external?: boolean;
    to: string;
    classes?: string;
    size?: FormSize;
    icon?: MsIcon;
    iconPosition?: 'left' | 'right';
  }>(),
  {
    captureEvent: undefined,
    iconPosition: 'right',
    icon: undefined,
    external: false,
    classes: '',
    size: 'md',
  },
);

const cls = computed(() => {
  return props.classes
    ? props.classes
    : {
        'text-link': true,
        'text-md': props.size === 'lg',
        'text-base': props.size === 'md',
        'text-sm': props.size === 'sm',
      };
});
</script>
