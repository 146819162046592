<template>
  <div
    class="flex flex-row rounded-md"
    :data-variant="variant"
    :data-icon="icon"
    data-el="flash-alert"
    :data-flash-id="dataId"
    :data-id="dataId"
    :class="{
      [classes.bgClass]: true,
      [classes.textClass]: true,
      'border shadow': !flat,
    }"
  >
    <section class="flex w-full flex-col gap-3 p-4">
      <div>
        <header class="mb-3 flex flex-row gap-1">
          <h3 class="w-full text-xl font-semibold" v-if="title">
            <Icon class="mr-2 font-light opacity-80" v-if="icon" :icon="icon" />
            <span class="flex-1">{{ title }}</span>
          </h3>
          <button
            class="text-gray-700/0.6 hover:text-gray-900"
            v-if="dismissible && !flat"
            type="button"
            data-el="alert-card-close-button"
            @click="onClick(undefined)"
          >
            <Icon
              class="h-5 w-5 cursor-pointer opacity-70"
              icon="close"
              :class="{}"
            />
          </button>
        </header>
        <article class="flex flex-col gap-2">
          <p class="text-lg" v-if="subtitle">{{ subtitle }}</p>
          <p class="text-sm" v-if="text">{{ text }}</p>
          <code
            class="rounded border p-2 text-xs"
            v-if="variant === 'danger' && stack"
            >{{ stack }}</code
          >
          <slot />
        </article>
      </div>
      <div
        class="flex flex-row gap-2"
        v-if="$slots.button || (links && links.length)"
      >
        <slot v-if="$slots.button" name="button" />
        <Btn
          v-for="(routerLink, i) in links"
          :key="'link-' + i"
          :tag="routerLink.to ? 'button' : 'a'"
          :icon="routerLink.icon"
          icon-position="right"
          size="sm"
          :variant="variant"
          :href="routerLink.href"
          :to="routerLink.to"
          :target="routerLink.targetBlank ? '_blank' : undefined"
          :rel="routerLink.targetBlank ? 'noreferrer noopener' : undefined"
          data-el="alert-card-link"
          :data-id="routerLink.dataId || 'alert-button-external'"
          @click="onClick(routerLink.to)"
          >{{ routerLink.text }}
        </Btn>
      </div>
    </section>
    <footer class="text-xs" v-if="notes">
      {{ notes }}
    </footer>
  </div>
</template>
<script lang="ts" setup>
import Icon from '../../components/form/Icon.vue';
import { computed, onMounted } from 'vue';
import { Variant } from '../../store/types';
import Btn from '../../components/form/Btn.vue';
import { navigateTo } from '#imports';
import type { MsIcon } from '../../lib/services/types';
import { getLogger } from '../../lib/services/getLogger';

const onClick = (s?: string) => {
  log('Alert btn clicked');
  if (s) {
    navigateTo(s);
  }
  emit('dismissed');
};

const props = withDefaults(
  defineProps<{
    variant?: Variant | 'onboarding';
    dismissible?: boolean;
    dismissTimeoutSeconds?: number;
    flat?: boolean;
    title?: string;
    subtitle?: string;
    text?: string;
    icon?: MsIcon;
    links?: {
      text: string;
      to?: string;
      href?: string;
      targetBlank?: boolean;
      icon?: MsIcon;
      btn?: boolean;
      dataId?: string;
    }[];
    dataId?: string;
    stack?: string;
    notes?: string;
  }>(),
  {
    stack: '',
    notes: '',
    dataId: '',
    links: undefined,
    dismissTimeoutSeconds: undefined,
    variant: Variant.info,
    dismissible: true,
    flat: false,
    title: '',
    subtitle: '',
    text: '',
    icon: undefined,
  },
);

const log = getLogger('AlertCard');
const dismissed = () => {
  log('Alert dismissed');
  return emit('dismissed', props.dataId);
};
onMounted(() => {
  if (props.dismissTimeoutSeconds) {
    log('Add alert dismiss timeout');
    setTimeout(() => {
      log('Timeout dismiss alert');
      dismissed();
    }, props.dismissTimeoutSeconds * 1000);
  }
});
const emit = defineEmits<{ (e: 'dismissed', p?: string): void }>();
const classes = computed<{ bgClass: string; textClass: string }>(() => {
  switch (props.variant) {
    case 'onboarding':
      return {
        bgClass: 'bg-indigo-50 border-indigo-100',
        textClass: 'text-indigo-900',
      };
    case Variant.warning:
      return {
        bgClass: 'bg-amber-50 border-amber-100',
        textClass: 'text-amber-900',
      };
    case Variant.danger:
      return { bgClass: 'bg-red-50 border-red-100', textClass: 'text-red-900' };
    case Variant.upgrade:
      return {
        bgClass: 'bg-indigo-50 border-indigo-100',
        textClass: 'text-indigo-900',
      };
    case Variant.primary:
    case Variant.success:
      return {
        bgClass: 'bg-green-50 border-green-100',
        textClass: 'text-green-900',
      };
    case Variant.info:
      return {
        bgClass: 'bg-blue-50 border-blue-100',
        textClass: 'text-blue-900',
      };
    case Variant.secondary:
      return {
        bgClass: 'bg-gray-100 border-gray-200',
        textClass: 'text-gray-900',
      };
    default:
      return {
        bgClass: 'bg-gray-50 border-gray-100',
        textClass: 'text-gray-900',
      };
  }
});
</script>
