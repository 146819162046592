import fetch from 'cross-fetch';

import { getLogger } from './getLogger';
const log = getLogger('lambda-service');
// see https://bitbucket.org/jackmahoney/ms2-lambda/src/master/src/lambda-geo/
const baseUrl = 'https://f.mailslurp.link/f/geo';

export type GeoResult = {
  isMobile: boolean;
  country: string;

  region: string;
  timezone: string;
  ipAddress: string;
};

export type GeoResponse = {
  success: boolean;
  result?: GeoResult;
};

/**
 * Calls ms2-lambda geo function to get ip address etc from cloudfront
 */
export async function getLocation(): Promise<GeoResponse> {
  try {
    const res = await fetch(baseUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });
    const body = await res.json();
    log('Geo fetched');
    return { success: true, result: body };
  } catch (e) {
    log('Failed to get location ' + e);
    return { success: false, result: undefined };
  }
}
