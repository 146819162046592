import { getLogger } from './getLogger';

const log = getLogger('StorageService');

export type StorageKey =
  | 'REGRESSION_TEST'
  | 'requestedPlan'
  | 'apiKeyCache'
  | 'userFeedbackTime'
  | 'requestedLanguage'
  | 'isSamlRedirect';

export class StorageService {
  tryGetAndClear(key: StorageKey): string | null {
    const x = this.tryGet(key);
    this.trySet(key, '');
    return x;
  }
  tryGet(key: StorageKey): string | null {
    return localStorage ? localStorage.getItem(key) : null;
  }

  tryClear(key: StorageKey) {
    if (localStorage) {
      log('Remove item ' + key);
      return localStorage.removeItem(key);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trySet(key: StorageKey, value: any) {
    if (localStorage) {
      log('Set item ' + key);
      return localStorage.setItem(key, value);
    }
  }
}
