<template>
  <FormBadge
    class="count-text"
    :data-length="count"
    :size="size"
    :title="'' + count"
    :variant="variant"
  >
    {{ countF }}
  </FormBadge>
</template>
<script setup lang="ts">
import { formatNumberTruncate } from '../lib/services/helpers';
import { computed } from 'vue';

import { Variant } from '../store/types';
import FormBadge from '../components/form/FormBadge.vue';
import type { FormSize } from '../lib/services/types';

const props = withDefaults(
  defineProps<{
    size?: FormSize;
    variant?: Variant;
    count: number | string;
  }>(),
  {
    size: undefined,
    variant: Variant.light,
  },
);
const countF = computed(() =>
  formatNumberTruncate(parseInt(props.count.toString(), 10)),
);
</script>
