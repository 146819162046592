<template>
  <button
    class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
    type="button"
    :data-href="href"
    :data-id="dataId"
    @click="onClick"
  >
    <slot name="icon" />
    <span class="text-lg font-semibold leading-6">{{ text }}</span>
  </button>
</template>
<script setup lang="ts">
import { defineProps } from 'vue';
import { useNuxtApp } from '#imports';
const { $captureEvent: captureEvent } = useNuxtApp();
const props = defineProps<{
  context: string;
  dataId: string;
  href: string;
  text: string;
  provider: string;
}>();
const onClick = () => {
  if (props.context === 'sign_up') {
    captureEvent('begin_sign_up', {
      method: props.provider,
    });
  } else if (props.context === 'login') {
    captureEvent('begin_login', {
      method: props.provider,
    });
  }
  captureEvent('sso_click', {
    context: props.context,
    provider: props.provider,
  });
  window.location.href = props.href;
};
</script>
