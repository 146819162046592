import { getNow } from './TimeService';
import { addMinutes, isBefore } from 'date-fns';

export function tempAccessTokenWillOrHasExpired(
  expiresAt: string | Date | undefined | null
) {
  if (!expiresAt) {
    return true;
  }

  const dateNow: number = getNow();
  // Convert expiresAt to a Date object if it's not already one
  const expiresAtDate =
    expiresAt instanceof Date ? expiresAt : new Date(expiresAt);

  // Create a Date object from dateNow
  const currentDate = new Date(dateNow);

  // add 5 minutes from the current date
  const latest = addMinutes(currentDate, 5);

  // Check if expiresAtDate is before or equal to 'latest'
  return isBefore(expiresAtDate, latest);
}