<template>
  <NavBarAlert
    :loading="loading"
    :done="done"
    data-id="frozen-banner"
    theme="error"
  >
    <template #content>
      <p>
        <strong> Account frozen: </strong>
        Your account is frozen due to {{ frozenCause }}.
        <NuxtLink
          class="underline hover:no-underline"
          to="/unblock/"
          data-id="unblock-account"
        >
          Unblock account
        </NuxtLink>
        .
      </p>
    </template>
  </NavBarAlert>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useStore3 } from '../../../store/main';
import { computed, ref } from 'vue';
import NavBarAlert from '../NavBarAlert.vue';

const frozenCause = computed(() => {
  return user.value?.frozenMessage ?? 'payment issue, bounce, or complaints.';
});
const store = useStore3();
const { user } = storeToRefs(store);
const done = ref(false);
const loading = ref(false);
</script>
