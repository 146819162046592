<template>
  <Btn
    class="flex flex-row"
    v-if="canShow"
    tag="NuxtLink"
    :data-test-hasUnreadNotifications="hasUnreadNotifications"
    to="/notifications/"
    :variant="buttonVariant"
    icon="notifications"
    size="sm"
  >
    {{ unreadNotificationsCount }}
  </Btn>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue';
import Btn from './form/Btn.vue';
import { useStore3 } from '../store/main';
import { storeToRefs } from 'pinia';
import { Variant } from '../store/types';

const store3 = useStore3();

const { unreadNotificationsCount, hasUnreadNotifications, isChild } =
  storeToRefs(store3);
const canShow = computed<boolean>(() => !isChild.value);

const buttonVariant = computed<Variant>(() => {
  return hasUnreadNotifications.value ? Variant.warning : Variant.secondary;
});

onMounted(async () => {
  if (canShow.value) {
    await store3.getNotifications();
  }
});
</script>
