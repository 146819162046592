import { ApiError } from '../lib/services/helpers';
import { ErrorCodesDtoAppErrorCodesEnum } from '@mailslurp/admin-sdk';
import type { AppConfig } from '../lib/services/types';
import type { AdminService } from '../lib/api/AdminService';

export const REGEX_UPGRADE_VIEW = /upgrade\/?$/;

export async function wrapAdmin<T>(
  accessToken: undefined | null | string,
  fn: (admin: AdminService) => Promise<T>,
  appConfig: AppConfig,
  allowAnonymous = false,
): Promise<T> {
  const AdminServiceI = await import('../lib/api/AdminService').then(
    (it) => it.AdminService,
  );
  const basePath = appConfig.apiUrl;
  // try a call that depends on cognito token
  if (allowAnonymous) {
    const admin = new AdminServiceI('anonymous', basePath);
    return await fn(admin);
  } else {
    if (!accessToken) {
      return Promise.reject(
        new ApiError({
          error:
            'Access token required to access MailSlurp. Please login and try again.',
          errorCode: ErrorCodesDtoAppErrorCodesEnum.W_401_UNAUTHORIZED,
          url: null,
          status: null,
          caseNumber: null,
          errorClass: null,
          result: null,
        }),
      );
    }
    const admin = new AdminServiceI(accessToken, basePath);
    return await fn(admin);
  }
}

/**
 * Admin calls require cognito access token, this expires every 5 minutes
 * Wrap admin calls so that if they throw a 401 with "expired" in the message
 * then refresh the access token and try again
 */
export async function wrapAdminContext3<T>(
  apiKey: string | undefined | null,
  fn: (admin: AdminService) => Promise<T>,
  appConfig: AppConfig,
  allowAnonymous = false,
): Promise<T> {
  return wrapAdmin<T>(apiKey, fn, appConfig, allowAnonymous);
}
