<template>
  <Btn
    data-el="user-profile"
    :data-unread-notification-count="unreadNotificationsCount.toString()"
    icon="cog"
    size="sm"
    :variant="Variant.white"
    tag="button"
  />
</template>
<script setup lang="ts">
import { type UserDto } from '@mailslurp/admin-sdk';
import { storeToRefs } from 'pinia';
import Btn from './form/Btn.vue';
import { useStore3 } from '../store/main';
import { Variant } from '../store/types';

const props = withDefaults(
  defineProps<{ user: UserDto; interactive?: boolean }>(),
  { interactive: false },
);
const store3 = useStore3();
const { unreadNotificationsCount } = storeToRefs(store3);
</script>
