import pino from 'pino';

const logger = pino({
  transport: {
    target: 'pino-pretty',
  },
});
export function getLogger(name: string) {
  return (x: unknown, ...params: unknown[]) =>
    logger.info({ name, log: x, ...params });
}