import { default as accountstfO3XMngbvMeta } from "/tmp/workspace/pages/accounts.vue?macro=true";
import { default as aicYtKgkYrWiMeta } from "/tmp/workspace/pages/ai.vue?macro=true";
import { default as editFz04xnRod2Meta } from "/tmp/workspace/pages/aliases/[id]/edit.vue?macro=true";
import { default as index4oTg9sJe65Meta } from "/tmp/workspace/pages/aliases/[id]/index.vue?macro=true";
import { default as createtH1XAUXZwBMeta } from "/tmp/workspace/pages/aliases/create.vue?macro=true";
import { default as indexvzivzF7BHTMeta } from "/tmp/workspace/pages/aliases/index.vue?macro=true";
import { default as indexIati5r0DjgMeta } from "/tmp/workspace/pages/aliases/threads/[id]/index.vue?macro=true";
import { default as indexpi6yhBPBgiMeta } from "/tmp/workspace/pages/aliases/threads/index.vue?macro=true";
import { default as _91id_93MNeo160TRhMeta } from "/tmp/workspace/pages/attachments/[id].vue?macro=true";
import { default as createyKPMxp26xMMeta } from "/tmp/workspace/pages/attachments/create.vue?macro=true";
import { default as indexfsIO4XyioFMeta } from "/tmp/workspace/pages/attachments/index.vue?macro=true";
import { default as indextXYg7LutV4Meta } from "/tmp/workspace/pages/automations/index.vue?macro=true";
import { default as composeq5xiUFZPfBMeta } from "/tmp/workspace/pages/compose.vue?macro=true";
import { default as createhBlRrMveW7Meta } from "/tmp/workspace/pages/connectors/[id]/connections/create.vue?macro=true";
import { default as editFLntdj2zlYMeta } from "/tmp/workspace/pages/connectors/[id]/connections/edit.vue?macro=true";
import { default as indexHvn5Qto4EZMeta } from "/tmp/workspace/pages/connectors/[id]/connections/index.vue?macro=true";
import { default as editRIeHzHcfQPMeta } from "/tmp/workspace/pages/connectors/[id]/edit.vue?macro=true";
import { default as _91eventId_93ckjS0ZNfT4Meta } from "/tmp/workspace/pages/connectors/[id]/events/[eventId].vue?macro=true";
import { default as index5cCXE4NIpDMeta } from "/tmp/workspace/pages/connectors/[id]/events/index.vue?macro=true";
import { default as indexImslXpZgtCMeta } from "/tmp/workspace/pages/connectors/[id]/index.vue?macro=true";
import { default as indexpIdXQdF4QbMeta } from "/tmp/workspace/pages/connectors/[id]/sync/index.vue?macro=true";
import { default as createtVh7WemRPXMeta } from "/tmp/workspace/pages/connectors/create.vue?macro=true";
import { default as indexMENvJ0O71HMeta } from "/tmp/workspace/pages/connectors/index.vue?macro=true";
import { default as _91id_93dG4uaLqdJyMeta } from "/tmp/workspace/pages/contacts/[id].vue?macro=true";
import { default as createCRflprpor7Meta } from "/tmp/workspace/pages/contacts/create.vue?macro=true";
import { default as indexQG3jaYvIFAMeta } from "/tmp/workspace/pages/contacts/index.vue?macro=true";
import { default as dashboardRfTtRBqEUoMeta } from "/tmp/workspace/pages/dashboard.vue?macro=true";
import { default as editijWtja07uuMeta } from "/tmp/workspace/pages/domains/[id]/edit.vue?macro=true";
import { default as indexwBQZbnn5tcMeta } from "/tmp/workspace/pages/domains/[id]/index.vue?macro=true";
import { default as createuxerg7MkBNMeta } from "/tmp/workspace/pages/domains/create.vue?macro=true";
import { default as indexnAsieNjXooMeta } from "/tmp/workspace/pages/domains/index.vue?macro=true";
import { default as indexBor8KGlShRMeta } from "/tmp/workspace/pages/email-validation/index.vue?macro=true";
import { default as forwardCUPJUvOCKuMeta } from "/tmp/workspace/pages/emails/[id]/forward.vue?macro=true";
import { default as indexpx8JR9JviCMeta } from "/tmp/workspace/pages/emails/[id]/index.vue?macro=true";
import { default as reply17yAkfoskMMeta } from "/tmp/workspace/pages/emails/[id]/reply.vue?macro=true";
import { default as index7fRVga0sSCMeta } from "/tmp/workspace/pages/emails/index.vue?macro=true";
import { default as indexjOgWjgBT4XMeta } from "/tmp/workspace/pages/expired/[id]/index.vue?macro=true";
import { default as indexjfPHHbXEM6Meta } from "/tmp/workspace/pages/expired/index.vue?macro=true";
import { default as exportBy95qe5j67Meta } from "/tmp/workspace/pages/export.vue?macro=true";
import { default as forgot_45password8Wtu0JlVYRMeta } from "/tmp/workspace/pages/forgot-password.vue?macro=true";
import { default as editzHoLAawgFyMeta } from "/tmp/workspace/pages/forwarders/[id]/edit.vue?macro=true";
import { default as indexVsBnmYu0rXMeta } from "/tmp/workspace/pages/forwarders/[id]/index.vue?macro=true";
import { default as createDLh4nmkQD8Meta } from "/tmp/workspace/pages/forwarders/create.vue?macro=true";
import { default as indexa2f2IPxnV8Meta } from "/tmp/workspace/pages/forwarders/events/[id]/index.vue?macro=true";
import { default as indexBfvs6pM4bsMeta } from "/tmp/workspace/pages/forwarders/events/index.vue?macro=true";
import { default as indexKHni2Vca5wMeta } from "/tmp/workspace/pages/forwarders/index.vue?macro=true";
import { default as grant1ONxu4ckORMeta } from "/tmp/workspace/pages/grant.vue?macro=true";
import { default as _91id_938l3e4JeseIMeta } from "/tmp/workspace/pages/groups/[id].vue?macro=true";
import { default as createajrIMlaFLMMeta } from "/tmp/workspace/pages/groups/create.vue?macro=true";
import { default as index5zPaRYtxo5Meta } from "/tmp/workspace/pages/groups/index.vue?macro=true";
import { default as AccessTablejTNh2hrkbyMeta } from "/tmp/workspace/pages/home/AccessTable.vue?macro=true";
import { default as AccessTableRow5jiaqCjFxmMeta } from "/tmp/workspace/pages/home/AccessTableRow.vue?macro=true";
import { default as SmtpImapAccessViewNlopprDnoJMeta } from "/tmp/workspace/pages/home/SmtpImapAccessView.vue?macro=true";
import { default as indexgrPE0ZrkaMMeta } from "/tmp/workspace/pages/inbox-rulesets/[id]/index.vue?macro=true";
import { default as createYtPUjQr7YEMeta } from "/tmp/workspace/pages/inbox-rulesets/create.vue?macro=true";
import { default as indexzqr9Rf8hBRMeta } from "/tmp/workspace/pages/inbox-rulesets/index.vue?macro=true";
import { default as editzzWUxYy88wMeta } from "/tmp/workspace/pages/inboxes/[id]/edit.vue?macro=true";
import { default as indexupiA3gLexuMeta } from "/tmp/workspace/pages/inboxes/[id]/index.vue?macro=true";
import { default as createruOLtwcJRaMeta } from "/tmp/workspace/pages/inboxes/create.vue?macro=true";
import { default as indexH0Vys2BLnMMeta } from "/tmp/workspace/pages/inboxes/index.vue?macro=true";
import { default as indexzxmsoQA6sCMeta } from "/tmp/workspace/pages/index.vue?macro=true";
import { default as loginm0ZnPEcoE6Meta } from "/tmp/workspace/pages/login.vue?macro=true";
import { default as logout1hy4xhRui5Meta } from "/tmp/workspace/pages/logout.vue?macro=true";
import { default as mail_45serverxdqYEkdVx7Meta } from "/tmp/workspace/pages/mail-server.vue?macro=true";
import { default as index1xkLvAdiwfMeta } from "/tmp/workspace/pages/missed-emails/[id]/index.vue?macro=true";
import { default as indexrey3c1Vy7WMeta } from "/tmp/workspace/pages/missed-emails/index.vue?macro=true";
import { default as indexpAmU3Zi5GVMeta } from "/tmp/workspace/pages/notifications/[id]/index.vue?macro=true";
import { default as indexEc6oXsJTfOMeta } from "/tmp/workspace/pages/notifications/index.vue?macro=true";
import { default as indexbpjqJultONMeta } from "/tmp/workspace/pages/offboarding/index.vue?macro=true";
import { default as _91id_93SWM9XFhRVAMeta } from "/tmp/workspace/pages/organization/[id].vue?macro=true";
import { default as indexsx6dYj5AvxMeta } from "/tmp/workspace/pages/organization/index.vue?macro=true";
import { default as create_45samlR8XO1GKy59Meta } from "/tmp/workspace/pages/organizations/[slug]/create-saml.vue?macro=true";
import { default as create_45user20ECb6MVZVMeta } from "/tmp/workspace/pages/organizations/[slug]/create-user.vue?macro=true";
import { default as edit_45userkiGxbsQGXGMeta } from "/tmp/workspace/pages/organizations/[slug]/edit-user.vue?macro=true";
import { default as indexcWR6zpy1XIMeta } from "/tmp/workspace/pages/organizations/[slug]/index.vue?macro=true";
import { default as update_45samlq0jFaRh055Meta } from "/tmp/workspace/pages/organizations/[slug]/update-saml.vue?macro=true";
import { default as create7qFM3VudAWMeta } from "/tmp/workspace/pages/organizations/create.vue?macro=true";
import { default as indexkH76moXyoGMeta } from "/tmp/workspace/pages/organizations/index.vue?macro=true";
import { default as indexUuwWSjpUkwMeta } from "/tmp/workspace/pages/phone-numbers/[id]/index.vue?macro=true";
import { default as index06zrRyHmY0Meta } from "/tmp/workspace/pages/phone-numbers/[id]/sms/[smsid]/index.vue?macro=true";
import { default as replyvFPAMxQpFiMeta } from "/tmp/workspace/pages/phone-numbers/[id]/sms/[smsid]/reply.vue?macro=true";
import { default as indexnICcCqwPctMeta } from "/tmp/workspace/pages/phone-numbers/[id]/sms/index.vue?macro=true";
import { default as createlAtjmjvqBzMeta } from "/tmp/workspace/pages/phone-numbers/create.vue?macro=true";
import { default as indexWPywr1775fMeta } from "/tmp/workspace/pages/phone-numbers/index.vue?macro=true";
import { default as indexFnHPz0mPieMeta } from "/tmp/workspace/pages/plugins/index.vue?macro=true";
import { default as _91id_93wsgh7Kp6OvMeta } from "/tmp/workspace/pages/portals/[id].vue?macro=true";
import { default as createLiqw4uPfOmMeta } from "/tmp/workspace/pages/portals/create.vue?macro=true";
import { default as _91id_934iU7CkVsQ5Meta } from "/tmp/workspace/pages/portals/guests/[id].vue?macro=true";
import { default as createt6Kt9npH3tMeta } from "/tmp/workspace/pages/portals/guests/create.vue?macro=true";
import { default as indexCquMwrYpxfMeta } from "/tmp/workspace/pages/portals/guests/index.vue?macro=true";
import { default as indexlUrN2iKdrXMeta } from "/tmp/workspace/pages/portals/index.vue?macro=true";
import { default as previewsbZsj3NBfm5Meta } from "/tmp/workspace/pages/previews.vue?macro=true";
import { default as editTmNqgoHbP2Meta } from "/tmp/workspace/pages/repliers/[id]/edit.vue?macro=true";
import { default as index2oxt6pdksiMeta } from "/tmp/workspace/pages/repliers/[id]/index.vue?macro=true";
import { default as createGwFbBPEvzvMeta } from "/tmp/workspace/pages/repliers/create.vue?macro=true";
import { default as indexh3T4YYaxCqMeta } from "/tmp/workspace/pages/repliers/index.vue?macro=true";
import { default as indexAvLbbGQiavMeta } from "/tmp/workspace/pages/reputation/bounces/[id]/index.vue?macro=true";
import { default as indexY6Jc3hNv2NMeta } from "/tmp/workspace/pages/reputation/bounces/index.vue?macro=true";
import { default as indexEGlrhPOwcBMeta } from "/tmp/workspace/pages/reputation/complaints/[id]/index.vue?macro=true";
import { default as indexaoc22knCTAMeta } from "/tmp/workspace/pages/reputation/complaints/index.vue?macro=true";
import { default as indexwC41Rqa57OMeta } from "/tmp/workspace/pages/reputation/index.vue?macro=true";
import { default as indexVQjnIawMAlMeta } from "/tmp/workspace/pages/reputation/recipients/[id]/index.vue?macro=true";
import { default as index4yNHclPjkBMeta } from "/tmp/workspace/pages/reputation/recipients/index.vue?macro=true";
import { default as _91id_93Qs3LJrFiFRMeta } from "/tmp/workspace/pages/scheduled/[id].vue?macro=true";
import { default as indexxeL6pkRqPSMeta } from "/tmp/workspace/pages/scheduled/index.vue?macro=true";
import { default as indexOkNd9dAT0SMeta } from "/tmp/workspace/pages/sent/[id]/index.vue?macro=true";
import { default as _91id_93KG0lV82e7YMeta } from "/tmp/workspace/pages/sent/delivery/[id].vue?macro=true";
import { default as indexXEJf7d6DrdMeta } from "/tmp/workspace/pages/sent/index.vue?macro=true";
import { default as feedbackS5dthCVm6bMeta } from "/tmp/workspace/pages/settings/feedback.vue?macro=true";
import { default as indexNBiy5yyzYVMeta } from "/tmp/workspace/pages/settings/index.vue?macro=true";
import { default as sign_45upEFYLhDmVLwMeta } from "/tmp/workspace/pages/sign-up.vue?macro=true";
import { default as indexaCnW4jsHLYMeta } from "/tmp/workspace/pages/sms/index.vue?macro=true";
import { default as ssoSvJmV32ua3Meta } from "/tmp/workspace/pages/sso.vue?macro=true";
import { default as indexudZ7ZHl1b2Meta } from "/tmp/workspace/pages/templates/[id]/index.vue?macro=true";
import { default as createdI0qsTt9QyMeta } from "/tmp/workspace/pages/templates/create.vue?macro=true";
import { default as indexodz1RHakZlMeta } from "/tmp/workspace/pages/templates/index.vue?macro=true";
import { default as indexpJRuaQgGx3Meta } from "/tmp/workspace/pages/tracking/[id]/index.vue?macro=true";
import { default as index6qUD3wBO0fMeta } from "/tmp/workspace/pages/tracking/index.vue?macro=true";
import { default as default_45senderd1RIWRSNFnMeta } from "/tmp/workspace/pages/unblock/default-sender.vue?macro=true";
import { default as indexsHVb6IXuOKMeta } from "/tmp/workspace/pages/unblock/index.vue?macro=true";
import { default as unfreeze_45requestDBhu7a6nuUMeta } from "/tmp/workspace/pages/unblock/unfreeze-request.vue?macro=true";
import { default as _91id_937PvG0dAGgVMeta } from "/tmp/workspace/pages/upgrade/[id].vue?macro=true";
import { default as _91addon_93Qw2GHRpBeQMeta } from "/tmp/workspace/pages/upgrade/addons/[addon].vue?macro=true";
import { default as index2lt5bAJ5dPMeta } from "/tmp/workspace/pages/upgrade/callback/index.vue?macro=true";
import { default as indexylNuNwrAj4Meta } from "/tmp/workspace/pages/upgrade/index.vue?macro=true";
import { default as indexzTByOkcJATMeta } from "/tmp/workspace/pages/upgrade/missing/index.vue?macro=true";
import { default as indexrOajPmYJS0Meta } from "/tmp/workspace/pages/upgrade/phones/index.vue?macro=true";
import { default as _91id_93OR6UtVgWdzMeta } from "/tmp/workspace/pages/upgrade/quote/[id].vue?macro=true";
import { default as indexPALl3Tumb1Meta } from "/tmp/workspace/pages/upgrade/quote/index.vue?macro=true";
import { default as indexAl2iXqXkwTMeta } from "/tmp/workspace/pages/upgrade/users/index.vue?macro=true";
import { default as indexRRHahkqJ1bMeta } from "/tmp/workspace/pages/usage/index.vue?macro=true";
import { default as verifyWKfJjH0UsKMeta } from "/tmp/workspace/pages/verify.vue?macro=true";
import { default as edit2mcI8ZvHjsMeta } from "/tmp/workspace/pages/webhooks/[id]/edit.vue?macro=true";
import { default as indexnqXyABv0oUMeta } from "/tmp/workspace/pages/webhooks/[id]/index.vue?macro=true";
import { default as _91resultId_93h654SlTrXYMeta } from "/tmp/workspace/pages/webhooks/[id]/results/[resultId].vue?macro=true";
import { default as index22xnwlSCvpMeta } from "/tmp/workspace/pages/webhooks/[id]/results/index.vue?macro=true";
import { default as createHrtXWAhTwdMeta } from "/tmp/workspace/pages/webhooks/create.vue?macro=true";
import { default as indexBJR84KRWW1Meta } from "/tmp/workspace/pages/webhooks/endpoints/index.vue?macro=true";
import { default as index0rjUzm2nXCMeta } from "/tmp/workspace/pages/webhooks/index.vue?macro=true";
import { default as indexnMY1BqEdcsMeta } from "/tmp/workspace/pages/zapier/index.vue?macro=true";
export default [
  {
    name: "accounts",
    path: "/accounts",
    component: () => import("/tmp/workspace/pages/accounts.vue").then(m => m.default || m)
  },
  {
    name: "ai",
    path: "/ai",
    component: () => import("/tmp/workspace/pages/ai.vue").then(m => m.default || m)
  },
  {
    name: "aliases-id-edit",
    path: "/aliases/:id()/edit",
    component: () => import("/tmp/workspace/pages/aliases/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "aliases-id",
    path: "/aliases/:id()",
    component: () => import("/tmp/workspace/pages/aliases/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "aliases-create",
    path: "/aliases/create",
    component: () => import("/tmp/workspace/pages/aliases/create.vue").then(m => m.default || m)
  },
  {
    name: "aliases",
    path: "/aliases",
    component: () => import("/tmp/workspace/pages/aliases/index.vue").then(m => m.default || m)
  },
  {
    name: "aliases-threads-id",
    path: "/aliases/threads/:id()",
    component: () => import("/tmp/workspace/pages/aliases/threads/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "aliases-threads",
    path: "/aliases/threads",
    component: () => import("/tmp/workspace/pages/aliases/threads/index.vue").then(m => m.default || m)
  },
  {
    name: "attachments-id",
    path: "/attachments/:id()",
    component: () => import("/tmp/workspace/pages/attachments/[id].vue").then(m => m.default || m)
  },
  {
    name: "attachments-create",
    path: "/attachments/create",
    component: () => import("/tmp/workspace/pages/attachments/create.vue").then(m => m.default || m)
  },
  {
    name: "attachments",
    path: "/attachments",
    component: () => import("/tmp/workspace/pages/attachments/index.vue").then(m => m.default || m)
  },
  {
    name: "automations",
    path: "/automations",
    component: () => import("/tmp/workspace/pages/automations/index.vue").then(m => m.default || m)
  },
  {
    name: "compose",
    path: "/compose",
    component: () => import("/tmp/workspace/pages/compose.vue").then(m => m.default || m)
  },
  {
    name: "connectors-id-connections-create",
    path: "/connectors/:id()/connections/create",
    component: () => import("/tmp/workspace/pages/connectors/[id]/connections/create.vue").then(m => m.default || m)
  },
  {
    name: "connectors-id-connections-edit",
    path: "/connectors/:id()/connections/edit",
    component: () => import("/tmp/workspace/pages/connectors/[id]/connections/edit.vue").then(m => m.default || m)
  },
  {
    name: "connectors-id-connections",
    path: "/connectors/:id()/connections",
    component: () => import("/tmp/workspace/pages/connectors/[id]/connections/index.vue").then(m => m.default || m)
  },
  {
    name: "connectors-id-edit",
    path: "/connectors/:id()/edit",
    component: () => import("/tmp/workspace/pages/connectors/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "connectors-id-events-eventId",
    path: "/connectors/:id()/events/:eventId()",
    component: () => import("/tmp/workspace/pages/connectors/[id]/events/[eventId].vue").then(m => m.default || m)
  },
  {
    name: "connectors-id-events",
    path: "/connectors/:id()/events",
    component: () => import("/tmp/workspace/pages/connectors/[id]/events/index.vue").then(m => m.default || m)
  },
  {
    name: "connectors-id",
    path: "/connectors/:id()",
    component: () => import("/tmp/workspace/pages/connectors/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "connectors-id-sync",
    path: "/connectors/:id()/sync",
    component: () => import("/tmp/workspace/pages/connectors/[id]/sync/index.vue").then(m => m.default || m)
  },
  {
    name: "connectors-create",
    path: "/connectors/create",
    component: () => import("/tmp/workspace/pages/connectors/create.vue").then(m => m.default || m)
  },
  {
    name: "connectors",
    path: "/connectors",
    component: () => import("/tmp/workspace/pages/connectors/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts-id",
    path: "/contacts/:id()",
    component: () => import("/tmp/workspace/pages/contacts/[id].vue").then(m => m.default || m)
  },
  {
    name: "contacts-create",
    path: "/contacts/create",
    component: () => import("/tmp/workspace/pages/contacts/create.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/tmp/workspace/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/tmp/workspace/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "domains-id-edit",
    path: "/domains/:id()/edit",
    component: () => import("/tmp/workspace/pages/domains/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "domains-id",
    path: "/domains/:id()",
    component: () => import("/tmp/workspace/pages/domains/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "domains-create",
    path: "/domains/create",
    component: () => import("/tmp/workspace/pages/domains/create.vue").then(m => m.default || m)
  },
  {
    name: "domains",
    path: "/domains",
    component: () => import("/tmp/workspace/pages/domains/index.vue").then(m => m.default || m)
  },
  {
    name: "email-validation",
    path: "/email-validation",
    component: () => import("/tmp/workspace/pages/email-validation/index.vue").then(m => m.default || m)
  },
  {
    name: "emails-id-forward",
    path: "/emails/:id()/forward",
    component: () => import("/tmp/workspace/pages/emails/[id]/forward.vue").then(m => m.default || m)
  },
  {
    name: "emails-id",
    path: "/emails/:id()",
    component: () => import("/tmp/workspace/pages/emails/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "emails-id-reply",
    path: "/emails/:id()/reply",
    component: () => import("/tmp/workspace/pages/emails/[id]/reply.vue").then(m => m.default || m)
  },
  {
    name: "emails",
    path: "/emails",
    component: () => import("/tmp/workspace/pages/emails/index.vue").then(m => m.default || m)
  },
  {
    name: "expired-id",
    path: "/expired/:id()",
    component: () => import("/tmp/workspace/pages/expired/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "expired",
    path: "/expired",
    component: () => import("/tmp/workspace/pages/expired/index.vue").then(m => m.default || m)
  },
  {
    name: "export",
    path: "/export",
    component: () => import("/tmp/workspace/pages/export.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/tmp/workspace/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forwarders-id-edit",
    path: "/forwarders/:id()/edit",
    component: () => import("/tmp/workspace/pages/forwarders/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forwarders-id",
    path: "/forwarders/:id()",
    component: () => import("/tmp/workspace/pages/forwarders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forwarders-create",
    path: "/forwarders/create",
    component: () => import("/tmp/workspace/pages/forwarders/create.vue").then(m => m.default || m)
  },
  {
    name: "forwarders-events-id",
    path: "/forwarders/events/:id()",
    component: () => import("/tmp/workspace/pages/forwarders/events/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "forwarders-events",
    path: "/forwarders/events",
    component: () => import("/tmp/workspace/pages/forwarders/events/index.vue").then(m => m.default || m)
  },
  {
    name: "forwarders",
    path: "/forwarders",
    component: () => import("/tmp/workspace/pages/forwarders/index.vue").then(m => m.default || m)
  },
  {
    name: "grant",
    path: "/grant",
    component: () => import("/tmp/workspace/pages/grant.vue").then(m => m.default || m)
  },
  {
    name: "groups-id",
    path: "/groups/:id()",
    component: () => import("/tmp/workspace/pages/groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "groups-create",
    path: "/groups/create",
    component: () => import("/tmp/workspace/pages/groups/create.vue").then(m => m.default || m)
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/tmp/workspace/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: "home-AccessTable",
    path: "/home/AccessTable",
    component: () => import("/tmp/workspace/pages/home/AccessTable.vue").then(m => m.default || m)
  },
  {
    name: "home-AccessTableRow",
    path: "/home/AccessTableRow",
    component: () => import("/tmp/workspace/pages/home/AccessTableRow.vue").then(m => m.default || m)
  },
  {
    name: "home-SmtpImapAccessView",
    path: "/home/SmtpImapAccessView",
    component: () => import("/tmp/workspace/pages/home/SmtpImapAccessView.vue").then(m => m.default || m)
  },
  {
    name: "inbox-rulesets-id",
    path: "/inbox-rulesets/:id()",
    component: () => import("/tmp/workspace/pages/inbox-rulesets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "inbox-rulesets-create",
    path: "/inbox-rulesets/create",
    component: () => import("/tmp/workspace/pages/inbox-rulesets/create.vue").then(m => m.default || m)
  },
  {
    name: "inbox-rulesets",
    path: "/inbox-rulesets",
    component: () => import("/tmp/workspace/pages/inbox-rulesets/index.vue").then(m => m.default || m)
  },
  {
    name: "inboxes-id-edit",
    path: "/inboxes/:id()/edit",
    component: () => import("/tmp/workspace/pages/inboxes/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "inboxes-id",
    path: "/inboxes/:id()",
    component: () => import("/tmp/workspace/pages/inboxes/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "inboxes-create",
    path: "/inboxes/create",
    component: () => import("/tmp/workspace/pages/inboxes/create.vue").then(m => m.default || m)
  },
  {
    name: "inboxes",
    path: "/inboxes",
    component: () => import("/tmp/workspace/pages/inboxes/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/tmp/workspace/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/tmp/workspace/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: "mail-server",
    path: "/mail-server",
    component: () => import("/tmp/workspace/pages/mail-server.vue").then(m => m.default || m)
  },
  {
    name: "missed-emails-id",
    path: "/missed-emails/:id()",
    component: () => import("/tmp/workspace/pages/missed-emails/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "missed-emails",
    path: "/missed-emails",
    component: () => import("/tmp/workspace/pages/missed-emails/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications-id",
    path: "/notifications/:id()",
    component: () => import("/tmp/workspace/pages/notifications/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/tmp/workspace/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "offboarding",
    path: "/offboarding",
    component: () => import("/tmp/workspace/pages/offboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "organization-id",
    path: "/organization/:id()",
    component: () => import("/tmp/workspace/pages/organization/[id].vue").then(m => m.default || m)
  },
  {
    name: "organization",
    path: "/organization",
    component: () => import("/tmp/workspace/pages/organization/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-slug-create-saml",
    path: "/organizations/:slug()/create-saml",
    component: () => import("/tmp/workspace/pages/organizations/[slug]/create-saml.vue").then(m => m.default || m)
  },
  {
    name: "organizations-slug-create-user",
    path: "/organizations/:slug()/create-user",
    component: () => import("/tmp/workspace/pages/organizations/[slug]/create-user.vue").then(m => m.default || m)
  },
  {
    name: "organizations-slug-edit-user",
    path: "/organizations/:slug()/edit-user",
    component: () => import("/tmp/workspace/pages/organizations/[slug]/edit-user.vue").then(m => m.default || m)
  },
  {
    name: "organizations-slug",
    path: "/organizations/:slug()",
    component: () => import("/tmp/workspace/pages/organizations/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "organizations-slug-update-saml",
    path: "/organizations/:slug()/update-saml",
    component: () => import("/tmp/workspace/pages/organizations/[slug]/update-saml.vue").then(m => m.default || m)
  },
  {
    name: "organizations-create",
    path: "/organizations/create",
    component: () => import("/tmp/workspace/pages/organizations/create.vue").then(m => m.default || m)
  },
  {
    name: "organizations",
    path: "/organizations",
    component: () => import("/tmp/workspace/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: "phone-numbers-id",
    path: "/phone-numbers/:id()",
    component: () => import("/tmp/workspace/pages/phone-numbers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "phone-numbers-id-sms-smsid",
    path: "/phone-numbers/:id()/sms/:smsid()",
    component: () => import("/tmp/workspace/pages/phone-numbers/[id]/sms/[smsid]/index.vue").then(m => m.default || m)
  },
  {
    name: "phone-numbers-id-sms-smsid-reply",
    path: "/phone-numbers/:id()/sms/:smsid()/reply",
    component: () => import("/tmp/workspace/pages/phone-numbers/[id]/sms/[smsid]/reply.vue").then(m => m.default || m)
  },
  {
    name: "phone-numbers-id-sms",
    path: "/phone-numbers/:id()/sms",
    component: () => import("/tmp/workspace/pages/phone-numbers/[id]/sms/index.vue").then(m => m.default || m)
  },
  {
    name: "phone-numbers-create",
    path: "/phone-numbers/create",
    component: () => import("/tmp/workspace/pages/phone-numbers/create.vue").then(m => m.default || m)
  },
  {
    name: "phone-numbers",
    path: "/phone-numbers",
    component: () => import("/tmp/workspace/pages/phone-numbers/index.vue").then(m => m.default || m)
  },
  {
    name: "plugins",
    path: "/plugins",
    component: () => import("/tmp/workspace/pages/plugins/index.vue").then(m => m.default || m)
  },
  {
    name: "portals-id",
    path: "/portals/:id()",
    component: () => import("/tmp/workspace/pages/portals/[id].vue").then(m => m.default || m)
  },
  {
    name: "portals-create",
    path: "/portals/create",
    component: () => import("/tmp/workspace/pages/portals/create.vue").then(m => m.default || m)
  },
  {
    name: "portals-guests-id",
    path: "/portals/guests/:id()",
    component: () => import("/tmp/workspace/pages/portals/guests/[id].vue").then(m => m.default || m)
  },
  {
    name: "portals-guests-create",
    path: "/portals/guests/create",
    component: () => import("/tmp/workspace/pages/portals/guests/create.vue").then(m => m.default || m)
  },
  {
    name: "portals-guests",
    path: "/portals/guests",
    component: () => import("/tmp/workspace/pages/portals/guests/index.vue").then(m => m.default || m)
  },
  {
    name: "portals",
    path: "/portals",
    component: () => import("/tmp/workspace/pages/portals/index.vue").then(m => m.default || m)
  },
  {
    name: "previews",
    path: "/previews",
    component: () => import("/tmp/workspace/pages/previews.vue").then(m => m.default || m)
  },
  {
    name: "repliers-id-edit",
    path: "/repliers/:id()/edit",
    component: () => import("/tmp/workspace/pages/repliers/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "repliers-id",
    path: "/repliers/:id()",
    component: () => import("/tmp/workspace/pages/repliers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "repliers-create",
    path: "/repliers/create",
    component: () => import("/tmp/workspace/pages/repliers/create.vue").then(m => m.default || m)
  },
  {
    name: "repliers",
    path: "/repliers",
    component: () => import("/tmp/workspace/pages/repliers/index.vue").then(m => m.default || m)
  },
  {
    name: "reputation-bounces-id",
    path: "/reputation/bounces/:id()",
    component: () => import("/tmp/workspace/pages/reputation/bounces/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "reputation-bounces",
    path: "/reputation/bounces",
    component: () => import("/tmp/workspace/pages/reputation/bounces/index.vue").then(m => m.default || m)
  },
  {
    name: "reputation-complaints-id",
    path: "/reputation/complaints/:id()",
    component: () => import("/tmp/workspace/pages/reputation/complaints/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "reputation-complaints",
    path: "/reputation/complaints",
    component: () => import("/tmp/workspace/pages/reputation/complaints/index.vue").then(m => m.default || m)
  },
  {
    name: "reputation",
    path: "/reputation",
    component: () => import("/tmp/workspace/pages/reputation/index.vue").then(m => m.default || m)
  },
  {
    name: "reputation-recipients-id",
    path: "/reputation/recipients/:id()",
    component: () => import("/tmp/workspace/pages/reputation/recipients/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "reputation-recipients",
    path: "/reputation/recipients",
    component: () => import("/tmp/workspace/pages/reputation/recipients/index.vue").then(m => m.default || m)
  },
  {
    name: "scheduled-id",
    path: "/scheduled/:id()",
    component: () => import("/tmp/workspace/pages/scheduled/[id].vue").then(m => m.default || m)
  },
  {
    name: "scheduled",
    path: "/scheduled",
    component: () => import("/tmp/workspace/pages/scheduled/index.vue").then(m => m.default || m)
  },
  {
    name: "sent-id",
    path: "/sent/:id()",
    component: () => import("/tmp/workspace/pages/sent/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "sent-delivery-id",
    path: "/sent/delivery/:id()",
    component: () => import("/tmp/workspace/pages/sent/delivery/[id].vue").then(m => m.default || m)
  },
  {
    name: "sent",
    path: "/sent",
    component: () => import("/tmp/workspace/pages/sent/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-feedback",
    path: "/settings/feedback",
    component: () => import("/tmp/workspace/pages/settings/feedback.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/tmp/workspace/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/tmp/workspace/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "sms",
    path: "/sms",
    component: () => import("/tmp/workspace/pages/sms/index.vue").then(m => m.default || m)
  },
  {
    name: "sso",
    path: "/sso",
    component: () => import("/tmp/workspace/pages/sso.vue").then(m => m.default || m)
  },
  {
    name: "templates-id",
    path: "/templates/:id()",
    component: () => import("/tmp/workspace/pages/templates/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-create",
    path: "/templates/create",
    component: () => import("/tmp/workspace/pages/templates/create.vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/tmp/workspace/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "tracking-id",
    path: "/tracking/:id()",
    component: () => import("/tmp/workspace/pages/tracking/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "tracking",
    path: "/tracking",
    component: () => import("/tmp/workspace/pages/tracking/index.vue").then(m => m.default || m)
  },
  {
    name: "unblock-default-sender",
    path: "/unblock/default-sender",
    component: () => import("/tmp/workspace/pages/unblock/default-sender.vue").then(m => m.default || m)
  },
  {
    name: "unblock",
    path: "/unblock",
    component: () => import("/tmp/workspace/pages/unblock/index.vue").then(m => m.default || m)
  },
  {
    name: "unblock-unfreeze-request",
    path: "/unblock/unfreeze-request",
    component: () => import("/tmp/workspace/pages/unblock/unfreeze-request.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-id",
    path: "/upgrade/:id()",
    component: () => import("/tmp/workspace/pages/upgrade/[id].vue").then(m => m.default || m)
  },
  {
    name: "upgrade-addons-addon",
    path: "/upgrade/addons/:addon()",
    component: () => import("/tmp/workspace/pages/upgrade/addons/[addon].vue").then(m => m.default || m)
  },
  {
    name: "upgrade-callback",
    path: "/upgrade/callback",
    component: () => import("/tmp/workspace/pages/upgrade/callback/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade",
    path: "/upgrade",
    component: () => import("/tmp/workspace/pages/upgrade/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-missing",
    path: "/upgrade/missing",
    component: () => import("/tmp/workspace/pages/upgrade/missing/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-phones",
    path: "/upgrade/phones",
    component: () => import("/tmp/workspace/pages/upgrade/phones/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-quote-id",
    path: "/upgrade/quote/:id()",
    component: () => import("/tmp/workspace/pages/upgrade/quote/[id].vue").then(m => m.default || m)
  },
  {
    name: "upgrade-quote",
    path: "/upgrade/quote",
    component: () => import("/tmp/workspace/pages/upgrade/quote/index.vue").then(m => m.default || m)
  },
  {
    name: "upgrade-users",
    path: "/upgrade/users",
    component: () => import("/tmp/workspace/pages/upgrade/users/index.vue").then(m => m.default || m)
  },
  {
    name: "usage",
    path: "/usage",
    component: () => import("/tmp/workspace/pages/usage/index.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/tmp/workspace/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "webhooks-id-edit",
    path: "/webhooks/:id()/edit",
    component: () => import("/tmp/workspace/pages/webhooks/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "webhooks-id",
    path: "/webhooks/:id()",
    component: () => import("/tmp/workspace/pages/webhooks/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "webhooks-id-results-resultId",
    path: "/webhooks/:id()/results/:resultId()",
    component: () => import("/tmp/workspace/pages/webhooks/[id]/results/[resultId].vue").then(m => m.default || m)
  },
  {
    name: "webhooks-id-results",
    path: "/webhooks/:id()/results",
    component: () => import("/tmp/workspace/pages/webhooks/[id]/results/index.vue").then(m => m.default || m)
  },
  {
    name: "webhooks-create",
    path: "/webhooks/create",
    component: () => import("/tmp/workspace/pages/webhooks/create.vue").then(m => m.default || m)
  },
  {
    name: "webhooks-endpoints",
    path: "/webhooks/endpoints",
    component: () => import("/tmp/workspace/pages/webhooks/endpoints/index.vue").then(m => m.default || m)
  },
  {
    name: "webhooks",
    path: "/webhooks",
    component: () => import("/tmp/workspace/pages/webhooks/index.vue").then(m => m.default || m)
  },
  {
    name: "zapier",
    path: "/zapier",
    component: () => import("/tmp/workspace/pages/zapier/index.vue").then(m => m.default || m)
  }
]