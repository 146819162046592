<template>
  <Link
    class="group"
    v-if="!isChild || sidebarMinimized"
    data-el="SidebarLink"
    :external="item.external"
    :to="item.to"
    :class="linkClasses(item)"
    :data-to="item.to"
    :data-test-id="item.dataId ?? item.title"
    :data-id="'sidebar-' + item.title.toLowerCase()"
    :capture-event="{
      name: 'sidebar_click',
      data: { dataId: item.dataId ?? item.title },
    }"
    :data-nav-item-title="item.title"
  >
    <Icon
      v-if="item.icon"
      :icon="item.icon"
      :class="
        isActivePath(currentPath, item)
          ? 'text-slate-800'
          : 'text-gray-900 group-hover:text-slate-800'
      "
    />
    <span
      class="ml-4 flex-1 whitespace-nowrap text-left no-underline"
      v-if="!minimized"
      >{{ item.title }}</span
    >
    <span
      class="ml-3 inline-flex h-3 w-3 items-center justify-center rounded-full bg-green-100 p-3 text-sm font-medium text-green-800"
      v-if="item.count !== undefined"
      >{{ item.count }}</span
    >
    <svg
      class="ml-3 h-6 w-6"
      v-if="item.children && item.children.length && !sidebarMinimized"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clip-rule="evenodd"
      />
    </svg>
  </Link>
  <Link
    class="group pl-10"
    v-else
    data-el="SidebarLink:child"
    :external="item.external"
    :data-nav-item-title="item.title"
    :data-nav-item-item-title="item.title"
    :to="item.to"
    :data-to="item.to"
    :data-test-id="item.dataId"
    :class="linkClasses(item)"
    >{{ item.title }}</Link
  >
</template>
<script lang="ts" setup>
import Icon from '../form/Icon.vue';
import Link from '../Link.vue';
import { isActivePath } from '../../lib/services/helpers';
import { type NavItem } from '../../lib/services/types';
import { storeToRefs } from 'pinia';
import { useStore3 } from '../../store/main';

const props = withDefaults(
  defineProps<{
    hasOpenChildren?: boolean;
    item: NavItem;
    isChild?: boolean;
    minimized?: boolean;
    currentPath: string;
  }>(),
  {
    minimized: false,
    hasOpenChildren: false,
    isChild: false,
  },
);

const store3 = useStore3();
const { sidebarLgMinified: sidebarMinimized } = storeToRefs(store3);

const linkClasses = (i: NavItem) => {
  const isActive = isActivePath(props.currentPath, i);
  return {
    'flex items-center w-full p-2 no-underline text-base font-normal tracking-normal':
      true,
    'rounded-t-lg font-normal leading-relaxed': true,
    'rounded-b-lg': !props.hasOpenChildren,
    'hover:bg-gray-100 hover:text-gray-900': true,
    'bg-gray-200 text-slate-800': isActive,
    // not child
    'text-gray-900': !isActive && !props.isChild,
    // is child
    'text-slate-800': !isActive && props.isChild,
  };
};
</script>
