import { acceptCookie } from '@mailslurp/common/dist/cookies';
import { isDev } from '../lib/services/helpers';
import { createInlineScript, createScript } from '@mailslurp/common';
import { useStore3 } from '../store/main';
import { storeToRefs } from 'pinia';
import { defineNuxtPlugin } from '#imports';
import { addAnalytics } from '@mailslurp/common/dist/analytics';
import { addChat } from '@mailslurp/common/dist/chat';
import { onMounted } from 'vue';
import { getLogger } from '../lib/services/getLogger';

const log = getLogger('scripts.client');

export default defineNuxtPlugin(() => {
  const store3 = useStore3();
  const addedAnonScripts = ref(false);
  const addedUserScripts = ref(false);
  const { user } = storeToRefs(store3);

  if (!addedAnonScripts.value) {
    log('Add inline scripts no cookies');
    addedAnonScripts.value = true;
    try {
      if (!isDev()) {
        // add analytics
        addAnalytics('app.mailslurp.com', {
          overridePathUuid: true,
        });
        // add chat
        addChat();
      }
    } catch (e) {
      console.error('Error adding anon scripts', e);
    }
  }

  watch(
    () => user.value,
    () => {
      if (!addedUserScripts.value) {
        log('Add inline scripts no cookies');
        addedUserScripts.value = true;
        try {
          acceptCookie();
          // const linedInId = '6730713';
          const redditPixelId = 'a2_ejt95w7gwk37';
          const adwordsId = 'AW-10965529593';
          const scriptTags = [
            // add gtag for adwords
            createScript(
              'gtag',
              `https://www.googletagmanager.com/gtag/js?id=${adwordsId}`,
            ),
            // https://support.google.com/google-ads/answer/7548399?hl=en
            createInlineScript(
              `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${adwordsId}');`,
            ),
            // https://business.reddithelp.com/helpcenter/s/article/Install-the-Reddit-Pixel-on-your-website
            // add reddit pixel
            createInlineScript(
              `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${redditPixelId}', {"email":"${user.value?.emailAddress}","externalId":"${user.value?.id}"});rdt('track', 'PageVisit');`,
            ),
          ];
          // append each script tag (do it for dev so build is tested)
          if (!isDev()) {
            scriptTags.forEach((script) => document.body.appendChild(script));
          }
        } catch (e) {
          console.error('Error adding user scripts', e);
        }
      }
    },
  );
});
