import validate from "/tmp/workspace/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/tmp/workspace/middleware/01.auth.global.ts";
import _02_45params_45global from "/tmp/workspace/middleware/02.params.global.ts";
import _03_45geo_45global from "/tmp/workspace/middleware/03.geo.global.ts";
import _04_45route_45change_45global from "/tmp/workspace/middleware/04.routeChange.global.ts";
import manifest_45route_45rule from "/tmp/workspace/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45params_45global,
  _03_45geo_45global,
  _04_45route_45change_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}